<template>
  <el-dialog
    width="80vw"
    top="10vh"
    :title="titleAgent"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    :lock-scroll="false"
    class="add-or-edit-agent"
  >
    <!-- body -->
    <el-row justify="center" align="center" class="group-input">
      <el-col :span="12" :offset="0">
        <el-col :span="4" :offset="1">
          <span>Upline</span>
          <span class="required-icon">*</span>
        </el-col>
        <el-col :span="6" :offset="0">
          <b>{{ this.upline }}</b>
        </el-col>
        <el-col :span="4" :offset="1">
          <span
            >Mã đại lý:
            {{ this.codeParentAgent }}
          </span>
          <span class="required-icon">*</span>
        </el-col>
        <el-col :span="7" :offset="0">
          <el-input v-model="codeAgentNew" size="small" clearable></el-input>
        </el-col>
      </el-col>

      <el-col :span="12" :offset="0">
        <el-col :span="4" :offset="1">
          <span>Cấp đại lý</span>
        </el-col>
        <el-col :span="18" :offset="0">
          <b>{{ capDaiLy }}</b>
        </el-col>
      </el-col>
    </el-row>

    <el-row justify="center" align="center" class="group-input">
      <el-col :span="12" :offset="0">
        <el-col :span="4" :offset="1">
          <span class="required-icon" v-if="type === 'add'">*</span>
          <span>Quyền đại lý:</span>
          <span class="required-icon">*</span>
        </el-col>
        <el-col :span="18" :offset="0">
          <el-select
            v-model="dataAgent.agentRoleId"
            value-key="codeKey"
            size="small"
            placeholder="Chọn quyền đại lý"
          >
            <el-option
              v-for="item in listQuyenDaiLy"
              :key="item.codeKey"
              :label="item.codeName"
              :value="item.codeKey"
              no-data-text="Không có dữ liệu"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-col>
      <el-col :span="12" :offset="0">
        <el-col :span="4" :offset="1">
          <span>User Telegram</span>
          <span class="required-icon">*</span>
        </el-col>
        <el-col :span="18" :offset="0">
          <el-input
            v-model="dataAgent.telegramName"
            size="small"
            clearable
          ></el-input>
        </el-col>
      </el-col>
    </el-row>

    <el-row justify="center" align="center" class="group-input">
      <el-col :span="12" :offset="0">
        <el-col :span="4" :offset="1">
          <span class="required-icon">*</span>
          <span>Nhóm Telegram:</span>
        </el-col>
        <el-col :span="18" :offset="0">
          <el-input
            v-model="dataAgent.telegramGroupName"
            size="small"
            clearable
          ></el-input>
        </el-col>
      </el-col>
      <el-col :span="12" :offset="0">
        <el-col :span="4" :offset="1">
          <span class="required-icon">*</span>
          <span>Phone Telegram:</span>
        </el-col>
        <el-col :span="18" :offset="0">
          <el-input
            v-model="dataAgent.telegramPhone"
            size="small"
            clearable
          ></el-input>
        </el-col>
      </el-col>
    </el-row>

    <el-row justify="center" align="center" class="group-input">
      <el-col :span="12" :offset="0">
        <el-col :span="4" :offset="1">
          <span>Ghi chú:</span>
        </el-col>
        <el-col :span="18" :offset="0">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 20 }"
            v-model="dataAgent.note"
          ></el-input>
        </el-col>
      </el-col>
    </el-row>

    <!-- footer -->
    <el-row slot="footer" class="dialog-footer">
      <el-col :span="24">
        <el-button type="danger" plain @click="cancel()">Hủy</el-button>
        <el-button type="success" @click="confirm()">{{
          this.type === "add" ? "Thêm" : "Lưu"
        }}</el-button>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "AddOrEditAgent",
  props: ["dialogVisible", "dataRow", "type", "titleAgent"],
  data() {
    return {
      dataAgent: {},
      modeOptions: [
        {
          codeKey: "",
          codeName: "",
        },
      ],
      isShowDate: true,
      listQuyenDaiLy: [
        {
          codeKey: 2,
          codeName: "Xác nhận",
        },
        {
          codeKey: 4,
          codeName: "Toàn quyền",
        },
      ],
      upline: "",
      codeParentAgent: "",
      codeAgentNew: "",
      capDaiLy: "",
    };
  },
  computed: {
    ...mapState("TongDaiLy", ["domainOptions"]),
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        return;
      }
      this.upline = "Công ty";
      this.capDaiLy = "Tổng đại lý";
      this.codeParentAgent = "";
      if (this.dataRow) {
        //console.log(this.dataRow);
        if (this.type === "add") {
          this.codeParentAgent = this.dataRow.code + "-";
          this.upline = this.dataRow.code;
          this.codeAgentNew = "";
          this.capDaiLy = "Đại lý cấp " + this.dataRow.level;

          // Setting data for call api
          this.dataAgent = {
            parentId: this.dataRow.agentId,
            agentId: "",
            agentRoleId: 2,
            code: this.codeParentAgent + this.codeAgentNew,
            level: this.dataRow.level,
            note: "",
            phoneArea: "",
            telegramGroupName: "",
            telegramName: "",
            telegramPhone: "",
          };
        }

        if (this.type === "edit") {
          this.codeAgentNew = "";
          // This is mode edit
          if (this.dataRow.level == 1) {
            this.codeAgentNew = this.dataRow.code;
            this.codeParentAgent = "";
          } else {
            this.capDaiLy = "Đại lý cấp " + (this.dataRow.level - 1);
            this.codeParentAgent = this.dataRow.parentCode + "-";
            this.codeAgentNew = (this.dataRow.code + "").replace(
              this.codeParentAgent,
              ""
            );
          }

          // Setting data for call api
          this.dataAgent = {
            agentId: this.dataRow.agentId,
            parentId: this.dataRow.parentId,
            agentRoleId: this.dataRow.agentRoleId,
            code: this.codeParentAgent + this.codeAgentNew,
            level: this.dataRow.level - 1,
            note: this.dataRow.note,
            phoneArea: this.dataRow.phoneArea,
            telegramGroupName: this.dataRow.telegramGroupName,
            telegramName: this.dataRow.telegramName,
            telegramPhone: this.dataRow.telegramPhone,
          };
        }
      } else {
        // this is mode thêm tổng đại lý
        this.codeAgentNew = "";
        this.dataAgent = {
          agentId: "",
          agentRoleId: 2,
          code: this.codeAgentNew,
          level: 0,
          note: "",
          parentId: "",
          phoneArea: "",
          telegramGroupName: "",
          telegramName: "",
          telegramPhone: "",
        };
      }
    },
    codeAgentNew: {
      handler(val) {
        this.codeAgentNew = val.toUpperCase();
      },
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    handleClose() {
      this.$confirm(
        `Bạn có chắc muốn hủy ${
          this.type === "addRoot"
            ? "thêm tổng"
            : this.type === "add"
            ? " thêm "
            : "chỉnh sửa"
        } đại lý?`,
        `Xác nhận`,
        {
          confirmButtonText: "Đồng Ý",
          cancelButtonText: "Hủy",
          confirmButtonClass: "btn-success",
          cancelButtonClass: "btn-success-plain",
        }
      )
        .then(() => {
          this.close();
        })
        .catch();
    },
    close() {
      this.dataAgent = {};
      this.$emit("closeDialog");
    },
    cancel() {
      this.handleClose();
    },
    confirm() {
      let isValid =
        this.checkInput("text", this.codeAgentNew, "Mã đại lý") &&
        this.checkInput("text", this.dataAgent.agentRoleId, "Quyền đại lý") &&
        this.checkInput("text", this.dataAgent.telegramName, "User Telegram") &&
        this.checkInput(
          "text",
          this.dataAgent.telegramPhone,
          "Phone Telegram"
        ) &&
        this.checkInput(
          "text",
          this.dataAgent.telegramGroupName,
          "Nhóm Telegram"
        );
      if (isValid) {
        this.dataAgent.code = this.codeParentAgent + this.codeAgentNew;
        console.log(this.dataAgent.code);
        if (this.type === "edit") {
          this.$emit("callApiAgentUpdate", this.dataAgent);
        } else {
          this.$emit("callApiAgentInset", this.dataAgent);
        }
      }
    },
    checkInput(type, input, text) {
      if (type === "text") {
        if (input === "") {
          this.$alert(`Bạn phải nhập ${text}`, `Lỗi`, {
            confirmButtonText: "OK",
            confirmButtonClass: "btn-success",
            type: "error",
          });
          return false;
        }
      }
      return true;
    },
  },
};
</script>

<style lang="scss">
.add-or-edit-agent {
  .el-dialog__body {
    padding-top: 20px;
    height: 50vh;
    overflow: auto;
  }

  .el-select {
    width: 100%;
  }

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100% !important;
  }

  .group-input {
    min-height: 32px;
    margin-top: 10px;

    > div {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  [class*="el-col-"] {
    position: relative;

    .required-icon {
      color: red;
      position: absolute;
      top: 0;
      left: -10px;
    }
  }
}

.btn-success {
  color: #fff !important;
  background-color: #0e9cc0 !important;
  border-color: #0e9cc0 !important;

  &:hover {
    color: #fff !important;
    background: #85ce61 !important;
    border-color: #85ce61 !important;
  }
}

.btn-success-plain {
  color: #0e9cc0 !important;
  background: #f0f9eb !important;
  border-color: #c2e7b0 !important;

  &:hover {
    color: #fff !important;
    background: #85ce61 !important;
    border-color: #85ce61 !important;
  }
}
</style>
