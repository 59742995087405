<template>
  <el-dialog
    width="80vw"
    top="5vh"
    :title="this.titlePopupListPlayer"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    :lock-scroll="false"
    class="add-or-edit-player"
  >
    <el-row justify="center" align="center" class="group-input">
      <el-col :span="12" :offset="0">
        <el-col :span="4" :offset="0">
          <span style="margin-left: 10px">Chọn Domain:</span>
        </el-col>
        <el-col :span="18" :offset="0">
          <el-select
            v-model="typeDomain"
            value-key="codeKey"
            size="small"
            placeholder="Chọn nhà domain"
          >
            <el-option
              v-for="item in domainOptions"
              :key="item.codeName"
              :label="item.codeName"
              :value="item.codeKey"
              no-data-text="Không có dữ liệu"
              clearable
            >
            </el-option>
          </el-select>
        </el-col>
      </el-col>
    </el-row>

    <el-row justify="center" align="center" class="group-input-user">
      <el-col :span="12" :offset="0">
        <el-col :span="4" :offset="0">
          <span>Nhà cung cấp:</span>
        </el-col>
        <el-col :span="18" :offset="0">
          <el-select
            v-model="nhaCungCap"
            value-key="codeKey"
            size="small"
            placeholder="Chọn nhà cung cấp"
          >
            <el-option
              v-for="item in nhaCungCapOptions"
              :key="item.codeName"
              :label="item.codeName"
              :value="item.codeKey"
              no-data-text="Không có dữ liệu"
              clearable
            >
            </el-option>
          </el-select>
        </el-col>
      </el-col>
    </el-row>

    <el-row justify="center" align="center" class="group-input-user">
      <el-col :span="12" :offset="0">
        <el-col :span="4" :offset="0">
          <span>Nhà nhận hàng:</span>
        </el-col>
        <el-col :span="18" :offset="0">
          <treeselect
            :options="treeDataOptions"
            :multiple="false"
            @select="changeGiaoThau"
            v-model="distributorId"
            placeholder="Chọn nhà nhận hàng"
          />
        </el-col>
      </el-col>
    </el-row>

    <el-row justify="center" align="center" class="group-input-user">
      <el-col :span="12" :offset="0">
        <el-col :span="4" :offset="0">
          <span>Đại lý cấp dưới:</span>
        </el-col>
        <el-col :span="18" :offset="0">
          <treeselect
            :options="treeDataDownLine"
            :multiple="false"
            @select="changeGiaoChild"
            v-model="receiverId"
            placeholder="Chọn nhà nhận hàng cấp dưới"
          />
        </el-col>
      </el-col>
    </el-row>

    <!-- body -->
    <el-row
      justify="center"
      align="center"
      class="group-input-user header-group"
    >
      <el-col :span="4" :offset="0">
        <span></span>
      </el-col>
      <el-col :span="4" :offset="0">
        <span>Tài khoản chính</span>
      </el-col>

      <el-col :span="4" :offset="0">
        <span>Kế toán</span>
      </el-col>
      <el-col :span="4" :offset="0">
        <span>Support</span>
      </el-col>
      <el-col :span="4" :offset="0">
        <span>Thống kê</span>
      </el-col>
      <el-col :span="4" :offset="0">
        <span>Backup</span>
      </el-col>
    </el-row>
    <el-row justify="center" align="center" class="group-input-user group-1">
      <el-col :span="4" :offset="0">
        <span>Tên của super</span>
      </el-col>
      <el-col :span="4" :offset="0">
        <el-input
          style="width: auto"
          v-model="userName"
          clearable
          placeholder="Tài khoản sẽ quản lý trên tên này"
        ></el-input>
      </el-col>

      <el-col :span="4" :offset="0">
        <el-input
          style="width: auto"
          v-model="this.accoutKeToan.userName"
          disabled
          placeholder="Lấy từ TK chính"
        ></el-input>
      </el-col>
      <el-col :span="4" :offset="0">
        <el-input
          style="width: auto"
          v-model="this.accoutSupport.userName"
          disabled
          placeholder="Lấy từ TK chính"
        ></el-input>
      </el-col>
      <el-col :span="4" :offset="0">
        <el-input
          style="width: auto"
          v-model="this.accoutThongKe.userName"
          disabled
          placeholder="Lấy từ TK chính"
        ></el-input>
      </el-col>
      <el-col :span="4" :offset="0">
        <el-input
          style="width: auto"
          v-model="this.accoutBackup.userName"
          disabled
          placeholder="Lấy từ TK chính"
        ></el-input>
      </el-col>
    </el-row>
    <el-row justify="center" align="center" class="group-input-user group-2">
      <el-col :span="4" :offset="0">
        <span>Level</span>
      </el-col>
      <el-col :span="4" :offset="0">
        <span>SUPPER</span>
      </el-col>

      <el-col :span="4" :offset="0">
        <span>SUPPER</span>
      </el-col>
      <el-col :span="4" :offset="0">
        <span>SUPPER</span>
      </el-col>
      <el-col :span="4" :offset="0">
        <span>SUPPER</span>
      </el-col>
      <el-col :span="4" :offset="0">
        <span>SUPPER</span>
      </el-col>
    </el-row>
    <el-row justify="center" align="center" class="group-input-user group-3">
      <el-col :span="4" :offset="0">
        <span>Trạng thái</span>
      </el-col>
      <el-col :span="4" :offset="0">
        <span>OPEN</span>
      </el-col>

      <el-col :span="4" :offset="0">
        <span>OPEN</span>
      </el-col>
      <el-col :span="4" :offset="0">
        <span>OPEN</span>
      </el-col>
      <el-col :span="4" :offset="0">
        <span>OPEN</span>
      </el-col>
      <el-col :span="4" :offset="0">
        <span>OPEN</span>
      </el-col>
    </el-row>
    <el-row justify="center" align="center" class="group-input-user group-1">
      <el-col :span="4" :offset="0">
        <span>Tên đăng nhập (Login ID)</span>
      </el-col>
      <el-col :span="4" :offset="0">
        <el-input
          style="width: auto"
          v-model="loginId"
          clearable
          placeholder="Nhập login Id"
        ></el-input>
      </el-col>

      <el-col :span="4" :offset="0">
        <el-input
          style="width: auto"
          v-model="accoutKeToan.loginId"
          clearable
          placeholder="Nhập login Id"
        ></el-input>
      </el-col>
      <el-col :span="4" :offset="0">
        <el-input
          style="width: auto"
          v-model="accoutSupport.loginId"
          clearable
          placeholder="Nhập login Id"
        ></el-input>
      </el-col>
      <el-col :span="4" :offset="0">
        <el-input
          style="width: auto"
          v-model="accoutThongKe.loginId"
          clearable
          placeholder="Nhập login Id"
        ></el-input>
      </el-col>
      <el-col :span="4" :offset="0">
        <el-input
          style="width: auto"
          v-model="accoutBackup.loginId"
          clearable
          placeholder="Nhập login Id"
        ></el-input>
      </el-col>
    </el-row>
    <el-row justify="center" align="center" class="group-input-user group-2">
      <el-col :span="4" :offset="0">
        <span>Password</span>
      </el-col>
      <el-col :span="4" :offset="0">
        <el-input
          style="width: auto"
          v-model="passWord"
          clearable
          placeholder="Nhập password"
        ></el-input>
      </el-col>

      <el-col :span="4" :offset="0">
        <el-input
          style="width: auto"
          v-model="accoutKeToan.passWord"
          clearable
          placeholder="Nhập password"
        ></el-input>
      </el-col>
      <el-col :span="4" :offset="0">
        <el-input
          style="width: auto"
          v-model="accoutSupport.passWord"
          clearable
          placeholder="Nhập password"
        ></el-input>
      </el-col>
      <el-col :span="4" :offset="0">
        <el-input
          style="width: auto"
          v-model="accoutThongKe.passWord"
          clearable
          placeholder="Nhập password"
        ></el-input>
      </el-col>
      <el-col :span="4" :offset="0">
        <el-input
          style="width: auto"
          v-model="accoutBackup.passWord"
          clearable
          placeholder="Nhập password"
        ></el-input>
      </el-col>
    </el-row>
    <el-row justify="center" align="center" class="group-input-user group-1">
      <el-col :span="4" :offset="0">
        <span>Passcode</span>
      </el-col>
      <el-col :span="4" :offset="0">
        <el-input
          style="width: auto"
          v-model="passCode"
          clearable
          placeholder="Nhập pass code"
        ></el-input>
      </el-col>

      <el-col :span="4" :offset="0">
        <el-input
          style="width: auto"
          v-model="accoutKeToan.passCode"
          clearable
          placeholder="Nhập pass code"
        ></el-input>
      </el-col>
      <el-col :span="4" :offset="0">
        <el-input
          style="width: auto"
          v-model="accoutSupport.passCode"
          clearable
          placeholder="Nhập pass code"
        ></el-input>
      </el-col>
      <el-col :span="4" :offset="0">
        <el-input
          style="width: auto"
          v-model="accoutThongKe.passCode"
          clearable
          placeholder="Nhập pass code"
        ></el-input>
      </el-col>
      <el-col :span="4" :offset="0">
        <el-input
          style="width: auto"
          v-model="accoutBackup.passCode"
          clearable
          placeholder="Nhập pass code"
        ></el-input>
      </el-col>
    </el-row>
    <el-row justify="center" align="center" class="group-input-user group-2">
      <el-col :span="4" :offset="0">
        <span>Quản lý</span>
      </el-col>
      <el-col :span="4" :offset="0">
        <el-select
          v-model="accoutRoot.typeManager"
          value-key="codeKey"
          placeholder="Chọn tự quản vs công ty"
        >
          <el-option
            v-for="item in managerOptions"
            :key="item.codeKey"
            :label="item.codeName"
            :value="item.codeKey"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4" :offset="0">
        <el-select
          v-model="accoutSupport.typeManager"
          value-key="codeKey"
          placeholder="Chọn tự quản vs công ty"
        >
          <el-option
            v-for="item in managerOptions"
            :key="item.codeKey"
            :label="item.codeName"
            :value="item.codeKey"
          >
          </el-option>
        </el-select>
      </el-col>

      <el-col :span="4" :offset="0">
        <el-select
          v-model="accoutKeToan.typeManager"
          value-key="codeKey"
          placeholder="Chọn tự quản vs công ty"
        >
          <el-option
            v-for="item in managerOptions"
            :key="item.codeKey"
            :label="item.codeName"
            :value="item.codeKey"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4" :offset="0">
        <el-select
          v-model="accoutThongKe.typeManager"
          value-key="codeKey"
          placeholder="Chọn tự quản vs công ty"
        >
          <el-option
            v-for="item in managerOptions"
            :key="item.codeKey"
            :label="item.codeName"
            :value="item.codeKey"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4" :offset="0">
        <el-select
          v-model="accoutBackup.typeManager"
          value-key="codeKey"
          placeholder="Chọn tự quản vs công ty"
        >
          <el-option
            v-for="item in managerOptions"
            :key="item.codeKey"
            :label="item.codeName"
            :value="item.codeKey"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row justify="center" align="center" class="group-input-user group-3">
      <el-col :span="4" :offset="0">
        <span>Ghi chú</span>
      </el-col>
      <el-col :span="4" :offset="0">
        <el-input
          style="width: auto"
          v-model="accoutRoot.note"
          clearable
          placeholder="Nhập Ghi chú"
        ></el-input>
      </el-col>

      <el-col :span="4" :offset="0">
        <el-input
          style="width: auto"
          v-model="accoutKeToan.note"
          clearable
          placeholder="Nhập Ghi chú"
        ></el-input>
      </el-col>
      <el-col :span="4" :offset="0">
        <el-input
          style="width: auto"
          v-model="accoutSupport.note"
          clearable
          placeholder="Nhập Ghi chú"
        ></el-input>
      </el-col>
      <el-col :span="4" :offset="0">
        <el-input
          style="width: auto"
          v-model="accoutThongKe.note"
          clearable
          placeholder="Nhập Ghi chú"
        ></el-input>
      </el-col>
      <el-col :span="4" :offset="0">
        <el-input
          style="width: auto"
          v-model="accoutBackup.note"
          clearable
          placeholder="Nhập Ghi chú"
        ></el-input>
      </el-col>
    </el-row>

    <!-- footer -->
    <el-row slot="footer" class="dialog-footer">
      <el-col :span="24">
        <el-button type="success" plain @click="cancel()">Hủy</el-button>
        <el-button type="success" @click="confirm()">{{
          type === "add" ? "Thêm" : "Lưu"
        }}</el-button>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
// import the component
import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  components: { Treeselect },
  name: "AddOrEditListPlayer",
  props: [
    "dialogVisible",
    "dataRow",
    "type",
    "treeDataOptions",
    "titlePopupListPlayer",
  ],
  data() {
    return {
      data: [],
      treeDataDownLine: [],
      modeOptions: [
        {
          codeKey: "",
          codeName: "",
        },
      ],
      isShowDate: true,
      statusOptions: [
        {
          codeKey: 0,
          codeName: "Open",
        },
        {
          codeKey: 1,
          codeName: "Closed",
        },
      ],
      managerOptions: [
        {
          codeKey: 0,
          codeName: "Công Ty",
        },
        {
          codeKey: 1,
          codeName: "Ngoài Công Ty",
        },
      ],
      typeDomain: "",
      nhaCungCap: "",
      userName: "",
      loginId: "",
      passWord: "",
      passCode: "",
      receiverId: null,
      distributorId: null,
      accoutRoot: {
        playerId: 0,
        typeSub: 0,
        typeDomain: "",
        supplier: "",
        typeManager: 0,
        levelAccount: "1",
        originId: null,
        parentId: null,
        distributorId: null,
        receiverId: null,
        status: 0,
        userName: "",
        loginId: "",
        passWord: "",
        passCode: "",
        note: "",
        mode: null,
      },
      accoutSupport: {
        playerId: 0,
        typeSub: 1,
        typeDomain: "",
        supplier: "",
        typeManager: 0,
        levelAccount: "1",
        originId: 2,
        parentId: null,
        distributorId: null,
        receiverId: null,
        status: 0,
        userName: "",
        loginId: "",
        passWord: "",
        passCode: "",
        note: "",
        mode: 2,
      },
      accoutKeToan: {
        playerId: 0,
        typeSub: 1,
        typeDomain: "",
        supplier: "",
        typeManager: 0,
        levelAccount: "1",
        originId: 3,
        parentId: null,
        distributorId: null,
        receiverId: null,
        status: 0,
        userName: "",
        loginId: "",
        passWord: "",
        passCode: "",
        note: "",
        mode: 3,
      },
      accoutThongKe: {
        playerId: 0,
        typeSub: 1,
        typeDomain: "",
        supplier: "",
        typeManager: 0,
        levelAccount: "1",
        originId: 4,
        parentId: null,
        distributorId: null,
        receiverId: null,
        status: 0,
        userName: "",
        loginId: "",
        passWord: "",
        passCode: "",
        note: "",
        mode: 4,
      },
      accoutBackup: {
        playerId: 0,
        typeSub: 1,
        typeDomain: "",
        supplier: "",
        typeManager: 0,
        levelAccount: "1",
        originId: 5,
        parentId: null,
        distributorId: null,
        receiverId: null,
        status: 0,
        userName: "",
        loginId: "",
        passWord: "",
        passCode: "",
        note: "",
        mode: 5,
      },
    };
  },
  computed: {
    ...mapState("TongDaiLy", ["domainOptions", "nhaCungCapOptions"]),
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        return;
      }
      if (this.dataRow) {
        console.log(this.dataRow);
      }
      this.treeDataDownLine = this.nhaCungCapOptions;
    },
    userName: {
      handler(val) {
        let value = val.toUpperCase();
        this.userName = value;
        this.accoutRoot.userName = value;
        this.accoutKeToan.userName = value;
        this.accoutSupport.userName = value;
        this.accoutThongKe.userName = value;
        this.accoutBackup.userName = value;
      },
    },
    loginId: {
      handler(val) {
        let value = val.toUpperCase();
        this.loginId = value;
        this.accoutRoot.loginId = value;
        // this.accoutKeToan.loginId = value + "SubKT";
        // this.accoutSupport.loginId = value + "SubSP";
        // this.accoutThongKe.loginId = value + "SubTK";
        // this.accoutBackup.loginId = value + "SubBK";
      },
    },
    passWord: {
      handler(val) {
        this.accoutRoot.passWord = val;
        // this.accoutKeToan.passWord = val;
        // this.accoutSupport.passWord = val;
        // this.accoutThongKe.passWord = val;
        // this.accoutBackup.passWord = val;
      },
    },
    passCode: {
      handler(val) {
        this.accoutRoot.passCode = val;
        // this.accoutKeToan.passCode = val;
        // this.accoutSupport.passCode = val;
        // this.accoutThongKe.passCode = val;
        // this.accoutBackup.passCode = val;
      },
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    handleClose() {
      this.$confirm(`Bạn có chắc muốn hủy ?`, `Xác nhận`, {
        confirmButtonText: "Đồng Ý",
        cancelButtonText: "Hủy",
        confirmButtonClass: "btn-success",
        cancelButtonClass: "btn-success-plain",
      })
        .then((response) => {
          console.log(response);
          this.close();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    close() {
      this.data = [];
      this.$emit("closeDialog");
    },
    cancel() {
      this.handleClose();
    },
    confirm() {
      let isValid =
        this.checkInput("text", this.typeDomain, "Domain") &&
        this.checkInput("text", this.nhaCungCap, "Nhà cung cấp") &&
        this.checkInput("text", this.accoutRoot.userName, "TK Chính") &&
        this.checkInput("text", this.accoutRoot.loginId, "Login ID") &&
        this.checkInput("text", this.accoutRoot.passWord, "Password") &&
        this.checkInput("date", this.data);
      if (isValid) {
        this.data = [];
        this.setDataForApi();
        this.data.push(this.accoutRoot);
        this.data.push(this.accoutKeToan);
        this.data.push(this.accoutSupport);
        this.data.push(this.accoutThongKe);
        this.data.push(this.accoutBackup);
        if (this.type === "add") {
          this.$emit("callApiInsertListPlayer", this.data);
        } else {
          this.$emit("callApiUpdateListPlayer", this.data);
        }
      }
    },
    checkInput(type, input, text) {
      if (type === "text") {
        if (input === "") {
          this.$alert(`Bạn phải chọn ${text}`, `Lỗi`, {
            confirmButtonText: "OK",
            confirmButtonClass: "btn-success",
            type: "error",
          });
          return false;
        }
      }
      return true;
    },
    setDataForApi() {
      // domain
      this.accoutRoot.typeDomain = this.typeDomain;
      this.accoutSupport.typeDomain = this.typeDomain;
      this.accoutKeToan.typeDomain = this.typeDomain;
      this.accoutThongKe.typeDomain = this.typeDomain;
      this.accoutBackup.typeDomain = this.typeDomain;

      // nhà cung cấp
      this.accoutRoot.supplier = this.nhaCungCap;
      this.accoutSupport.supplier = this.nhaCungCap;
      this.accoutKeToan.supplier = this.nhaCungCap;
      this.accoutThongKe.supplier = this.nhaCungCap;
      this.accoutBackup.supplier = this.nhaCungCap;

      // nhà nhận hàng (agent Id giữ chính)
      this.accoutRoot.receiverId = this.receiverId;
      this.accoutSupport.receiverId = this.receiverId;
      this.accoutKeToan.receiverId = this.receiverId;
      this.accoutThongKe.receiverId = this.receiverId;
      this.accoutBackup.receiverId = this.receiverId;
      // cấp dưới của nhà nhận hàng
      this.accoutRoot.distributorId = this.distributorId;
      this.accoutSupport.distributorId = this.distributorId;
      this.accoutKeToan.distributorId = this.distributorId;
      this.accoutThongKe.distributorId = this.distributorId;
      this.accoutBackup.distributorId = this.distributorId;
    },
    changeGiaoThau(node) {
      this.distributorId = node.agentId;
      this.treeDataDownLine = [];
      this.treeDataDownLine.push(node);
    },
    changeGiaoChild(node) {
      this.receiverId = node.agentId;
    },
  },
};
</script>

<style lang="scss">
.add-or-edit-player {
  .el-dialog__body {
    padding-top: 20px;
    height: 68vh;
    overflow: auto;
  }

  // .el-select {
  //   width: 100%;
  // }

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100% !important;
  }

  .group-input-user {
    height: 50px;
    margin-bottom: 10px;

    > div {
      height: 100%;
      display: flex;
      align-items: center;
    }
    span {
      margin-left: 10px;
    }
  }

  .header-group {
    background-color: #1b6ca8;
    color: white;
    font-size: 18px;
  }

  .group-1 {
    background-color: #84a9ac;
  }

  .group-2 {
    background-color: #f7f5dd;
  }

  .group-3 {
    background-color: #8de8b9;
  }

  [class*="el-col-"] {
    position: relative;

    .required-icon {
      color: red;
      position: absolute;
      top: 0;
      left: -10px;
    }
  }
}

.btn-success {
  color: #fff !important;
  background-color: #0e9cc0 !important;
  border-color: #0e9cc0 !important;

  &:hover {
    color: #fff !important;
    background: #85ce61 !important;
    border-color: #85ce61 !important;
  }
}

.btn-success-plain {
  color: #0e9cc0 !important;
  background: #f0f9eb !important;
  border-color: #c2e7b0 !important;

  &:hover {
    color: #fff !important;
    background: #85ce61 !important;
    border-color: #85ce61 !important;
  }
}
</style>
