<template>
  <div id="list-user" class="list-user" v-on:keyup.enter="search()">
    <Header :title="title.toUpperCase()" />

    <div class="page-content">
      <div class="container-fluid">
        <el-row :gutter="20" class="conditions-search">
          <el-col :xs="24" :sm="15" :md="16" :lg="17" :xl="17">
            <label class="title-select">User Name</label>
            <el-input v-model="userName" clearable></el-input>
          </el-col>
          <el-col :xs="24" :sm="9" :md="8" :lg="6" :xl="6" :offset="0">
            <el-button type="success" @click="search()">Tìm kiếm</el-button>
            <el-button
              type="success"
              icon="el-icon-circle-plus-outline"
              @click="onClickAddOrEditUser('add')"
              >Thêm User</el-button
            >
          </el-col>
        </el-row>
        <div class="page-title">
          <h5 class="text-uppercase">Danh sách tài khoản</h5>
        </div>
        <div class="table-content">
          <u-table
            ref="tableListUser"
            :data="
              dataListUser.filter(
                (data) =>
                  !userName ||
                  data.name.toLowerCase().includes(userName.toLowerCase())
              )
            "
            :height="700"
            use-virtual
            border
            stripe
            highlight-current-row
            size="small"
            header-row-class-name="header-row-class-name"
            empty-text="Không có dữ liệu"
            @current-change="handleCurrentChange"
          >
            <u-table-column label="ID" width="50">
              <template slot-scope="scope">
                <span>{{ scope.$index + 1 }}</span>
              </template>
            </u-table-column>
            <u-table-column prop="name" label="TÊN TÀI KHOẢN" sortable>
              <template slot-scope="scope">
                <span>{{ scope.row.name }}</span>
              </template>
            </u-table-column>
            <u-table-column prop="userName" label="Login ID" sortable>
              <template slot-scope="scope">
                <span @click="copyText(scope.row.userName, $event)">{{
                  scope.row.userName
                }}</span>
              </template>
            </u-table-column>
            <u-table-column prop="password" label="MẬT KHẨU" sortable>
              <template slot-scope="scope">
                <span @click="copyText(scope.row.password, $event)">{{
                  scope.row.password
                }}</span>
              </template>
            </u-table-column>
            <u-table-column prop="delFlg" label="TRẠNG THÁI" sortable>
              <template slot-scope="scope">
                <span>{{ scope.row.delFlg }}</span>
              </template>
            </u-table-column>
            <u-table-column prop="note" label="GHI CHÚ" sortable>
              <template slot-scope="scope">
                <span>{{ scope.row.note }}</span>
              </template>
            </u-table-column>
            <u-table-column prop="roleName" label="PHÂN QUYỀN" sortable>
              <template slot-scope="scope">
                <div v-for="role in scope.row.roles" :key="role">
                  <span> {{ role }}</span>
                </div>
              </template>
            </u-table-column>
            <u-table-column prop="phone" label="Phone 1" sortable>
              <template slot-scope="scope">
                <span>{{ scope.row.phone }}</span>
              </template>
            </u-table-column>
            <u-table-column prop="phone2" label="Phone 2" sortable>
              <template slot-scope="scope">
                <span>{{ scope.row.phone2 }}</span>
              </template>
            </u-table-column>
            <u-table-column prop="email" label="Email" sortable>
              <template slot-scope="scope">
                <span>{{ scope.row.email }}</span>
              </template>
            </u-table-column>

            <u-table-column width="120">
              <template slot-scope="scope">
                <div class="cell-btn">
                  <el-button
                    title="Chỉnh sửa Player"
                    type="success"
                    icon="el-icon-edit-outline"
                    circle
                    :disabled="!(currentRow && currentRow.id === scope.row.id)"
                    @click="onClickAddOrEditUser('edit')"
                  ></el-button>
                  <el-button
                    title="Xóa Player"
                    type="danger"
                    icon="el-icon-delete"
                    circle
                    :disabled="!(currentRow && currentRow.id === scope.row.id)"
                    @click="deleteUser()"
                  ></el-button>
                </div>
              </template>
            </u-table-column>
          </u-table>
        </div>
      </div>
    </div>
    <AddEditUser
      :dialog-visible="visibleAddOrEditUser"
      :data-row="dataRow"
      :type="modeAddOrEditUser"
      @closeDialog="closeAddOrEditUser"
      @addUser="addUser"
      @editUser="editUser"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ApiService from "../../service/api.service";
import AddEditUser from "./componentUser/AddEditUser.vue";
import Header from "../Common/Header.vue";

export default {
  name: "ListUser",

  components: {
    AddEditUser,
    Header,
  },
  data() {
    return {
      title: "QUẢN LÝ TÀI KHOẢN",
      visibleAddOrEditUser: false,
      currentRow: "",
      dataRow: "",
      modeAddOrEditUser: "",
      userName: "",
      dataListUser: [],
      page: 1,
      perPage: 20,
      pages: [],
    };
  },
  computed: {
    ...mapState("ListUser", []),
  },
  beforeCreate() {
    console.log("I have not been created yet!");
  },
  created() {},
  beforeMount() {
    console.log("I am about to be mounted to the DOM!");
  },
  mounted() {
    console.log("I am mounted to the DOM!");
    this.getDataListUser();
  },
  beforeDestroy() {
    console.log("I am about to be removed from the DOM!");
  },
  destroyed() {
    console.log("I no longer exist...");
  },
  methods: {
    ...mapActions("ListUser", ["setModeOptions", "setDomainOptions"]),
    errorApi(error) {
      console.log(error);
      let message = "";
      let code = "";
      if (error.response && error.response.data) {
        message = error.response.data.message || error.response.data.error;
        code = error.response.data.code || error.response.data.status;
      } else {
        message = error.message;
      }
      this.$alert(message, `Lỗi ${code}`, {
        confirmButtonText: "OK",
        confirmButtonClass: "btn-success",
        type: "error",
      });
    },
    getDataListUser() {
      this.$root.fullScreenLoading = true;
      let url = `${this.$root.url}/user/getUsers`;
      let params = {
        userName: this.userName,
      };
      let _this = this;
      ApiService.get(url, params)
        .then((response) => {
          _this.$root.fullScreenLoading = false;
          _this.dataListUser = response.data.map((item) => {
            item.insDate = item.insDate
              ? _this.$moment(item.insDate).format(_this.$root.formatDate)
              : item.insDate;
            item.updDate = item.updDate
              ? _this.$moment(item.updDate).format(_this.$root.formatDate)
              : item.updDate;
            item.delFlg = item.delFlg == 0 ? "Open" : "Close";
            return item;
          });

          setTimeout(() => {
            _this.$refs.tableListUser.doLayout();
          }, 100);
        })
        .catch((error) => {
          _this.$root.fullScreenLoading = false;
          _this.errorApi(error);
        });
    },
    addUser(val) {
      let param = this.setData(val);
      let url = `${this.$root.url}/user/insert`;
      let _this = this;
      ApiService.put(url, param)
        .then((response) => {
          console.log(response);
          _this.getDataListUser();
        })
        .catch((error) => {
          _this.errorApi(error);
        });
    },
    editUser(val) {
      let param = this.setData(val);
      let url = `${this.$root.url}/user/update`;
      let _this = this;
      ApiService.put(url, param)
        .then((response) => {
          console.log(response);
          _this.getDataListUser();
        })
        .catch((error) => {
          _this.errorApi(error);
        });
    },
    deleteUser() {
      let _this = this;
      this.$confirm(`Bạn có chắc muốn xóa User?`, `Xác nhận`, {
        confirmButtonText: "Đồng Ý",
        cancelButtonText: "Hủy",
        confirmButtonClass: "btn-success",
        cancelButtonClass: "btn-success-plain",
      })
        .then(() => {
          let param = this.setData(this.currentRow);
          let url = `${this.$root.url}/user/delete`;
          ApiService.delete(url, param)
            .then((response) => {
              console.log(response);
              _this.getDataListUser();
            })
            .catch((error) => {
              _this.errorApi(error);
            });
        })
        .catch((response) => {
          console.log(response);
        });
    },
    setData(val) {
      let row = {};
      // row.insDate = val.insDate
      //   ? this.$moment(val.insDate).format(this.$root.formatDateAPI)
      //   : val.insDate;
      // row.updDate = val.updDate
      //   ? this.$moment(val.updDate).format(this.$root.formatDateAPI)
      //   : val.updDate;
      row.id = val.id;
      row.userName = val.userName;
      row.name = val.name;
      row.password = val.password;
      row.note = val.note;
      row.delFlg = val.delFlg;
      row.roles = val.roles;
      row.phone = val.phone;
      row.phone2 = val.phone2;
      row.email = val.email;
      return row;
    },
    search() {
      this.getDataListUser();
    },
    goBack() {
      this.$router.push("menu");
    },
    handleCurrentChange(currentRow) {
      this.currentRow = currentRow;
    },
    onClickAddOrEditUser(val) {
      this.visibleAddOrEditUser = true;
      this.dataRow =
        val === "add" ? "" : JSON.parse(JSON.stringify(this.currentRow));
      this.modeAddOrEditUser = val;
    },
    closeAddOrEditUser() {
      this.visibleAddOrEditUser = false;
      this.dataRow = "";
      this.modeAddOrEditUser = "";
    },
    async copyText(mytext, event) {
      try {
        // navigator.clipboard.writeText only run with http @@@@
        if (!navigator.clipboard) {
          this.fallbackCopyTextToClipboard(mytext, event);
          return;
        }
        await navigator.clipboard.writeText(mytext);
        this.$toast.success(`Copy: "${mytext}"`);
      } catch ($e) {
        console.log($e);
        //this.$toast.error("Cannot copy");
      }
    },
    fallbackCopyTextToClipboard(text, event) {
      var textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = event.offsetX;
      textArea.style.left = "50vw";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        var successful = document.execCommand("copy");
        let msg = successful ? "successful" : "unsuccessful";
        this.$toast.success(`Copy: "${text}" ${msg}`);
      } catch (err) {
        console.log(err);
        //console.error("Fallback: Oops, unable to copy", err);
      }
      document.body.removeChild(textArea);
    },
  },
};
</script>

<style lang="scss">
.list-user {
  background-color: #fff !important;

  .page-content {
    min-height: calc(100vh - 90px);
    .page-title {
      background-color: #0e9cc0;
      padding: 10px;
      h5 {
        color: #fff;
        margin: 0;
      }
    }

    .table-content {
      width: 100%;
      overflow: auto;
      .el-table {
        min-width: 1200px;
      }
    }
    .header-row-class-name {
      th {
        background-color: #f56c6c;
        color: black;
        text-align: center;
      }
    }

    .el-table {
      .cell {
        word-break: break-word;
      }
    }

    .el-table__body {
      tr {
        &.current-row {
          td {
            background-color: #96dcee !important;
          }
        }
        &:hover > td {
          background-color: #96dcee !important;
        }
      }
    }
    .conditions-search {
      margin: 3vh 0;
      .title-select {
        margin-right: 15px;
        white-space: nowrap;
      }
      .el-col {
        display: flex;
        align-items: center;
        padding: 10px;
        .el-select {
          width: 100%;
        }

        .el-input {
          width: 90%;
        }
      }
    }
  }
}
button {
  outline: none !important;
}
@media (max-width: 767px) {
  .hidden-mobile {
    display: none !important;
  }
}
</style>
