<template>
  <div class="dac-biet">
    <el-row :gutter="20" class="title-table">
      <el-col :span="2" :offset="0">
        <span>CÁCH CHƠI ĐẶC BIỆT</span>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" class="conditions-search">
      <el-col :span="40" :offset="0">
        <el-input
          v-model="searchDataCT"
          placeholder="Nhập user name"
          class="search-form"
          clearable
        />
        <span>Sort chính :</span>
        <el-select v-model="columnFirst" class="select-key-sort">
          <el-option
            v-for="item in columnSort"
            :key="item.codeKey"
            :label="item.codeName"
            :value="item.codeKey"
          >
          </el-option>
        </el-select>
        <el-select v-model="ascFirst" class="select-type-sort">
          <el-option key="1" label="Giảm dần" value="1"> </el-option>
          <el-option key="0" label="Tăng dần" value="0"> </el-option>
        </el-select>
        <span>Và Sort phụ:</span>
        <el-select v-model="columnThen" class="select-key-sort">
          <el-option
            v-for="item in columnSort"
            :key="item.codeKey"
            :label="item.codeName"
            :value="item.codeKey"
          >
          </el-option>
        </el-select>
        <el-select v-model="ascThen" class="select-type-sort">
          <el-option key="1" label="Giảm dần" value="1"> </el-option>
          <el-option key="0" label="Tăng dần" value="0"> </el-option>
        </el-select>
        <el-button type="success" @click="sort()">Sort</el-button>
      </el-col>
    </el-row>
    <el-pagination
      style="text-align: center; margin-top: 10px"
      layout="total, sizes, prev, pager, next, jumper"
      :total="this.dataDacBiet.length"
      @current-change="setPage"
      :page-sizes="[100, 500, 1000]"
      :page-size.sync="pageSize"
      @size-change="handleSizeChange"
    >
    </el-pagination>
    <el-table
      :ref="'tableDacBiet'"
      :data="pagedTableData"
      style="width: 100%"
      header-row-class-name="header-row-class-name"
      border
      size="small"
      empty-text="Không có dữ liệu"
    >
      <el-table-column
        prop="memberId"
        label="Tài Khoản"
        header-align="center"
        align="center"
        min-width="130"
        sortable
      >
        <template slot-scope="scope">
          <div :class="`${scope.row.scan ? '' : 'warning-row'}`">
            <span
              class="cell-label-user-name"
              @click="clickMemberIdToReport(scope.row, false)"
            >
              {{ scope.row.userName }}
            </span>
            <span
              title="New Tab"
              class="cell-label-user-name"
              @click="clickMemberIdToReport(scope.row, true)"
            >
              <i class="el-icon-document-copy"></i>
            </span>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        prop="betCount"
        label="Tổng mã cược"
        header-align="center"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          <span>
            {{ scope.row.betCount }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="winLoss"
        label="Win/Loss"
        header-align="center"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          <span
            :class="`${
              scope.row.winLoss > 0 ? 'cell-value-win' : 'cell-value-loss'
            }`"
          >
            {{ scope.row.winLoss }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="special1"
        label="Chuyên xỉu bẩn"
        header-align="center"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          <span
            class="cell-label-user-name"
            @click="clickNumberToReport(scope.row, 5, 15, 1)"
          >
            {{ scope.row.special1 }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="special2"
        label="Chuyên đánh kèo an toàn"
        header-align="center"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          <span
            class="cell-label-user-name"
            @click="clickNumberToReport(scope.row, 5, 15, 2)"
          >
            {{ scope.row.special2 }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="special3"
        label="Chuyên đánh xỉu và dưới"
        header-align="center"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          <span
            class="cell-label-user-name"
            @click="clickNumberToReport(scope.row, 5, 15, 3)"
          >
            {{ scope.row.special3 }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="rangeRateBetSpecial"
        label="Tỉ lệ vi phạm"
        header-align="center"
        align="center"
        min-width="75"
        sortable
      >
        <template slot-scope="scope">
          <span>
            {{ scope.row.rangeRateBetSpecial }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="special4"
        label="Xỉu trước trận"
        header-align="center"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          <span
            class="cell-label-user-name"
            @click="clickNumberToReport(scope.row, 5, 15, 4)"
          >
            {{ scope.row.special4 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="special5"
        label="Xỉu hiep 1"
        header-align="center"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          <span
            class="cell-label-user-name"
            @click="clickNumberToReport(scope.row, 5, 15, 5)"
          >
            {{ scope.row.special5 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="special6"
        label="Toàn trận"
        header-align="center"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          <span
            class="cell-label-user-name"
            @click="clickNumberToReport(scope.row, 5, 15, 6)"
          >
            {{ scope.row.special6 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="special7"
        label="Chuyên góc (corner)"
        header-align="center"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          <span
            class="cell-label-user-name"
            @click="clickNumberToReport(scope.row, 5, 15, 7)"
          >
            {{ scope.row.special7 }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="viewStatusBetSpecial"
        label="Tình trạng"
        header-align="center"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          <span title="Đã xem" class="cell-label-user-name">
            <el-checkbox
              v-model="scope.row.viewStatusBetSpecial"
              @change="changeStatus(scope.row, $event)"
            >
            </el-checkbox>
          </span>
          <span
            style="margin-left: 25px"
            title="Đã report"
            class="cell-label-user-name"
          >
            <el-checkbox
              v-model="scope.row.reportStatusBetSpecial"
              @change="changeStatusReport(scope.row, $event)"
            >
            </el-checkbox>
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ApiService from "../../../service/api.service";

export default {
  name: "XemNhanhDacBiet",
  props: {
    dataDacBiet: {
      default: [],
    },
    dateFromTo: {
      default: [],
    },
  },
  data() {
    return {
      urlMain: `${this.$root.url}/betscanv2`,
      searchDataCT: "",
      pageSize: 100,
      page: 1,
      columnSort: [
        {
          codeKey: 0,
          codeName: "Chọn cột cần sort",
        },
        {
          codeKey: "special1",
          codeName: "Chuyên xỉu bẩn",
        },
        {
          codeKey: "special2",
          codeName: "Chuyên đánh kèo an toàn",
        },
        {
          codeKey: "special3",
          codeName: "Chuyên đánh xỉu và dưới",
        },
        {
          codeKey: "winLoss",
          codeName: "Win loss",
        },
        {
          codeKey: "rangeRateBetSpecial",
          codeName: "Tỉ lệ vi phạm",
        },
      ],
      columnFirst: 0,
      columnThen: 0,
      ascFirst: "1",
      ascThen: "1",
    };
  },
  computed: {
    ...mapState("Report", [""]),
    pagedTableData() {
      if (this.searchDataCT !== "" && this.searchDataCT.length > 2) {
        return this.dataDacBiet.filter((row) => {
          const userNameSearch = row.userName.toString().toLowerCase();
          const searchTerm = this.searchDataCT.toLowerCase();
          return userNameSearch.includes(searchTerm);
        });
      } else {
        return this.dataDacBiet.slice(
          this.pageSize * this.page - this.pageSize,
          this.pageSize * this.page
        );
      }
    },
  },
  watch: {},
  beforeCreate() {
    console.log("I have not been created yet!");
  },
  created() {
    console.log("I have just been created!");
  },
  beforeMount() {
    console.log("I am about to be mounted to the DOM!");
  },
  mounted() {
    console.log("I am mounted to the DOM!");
  },
  beforeDestroy() {
    console.log("I am about to be removed from the DOM!");
  },
  destroyed() {
    console.log("I no longer exist...");
  },
  methods: {
    ...mapActions("Report", ["setShowCuocTheo"]),
    errorApi(error) {
      console.log(error);
      let message = "";
      let code = "";
      if (error.response) {
        message = error.response.data.message;
        code = error.response.data.code;
      } else {
        message = error.message;
      }
      this.$alert(message, `Lỗi ${code}`, {
        confirmButtonText: "OK",
        confirmButtonClass: "btn-success",
        type: "error",
      });
    },
    settingParramToReport(row, milisFrom, milisTo, type) {
      localStorage.setItem(
        "PARAM_FOR_LEVEL_3",
        JSON.stringify({
          memberId: row.userName,
          superId: row.superId,
          domain: row.typeDomain,
          //domainName: row.domainName,
          isFromScreenXemNhanh: true,
          dateFromTo: this.dateFromTo,
          milisFrom: milisFrom,
          milisTo: milisTo,
          isSearchInit: true,
          trungIp: false,
          buonCom: false,
          cuocTheo: false,
          trungGiaiCam: false,
          giaiCam: false,
          cuocXau: false,
          sapgia: false,
          ruiro: false,
          dacbiet: true,
          typeDacBiet: type,
        })
      );
    },
    clickMemberIdToReport(row, newTab) {
      this.settingParramToReport(row, 0, 4);
      if (newTab) {
        let routeData = this.$router.resolve("report");
        window.open(routeData.href, "_blank");
      } else {
        this.$router.push("report");
      }
    },
    clickNumberToReport(row, milisFrom, milisTo, type) {
      this.settingParramToReport(row, milisFrom, milisTo, type);
      let routeData = this.$router.resolve("report");
      window.open(routeData.href, "_blank");
    },
    changeStatus(row, event) {
      this.$root.fullScreenLoading = true;
      let url = `${this.urlMain}/setHasViewData`;
      let _this = this;
      let params = this.setParamAPI(row, event);
      ApiService.get(url, params)
        .then((response) => {
          if (response && response.data) {
            console.log(response.data);
          }
          _this.$root.fullScreenLoading = false;
        })
        .catch((error) => {
          _this.$root.fullScreenLoading = false;
          _this.errorApi(error);
        });
    },
    changeStatusReport(row, event) {
      this.$root.fullScreenLoading = true;
      let url = `${this.urlMain}/setHasReportData`;
      let _this = this;
      let params = this.setParamAPI(row, event);
      ApiService.get(url, params)
        .then((response) => {
          if (response && response.data) {
            console.log(response.data);
          }
          _this.$root.fullScreenLoading = false;
        })
        .catch((error) => {
          _this.$root.fullScreenLoading = false;
          _this.errorApi(error);
        });
    },
    setParamAPI(row, check) {
      const dateFrom = this.$moment(this.dateFromTo[0]).format(
        this.$root.formatDateAPI
      );
      const dateTo = this.$moment(this.dateFromTo[1]).format(
        this.$root.formatDateAPI
      );
      let params = {
        mode: 5,
        dateFrom: dateFrom,
        dateTo: dateTo,
        typeDomain: row.typeDomain,
        memberId: row.userName,
        check: check,
      };
      return params;
    },
    tableRowClassName({ row }) {
      if (!row.scan) {
        return "warning-row";
      } else {
        return "";
      }
    },
    sizeChangeTable(val) {
      this.pageSizeTable1 = val;
      this.pageTable1 = 1;
      this.pageTable2 = 1;
      // this.getJobHistory();
    },
    currentPageChangeTable(val) {
      this.pageTable1 = val;
      this.pageTable2 = 1;
      // this.getJobHistory();
    },
    setPage(val) {
      this.page = val;
    },
    handleSizeChange() {
      console.log(this.pageSize);
    },
    sort() {
      let first = this.columnFirst;
      let then = this.columnThen;
      if (this.ascFirst == 1) {
        first = "-" + this.columnFirst;
      }
      if (this.ascThen == 1) {
        then = "-" + this.columnThen;
      }
      // this.sortByAttribute(this.dataCuocTheo, "winLoss");
      this.dataDacBiet.sort(this.fieldSorter([first, then]));
    },
    sortByAttribute(array, ...attrs) {
      // generate an array of predicate-objects contains
      // property getter, and descending indicator
      let predicates = attrs.map((pred) => {
        let descending = pred.charAt(0) === "-" ? -1 : 1;
        pred = pred.replace(/^-/, "");
        return {
          getter: (o) => o[pred],
          descend: descending,
        };
      });
      // schwartzian transform idiom implementation. aka: "decorate-sort-undecorate"
      return array
        .map((item) => {
          return {
            src: item,
            compareValues: predicates.map((predicate) =>
              predicate.getter(item)
            ),
          };
        })
        .sort((o1, o2) => {
          let i = -1,
            result = 0;
          while (++i < predicates.length) {
            if (o1.compareValues[i] < o2.compareValues[i]) result = -1;
            if (o1.compareValues[i] > o2.compareValues[i]) result = 1;
            if ((result *= predicates[i].descend)) break;
          }
          return result;
        })
        .map((item) => item.src);
    },
    fieldSorter(fields) {
      return function (a, b) {
        return fields
          .map(function (o) {
            var dir = 1;
            if (o[0] === "-") {
              dir = -1;
              o = o.substring(1);
            }
            if (a[o] > b[o]) return dir;
            if (a[o] < b[o]) return -dir;
            return 0;
          })
          .reduce(function firstNonZeroValue(p, n) {
            return p ? p : n;
          }, 0);
      };
    },
    handleClick() {
      this.$emit("clickFromChildComponent", this.searchDataCT);
      this.page = 1;
      this.pageSize = 100;
    },
  },
};
</script>
<style lang="scss">
.dac-biet {
  .el-table {
    .selected-row {
      td {
        background-color: #96dcee !important;
      }
    }

    .row-cuoc-goc {
      td {
        background-color: #e2eff1 !important;
      }
    }

    .cell {
      word-break: break-word;

      .el-icon-circle-close {
        color: red;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
          opacity: 0.8;
          font-size: 18px;
        }
      }

      .text-bold {
        font-weight: bold;

        [class*=" el-icon-"],
        [class^="el-icon-"] {
          font-weight: bold;
        }
      }

      .text-red {
        color: red;
      }

      .tai-tren {
        color: mediumslateblue;
        font-weight: bold;
      }

      .xiu-duoi {
        color: mediumseagreen;
        font-weight: bold;
      }

      .cuoc-goc {
        &.tai-tren {
          color: blue;
          font-weight: bold;
        }

        &.xiu-duoi {
          color: green;
          font-weight: bold;
        }
      }
    }

    tr {
      td {
        &:first-child {
          .cell {
            .el-table__expand-icon {
              display: none;
            }
          }
        }
      }
    }
  }

  .conditions-search {
    margin-bottom: 1vh;
    //height: 40px;

    &.el-row {
      display: flex;
    }

    [class*="el-col-"] {
      display: flex;
      align-items: center;
      height: 100%;
    }

    input {
      margin-right: 10px;
    }
    .search-form {
      width: 140px;
      margin-right: 10px;
      float: right;
    }
    span {
      margin-right: 10px;
    }
    .select-key-sort {
      margin-right: 10px;
    }
    .select-type-sort {
      margin-right: 10px;
      max-width: 120px;
    }
  }
  .warning-row {
    background-color: #ccffcc;
    padding: 0;
    margin: 0;
  }
  .search-button {
    margin-left: 30px;
  }
}
</style>
