<template>
  <div id="login" class="login">
    <div class="content">
      <div>
        <div class="text-center">
          <img alt="icon" src="../../assets/img/logo.svg" />
        </div>
        <h1 class="text-center form-title">Sign In</h1>
        <el-form class="login-form" ref="form" @submit.native.prevent="login">
          <el-form-item prop="username">
            <el-input
              ref="userName"
              v-model="model.userName"
              placeholder="Username"
              required
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              ref="password"
              v-model="model.password"
              required
              placeholder="Password"
              :type="passwordFieldType"
              ><i
                slot="suffix"
                class="el-input__icon el-icon-view"
                @click="showPassword"
              ></i
            ></el-input>
          </el-form-item>
          <el-form-item class="text-center">
            <el-button
              class="login-button"
              type="primary"
              native-type="submit"
              block
              @click="handleSubmit"
              >Sign In <i class="el-icon-loading" v-if="loading"></i
            ></el-button>
          </el-form-item>
          <div class="text-center">
            <img
              style="width: 60px; height: 60px"
              alt="icon"
              src="../../assets/img/logo.svg"
            />
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
//import axios from "axios";
import ApiService from "../../service/api.service";

export default {
  name: "Login",
  data() {
    return {
      title: "Login",
      model: {
        userName: "",
        password: "",
      },
      passwordFieldType: "password",
      loading: false,
    };
  },
  computed: {
    ...mapState("Login", []),
  },
  beforeCreate() {
    console.log("I have not been created yet!");
  },
  created() {
    console.log("I have just been created!");
  },
  beforeMount() {
    console.log("I am about to be mounted to the DOM!");
  },
  mounted() {
    console.log("I am mounted to the DOM!");
  },
  beforeDestroy() {
    console.log("I am about to be removed from the DOM!");
  },
  destroyed() {
    console.log("I no longer exist...");
  },
  methods: {
    ...mapActions("Login", []),
    errorApi(error) {
      console.log(error);
      let message = "";
      let code = "";
      if (error.response) {
        message = error.response.data.message;
        code = error.response.data.code;
      } else {
        message = error.message;
      }
      this.$alert(message, `Lỗi ${code}`, {
        confirmButtonText: "OK",
        confirmButtonClass: "btn-success",
        type: "error",
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      let isValid =
        this.checkInput("text", this.model.userName, "Tài khoản", "userName") &&
        this.checkInput("text", this.model.password, "Mật khẩu", "password");
      if (isValid) {
        this.loading = true;
        if (this.model.password.length > 0) {
          let url = `${this.$root.url}/auth/login`;
          ApiService.put(url, {
            userName: this.model.userName,
            password: this.model.password,
          })
            .then((response) => {
              localStorage.setItem("user", JSON.stringify(response.data));
              localStorage.setItem("userName", response.data.userName);
              localStorage.setItem("jwt", response.data.token);

              if (localStorage.getItem("jwt") != null) {
                if (this.$route.params.nextUrl != null) {
                  this.$router.push(this.$route.params.nextUrl);
                } else {
                  this.$router.push("menu");
                }
              }
            })
            .catch((error) => {
              alert(error.response.data.message);
              localStorage.removeItem("jwt");
              localStorage.removeItem("user");
              localStorage.removeItem("userName");
            });
        }
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      }
    },
    checkInput(type, input, text, ref) {
      if (type === "text") {
        if (input === "") {
          this.$alert(`Bạn phải nhập ${text}`, `Lỗi`, {
            confirmButtonText: "OK",
            confirmButtonClass: "btn-success",
            type: "error",
          });
          if (ref === "userName") this.$refs.userName.focus();
          else this.$refs.password.focus();
          return false;
        }
      }
      return true;
    },
    showPassword() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
  },
};
</script>

<style lang="scss">
.login {
  display: flex;
  height: 100vh;
  background-image: url("../../assets/img/bg_login.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  .content {
    background-color: #ecf3e1af;
    width: 400px;
    margin: auto;
    padding: 50px 30px;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    .form-title {
      margin: 25px 0;
      color: #3cb878;
    }
    img {
      max-height: 120px;
      margin: 0 !important;
    }
    .login-form .el-form-item input {
      padding-left: 10px;
      font-style: italic;
      border-radius: 10px;
    }
    button {
      background-color: #3cb878 !important;
      width: 60%;
      border: none;
      border-radius: 10px;
      font-size: 18px;
      outline: none !important;
    }
    button:hover {
      background-color: #41d388 !important;
      opacity: 0.8;
    }
  }
  .el-input__icon {
    cursor: pointer;
    color: #aaa;
  }
}
@media (max-width: 767px) {
  .login {
    .content {
      width: 90vw !important;
    }
  }
}
</style>
