const player = {
  namespaced: true,
  state: {
    domainOptions: [],
    domainName: "",
    reportTable: "0",
    timeUpdate: "",
  },
  mutations: {
    setDomainOptions(state, value) {
      state.domainOptions = value;
    },
    setDomainNm(state, value) {
      state.domainName = value;
    },
    setReportTable(state, value) {
      state.reportTable = value;
    },
    setTimeUpdate(state, value) {
      state.timeUpdate = value;
    },
  },
  actions: {
    setDomainOptions(context, value) {
      context.commit("setDomainOptions", value);
    },
    setDomainNm(context, value) {
      context.commit("setDomainNm", value);
    },
    setReportTable(context, value) {
      context.commit("setReportTable", value);
    },
    setTimeUpdate(context, value) {
      context.commit("setTimeUpdate", value);
    },
  },
  modules: {},
};

export default player;
