<template>
  <el-dialog
    width="80vw"
    top="10vh"
    :title="`${type === 'add' ? 'Thêm' : 'Chỉnh sửa'} Player`"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    :lock-scroll="false"
    class="add-or-edit-player"
  >
    <!-- body -->
    <el-row justify="center" align="center" class="group-input">
      <el-col :span="12" :offset="0">
        <el-col :span="4" :offset="1">
          <span>Down Line</span>
        </el-col>
        <el-col :span="18" :offset="0">
          <el-input v-model="data.downLine" size="small" clearable></el-input>
        </el-col>
      </el-col>

      <el-col :span="12" :offset="0">
        <el-col :span="4" :offset="1">
          <span>NCC</span>
        </el-col>
        <el-col :span="18" :offset="0">
          <el-input v-model="data.supplier" size="small" clearable></el-input>
        </el-col>
      </el-col>
    </el-row>

    <el-row justify="center" align="center" class="group-input">
      <el-col :span="12" :offset="0">
        <el-col :span="4" :offset="1">
          <span class="required-icon" v-if="type === 'add'">*</span>
          <span>Domain</span>
        </el-col>
        <el-col :span="18" :offset="0">
          <el-select
            v-model="data.typeDomain"
            value-key="codeKey"
            size="small"
            placeholder="Chọn Domain"
            :disabled="type === 'edit'"
          >
            <el-option
              v-for="item in domainOptions"
              :key="item.codeKey"
              :label="item.codeName"
              :value="item.codeKey"
              no-data-text="Không có dữ liệu"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-col>

      <el-col :span="12" :offset="0">
        <el-col :span="4" :offset="1">
          <span class="required-icon" v-if="type === 'add'">*</span>
          <span>TK Chính</span>
        </el-col>
        <el-col :span="18" :offset="0">
          <el-input
            v-model="data.userName"
            size="small"
            clearable
            :disabled="type === 'edit'"
          ></el-input>
        </el-col>
      </el-col>
    </el-row>

    <el-row justify="center" align="center" class="group-input">
      <el-col :span="12" :offset="0">
        <el-col :span="4" :offset="1">
          <span class="required-icon" v-if="type === 'add'">*</span>
          <span>Login ID</span>
        </el-col>
        <el-col :span="18" :offset="0">
          <el-input v-model="data.loginId" size="small" clearable></el-input>
        </el-col>
      </el-col>
      <el-col :span="12" :offset="0">
        <el-col :span="4" :offset="1">
          <span class="required-icon">*</span>
          <span>Password</span>
        </el-col>
        <el-col :span="18" :offset="0">
          <el-input v-model="data.passWord" size="small" clearable></el-input>
        </el-col>
      </el-col>
    </el-row>

    <el-row justify="center" align="center" class="group-input">
      <el-col :span="12" :offset="0">
        <el-col :span="4" :offset="1">
          <span class="required-icon">*</span>
          <span>PCode</span>
        </el-col>
        <el-col :span="18" :offset="0">
          <el-input v-model="data.passCode" size="small" clearable></el-input>
        </el-col>
      </el-col>
      <el-col :span="12" :offset="0">
        <el-col :span="4" :offset="1">
          <span class="required-icon">*</span>
          <span>Trạng Thái</span>
        </el-col>
        <el-col :span="18" :offset="0">
          <el-select
            v-model="data.status"
            value-key="codeKey"
            size="small"
            placeholder="Chọn Status"
          >
            <el-option
              v-for="item in statusOptions"
              :key="item.codeKey"
              :label="item.codeName"
              :value="item.codeKey"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-col>
    </el-row>

    <el-row
      justify="center"
      align="center"
      class="group-input"
      v-if="isShowDate"
    >
      <el-col :span="12" :offset="0">
        <el-col :span="4" :offset="1">
          <span>Date Start</span>
        </el-col>
        <el-col :span="18" :offset="0">
          <el-date-picker
            v-model="data.crDateFrom"
            type="date"
            size="small"
            placeholder="Chọn ngày"
            format="yyyy/MM/dd"
          >
          </el-date-picker>
        </el-col>
      </el-col>
      <el-col :span="12" :offset="0">
        <el-col :span="4" :offset="1">
          <span>Date End</span>
        </el-col>
        <el-col :span="18" :offset="0">
          <el-date-picker
            v-model="data.crDateTo"
            type="date"
            size="small"
            placeholder="Chọn ngày"
            format="yyyy/MM/dd"
          >
          </el-date-picker>
        </el-col>
      </el-col>
    </el-row>
    <el-row justify="center" align="center" class="group-input">
      <el-col :span="12" :offset="0">
        <el-col :span="4" :offset="1">
          <span>Note</span>
        </el-col>
        <el-col :span="18" :offset="0">
          <el-input
            v-model="data.note"
            size="small"
            clearable
            placeholder="note"
          ></el-input>
        </el-col>
      </el-col>
      <el-col :span="12" :offset="0">
        <el-col :span="4" :offset="1">
          <span>Scan</span>
        </el-col>
        <el-col :span="18" :offset="0">
          <input type="checkbox" id="checkbox" v-model="data.scan" />
        </el-col>
      </el-col>
    </el-row>

    <!-- footer -->
    <el-row slot="footer" class="dialog-footer">
      <el-col :span="24">
        <el-button type="success" plain @click="cancel()">Hủy</el-button>
        <el-button type="success" @click="confirm()">{{
          type === "add" ? "Thêm" : "Lưu"
        }}</el-button>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "AddOrEditPlayer",
  props: ["dialogVisible", "dataRow", "mode", "type"],
  data() {
    return {
      data: {},
      modeOptions: [
        {
          codeKey: "",
          codeName: "",
        },
      ],
      isShowDate: true,
      statusOptions: [
        {
          codeKey: 0,
          codeName: "Open",
        },
        {
          codeKey: 1,
          codeName: "Closed",
        },
      ],
      managersOptions: [
        {
          codeKey: "0",
          codeName: "Công Ty",
        },
        {
          codeKey: "1",
          codeName: "Ngoài Công Ty",
        },
      ],
    };
  },
  computed: {
    ...mapState("Player", ["domainOptions"]),
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        return;
      }
      if (this.dataRow) {
        this.data = this.dataRow;
        this.data.crDateFrom = this.dataRow.crDateFrom
          ? this.dataRow.crDateFrom
          : this.$moment();
        this.data.crDateTo = this.dataRow.crDateTo
          ? this.dataRow.crDateTo
          : this.$moment();
      } else {
        this.data = {
          crDateFrom: this.$moment(),
          crDateTo: this.$moment(),
          crDatetimeRun: "",
          delivered: "",
          downLine: "",
          loginId: "",
          mode: this.mode.mode,
          modeNm: this.mode.modeNm,
          note: "",
          passCode: "",
          passWord: "",
          status: "",
          supplier: "",
          typeDomain: "",
          typeDomainNm: "",
          typeManager: "",
          typeManagerNm: "",
          updDate: "",
          userName: "",
        };
      }
    },
    mode(val) {
      this.isShowDate = val.mode === "4" || val.mode === "5";
    },
  },
  beforeCreate() {
    console.log("I have not been created yet!");
  },
  created() {
    console.log("I have just been created!");
  },
  beforeMount() {
    console.log("I am about to be mounted to the DOM!");
  },
  mounted() {
    console.log("I am mounted to the DOM!");
  },
  beforeDestroy() {
    console.log("I am about to be removed from the DOM!");
  },
  destroyed() {
    console.log("I no longer exist...");
  },
  methods: {
    handleClose() {
      this.$confirm(
        `Bạn có chắc muốn hủy ${
          this.type === "add" ? "thêm" : "chỉnh sửa"
        } Player?`,
        `Xác nhận`,
        {
          confirmButtonText: "Đồng Ý",
          cancelButtonText: "Hủy",
          confirmButtonClass: "btn-success",
          cancelButtonClass: "btn-success-plain",
        }
      )
        .then((response) => {
          console.log(response);
          this.close();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    close() {
      this.data = {};
      this.$emit("closeDialog");
    },
    cancel() {
      this.handleClose();
    },
    confirm() {
      let isValid =
        this.checkInput("text", this.data.typeDomain, "Domain") &&
        this.checkInput("text", this.data.userName, "TK Chính") &&
        this.checkInput("text", this.data.loginId, "Login ID") &&
        this.checkInput("text", this.data.passWord, "Password") &&
        this.checkInput("text", this.data.passCode, "PCode") &&
        this.checkInput("text", this.data.status, "Trạng Thái") &&
        this.checkInput("date", this.data);
      if (isValid) {
        if (this.type === "add") {
          this.$emit("addPlayer", this.data);
        } else {
          this.$emit("editPlayer", this.data);
        }
        this.close();
      }
    },
    checkInput(type, input, text) {
      if (type === "text") {
        if (input === "") {
          this.$alert(`Bạn phải chọn ${text}`, `Lỗi`, {
            confirmButtonText: "OK",
            confirmButtonClass: "btn-success",
            type: "error",
          });
          return false;
        }
      } else if (type === "date") {
        if (this.$moment(input.crDateFrom).isAfter(input.crDateTo)) {
          this.$alert(`Bạn phải chọn Date Start trước Date End`, `Lỗi`, {
            confirmButtonText: "OK",
            confirmButtonClass: "btn-success",
            type: "error",
          });
          return false;
        }
      }
      return true;
    },
  },
};
</script>

<style lang="scss">
.add-or-edit-player {
  .el-dialog__body {
    padding-top: 20px;
    height: 50vh;
    overflow: auto;
  }

  .el-select {
    width: 100%;
  }

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100% !important;
  }

  .group-input {
    height: 32px;
    margin-bottom: 10px;

    > div {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  [class*="el-col-"] {
    position: relative;

    .required-icon {
      color: red;
      position: absolute;
      top: 0;
      left: -10px;
    }
  }
}

.btn-success {
  color: #fff !important;
  background-color: #0e9cc0 !important;
  border-color: #0e9cc0 !important;

  &:hover {
    color: #fff !important;
    background: #85ce61 !important;
    border-color: #85ce61 !important;
  }
}

.btn-success-plain {
  color: #0e9cc0 !important;
  background: #f0f9eb !important;
  border-color: #c2e7b0 !important;

  &:hover {
    color: #fff !important;
    background: #85ce61 !important;
    border-color: #85ce61 !important;
  }
}
</style>
