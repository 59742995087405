<template>
  <el-dialog
    width="90vw"
    top="2vh"
    title="Tìm kiếm tài khoản"
    :search-string="searchString"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    :lock-scroll="false"
    class="search-player"
  >
    <!-- body -->
    <el-row justify="center" align="center" class="group-input">
      <el-input
        v-model="searchText"
        @keyup.enter.native="getDataListPlayer()"
        clearable
        placeholder="Tìm tài khoản"
        >Tìm kiếm</el-input
      >
      <el-button
        type="success"
        @click="getDataListPlayer()"
        :loading="isSearch"
      >
        Tìm kiếm
      </el-button>
      <el-button type="primary" @click="close"> Quay Lại </el-button>
    </el-row>
    <el-row justify="center" align="center">
      <!-- Danh sách player -->
      <div class="header-table"></div>
      <u-table
        ref="tableListPlayer"
        :data="dataPlayers"
        :height="700"
        use-virtual
        style="width: 100%"
        header-row-class-name="header-row-class-name"
        border
        stripe
        size="small"
        empty-text="Không có dữ liệu"
      >
        <u-table-column prop="upLine" label="UPLINE" min-width="40" sortable>
          <template slot-scope="scope">
            <span>{{ scope.row.upLine }}</span>
          </template>
        </u-table-column>

        <u-table-column
          prop="downLine"
          label="DOWNLINE"
          min-width="50"
          sortable
        >
          <template slot-scope="scope">
            <span>{{ scope.row.downLine }}</span>
          </template>
        </u-table-column>

        <u-table-column
          prop="supplierName"
          sort-by="supplier"
          label="NCC"
          min-width="30"
          sortable
        >
          <template slot-scope="scope">
            <span>{{ scope.row.supplierName }}</span>
          </template>
        </u-table-column>

        <u-table-column
          prop="typeDomainName"
          sort-by="typeDomain"
          label="WEB"
          min-width="30"
          sortable
        >
          <template slot-scope="scope">
            <span>{{ scope.row.typeDomainName }}</span>
          </template>
        </u-table-column>

        <u-table-column
          prop="levelAccount"
          label="LEVEL"
          min-width="30"
          sortable
        >
          <template slot-scope="scope">
            <span>{{ scope.row.levelAccountName }}</span>
          </template>
        </u-table-column>

        <u-table-column prop="userName" label="TK Chính" sortable>
          <template slot-scope="scope">
            <span>{{ scope.row.userName }}</span>
          </template>
        </u-table-column>

        <u-table-column
          prop="typeSub"
          order="typeSub"
          label="Loại sub"
          min-width="50"
          sortable
        >
          <template slot-scope="scope">
            <span>{{ scope.row.typeSub === 0 ? "Super" : "Subview" }}</span>
          </template>
        </u-table-column>
        <u-table-column
          prop="loginId"
          label="Login
          ID"
          sortable
          min-width="55"
        >
          <template slot-scope="scope">
            <span @click="copyText(scope.row.loginId, $event)">{{
              scope.row.loginId
            }}</span>
          </template>
        </u-table-column>
        <u-table-column prop="passWord" label="Password" min-width="60">
          <template slot-scope="scope">
            <span @click="copyText(scope.row.passWord, $event)">{{
              scope.row.passWord
            }}</span>
          </template>
        </u-table-column>
        <u-table-column prop="passCode" label="PCode" min-width="50">
          <template slot-scope="scope">
            <span @click="copyText(scope.row.passCode, $event)">{{
              scope.row.passCode
            }}</span>
          </template>
        </u-table-column>
        <u-table-column prop="typeManager" label="Quản lý" min-width="40">
          <template slot-scope="scope">
            <span>{{ scope.row.typeManagerName }}</span>
          </template>
        </u-table-column>
        <u-table-column prop="note" label="Ghi chú" min-width="80" sortable>
          <template slot-scope="scope">
            <el-input
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 4 }"
              v-model="scope.row.note"
              disabled
            ></el-input>
          </template>
        </u-table-column>
        <u-table-column
          v-if="modeLogin === 1 || modeLogin === 2"
          label="Action"
        >
          <template slot-scope="scope">
            <div class="cell-btn">
              <el-button
                title="Chỉnh sửa Player"
                type="primary"
                icon="el-icon-edit-outline"
                size="mini"
                circle
                @click="editCrrentPlayerRow('edit', scope.row)"
              ></el-button>
              <el-button
                v-show="modeLogin === 1"
                title="Xóa Player"
                type="danger"
                icon="el-icon-delete"
                size="mini"
                circle
                @click="deletePlayer(scope.row)"
              ></el-button>
            </div>
          </template>
        </u-table-column>
      </u-table>
    </el-row>

    <!-- footer -->
    <el-row slot="footer" class="dialog-footer">
      <el-col :span="24">
        <el-button type="success" @click="close()">Close</el-button>
      </el-col>
    </el-row>
    <AddOrEditPlayer
      :dialog-visible="visibleAddOrEditPlayer"
      :data-row="dataRowSuper"
      :current-agent-data="currentAgentData"
      :select-tree-agent="selectTreeAgent"
      :mode-btn-active="modeBtnActive"
      :type="modeAddOrEditPlayer"
      :title-popup-player="titlePopupPlayer"
      @callApiInsertPlayer="callApiInsertPlayer"
      @callApiUpdatePlayer="callApiUpdatePlayer"
      @closeDialog="closeAddOrEditPlayer"
    />
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import ApiService from "../../../service/api.service";
import AddOrEditPlayer from "./AddOrEditPlayer.vue";
export default {
  name: "SearchPlayer",
  props: [
    "dialogVisible",
    "searchString",
    "modeLogin",
    "currentAgentData",
    "selectTreeAgent",
  ],
  components: {
    AddOrEditPlayer,
  },
  data() {
    return {
      dataPlayers: [],
      searchText: "",
      isSearch: false,

      // Setting popup of player: for add edit player
      modeAddOrEditPlayer: "",
      visibleAddOrEditPlayer: false,
      modeBtnActive: 0,
      dataRowSuper: "",
      titlePopupPlayer: "",
    };
  },
  computed: {
    ...mapState("TongDaiLy", ["domainOptions"]),
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        return;
      } else {
        console.log(this.modeLogin);
        console.log(this.currentAgentData);
        console.log(this.selectTreeAgent);
        this.searchText = this.searchString;
        this.getDataListPlayer();
      }
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    errorApi(error) {
      console.log(error);
      let message = "";
      let code = "";
      if (error.response) {
        message = error.response.data.message;
        code = error.response.data.code;
      } else {
        message = error.message;
      }
      this.$alert(message, `Lỗi ${code}`, {
        confirmButtonText: "OK",
        confirmButtonClass: "btn-success",
        type: "error",
      });
    },
    handleClose() {
      this.close();
    },
    close() {
      this.dataAgent = {};
      this.searchText = "";
      this.$emit("closeDialog");
    },
    getDataListPlayer() {
      if (this.searchText !== "") {
        this.isSearch = true;
        let url = `${this.$root.url}/account/player/searchByString`;
        let _this = this;
        let params = {
          searchString: this.searchText,
        };
        this.dataPlayers = [];
        ApiService.get(url, params)
          .then((response) => {
            this.dataPlayers = response.data;
            setTimeout(() => {
              _this.$refs.tableListPlayer.doLayout();
            }, 200);
            this.isSearch = false;
          })
          .catch((error) => {
            this.isSearch = false;
            _this.errorApi(error);
          });
      }
    },
    callApiInsertPlayer(val) {
      let param = val;
      let url = `${this.$root.url}/account/player/insert`;
      let _this = this;
      ApiService.put(url, param)
        .then((response) => {
          console.log(response);
          this.closeAddOrEditPlayer();
          this.getDataListPlayer();
        })
        .catch((error) => {
          _this.errorApi(error);
        });
    },
    callApiUpdatePlayer(val) {
      let param = val;
      let url = `${this.$root.url}/account/player/update`;
      let _this = this;
      ApiService.put(url, param)
        .then((response) => {
          console.log(response);
          this.closeAddOrEditPlayer();
          this.getDataListPlayer();
        })
        .catch((error) => {
          _this.errorApi(error);
        });
    },
    deletePlayer(currentRowPlayer) {
      this.$confirm(
        `Bạn có chắc muốn xóa Player ${currentRowPlayer.userName}?`,
        `Xác nhận`,
        {
          confirmButtonText: "Đồng Ý",
          cancelButtonText: "Hủy",
          confirmButtonClass: "btn-success",
          cancelButtonClass: "btn-success-plain",
        }
      )
        .then(() => {
          let param = this.setData(currentRowPlayer);
          let url = `${this.$root.url}/account/player/delete`;
          let _this = this;
          ApiService.delete(url, param)
            .then((response) => {
              console.log(response);
              this.getDataListPlayer();
            })
            .catch((error) => {
              _this.errorApi(error);
            });
        })
        .catch((response) => {
          console.log(response);
        });
    },
    setData(val) {
      let row = {};
      row.playerId = val.playerId;
      row.typeSub = val.typeSub;
      row.typeDomain = val.typeDomain;
      row.supplier = val.supplier;
      row.typeManager = val.typeManager;
      row.levelAccount = val.levelAccount;
      row.originId = val.originId;
      row.parentId = val.parentId;
      row.distributorId = val.distributorId;
      row.receiverId = val.receiverId;
      row.status = val.status;
      row.userName = val.userName;
      row.loginId = val.loginId;
      row.passWord = val.passWord;
      row.passCode = val.passCode;
      row.note = val.note;
      row.mode = val.mode;
      return row;
    },
    editCrrentPlayerRow(val, currentRow) {
      if (currentRow.typeSub === 0) {
        this.modeBtnActive = 1;
      } else {
        this.modeBtnActive = 3;
      }
      this.titlePopupPlayer = "Chỉnh sửa Tài khoản";
      this.dataRowSuper = currentRow;
      this.modeAddOrEditPlayer = val;
      this.visibleAddOrEditPlayer = true;
    },
    closeAddOrEditPlayer() {
      this.visibleAddOrEditPlayer = false;
      this.dataRowSuper = "";
      this.modeAddOrEditPlayer = "";
    },
    async copyText(mytext, event) {
      try {
        // navigator.clipboard.writeText only run with http @@@@
        if (!navigator.clipboard) {
          this.fallbackCopyTextToClipboard(mytext, event);
          return;
        }
        await navigator.clipboard.writeText(mytext);
        this.$toast.success(`Copy: "${mytext}"`);
      } catch ($e) {
        console.log($e);
        //this.$toast.error("Cannot copy");
      }
    },
    fallbackCopyTextToClipboard(text, event) {
      var textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = event.offsetX;
      textArea.style.left = "50vw";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        var successful = document.execCommand("copy");
        let msg = successful ? "successful" : "unsuccessful";
        this.$toast.success(`Copy: "${text}" ${msg}`);
      } catch (err) {
        console.log(err);
        //console.error("Fallback: Oops, unable to copy", err);
      }
      document.body.removeChild(textArea);
    },
  },
};
</script>

<style lang="scss">
.search-player {
  .el-dialog__body {
    padding: 5px 20px 20px;
    height: 75vh;
    overflow: auto;
  }

  .group-input {
    align-items: center;
    text-align: center;
    .el-input,
    .el-input__inner {
      width: 25vw;
      margin-right: 10px;
    }
  }
}
.el-table th.el-table__cell {
  background-color: #fdcead;
  color: black;
  text-align: center;
}

.el-table {
  .cell {
    word-break: break-word;
    text-align: center;
  }
}
.header-table {
  background-color: #99b898;
  color: white;
  padding-left: 10px;
  height: 40px;
  line-height: 40px;
  margin-top: 15px;
}
.el-table__body {
  tr {
    &.current-row {
      td {
        background-color: #96dcee !important;
      }
    }
    &:hover > td {
      background-color: #96dcee !important;
    }
  }
}

.btn-success {
  color: #fff !important;
  background-color: #0e9cc0 !important;
  border-color: #0e9cc0 !important;

  &:hover {
    color: #fff !important;
    background: #85ce61 !important;
    border-color: #85ce61 !important;
  }
}

.btn-success-plain {
  color: #0e9cc0 !important;
  background: #f0f9eb !important;
  border-color: #c2e7b0 !important;

  &:hover {
    color: #fff !important;
    background: #85ce61 !important;
    border-color: #85ce61 !important;
  }
}
</style>
