<template>
  <div class="report-by-choice">
    <u-table
      ref="table"
      :data="dataTable"
      :height="700"
      :row-height="115"
      use-virtual
      style="width: 100%"
      header-row-class-name="header-row-class-name"
      border
      stripe
      highlight-current-row
      size="small"
      empty-text="Không có dữ liệu"
    >
      <!-- 1 -->
      <u-table-column
        label="Tài Khoản"
        prop="userName"
        header-align="center"
        width="140"
        sortable
      >
        <template slot-scope="scope">
          <span
            class="cell-label-user-name"
            @click="moveToThongKeTheoMember(scope.row, false)"
          >
            {{ scope.row.userName }}
          </span>
          <span
            title="New Tab"
            class="cell-label-user-name"
            @click="moveToThongKeTheoMember(scope.row, true)"
          >
            <i class="el-icon-document-copy"></i>
          </span>
        </template>
      </u-table-column>

      <!-- 2 -->
      <u-table-column label="Số mã cược" header-align="center">
        <!-- 2.1 -->
        <u-table-column
          label="Đồng"
          prop="refPercentZero"
          header-align="center"
          width="80"
          sortable
        >
          <template slot-scope="scope">
            <span class="cell-label-dong">Đồng:</span>
            <br />
            <span class="cell-value-dong">{{ scope.row.refPercentZero }}</span>
          </template>
        </u-table-column>

        <!-- 2.2 -->
        <u-table-column
          label="Trên"
          prop="refPercentOverTeam"
          header-align="center"
          width="80"
          sortable
        >
          <u-table-column
            label="Dưới"
            prop="refPercentUnderTeam"
            header-align="center"
            width="80"
            sortable
          >
            <template slot-scope="scope">
              <el-row class="cell-2-row">
                <el-col :span="24" :offset="0">
                  <span class="cell-label-1">Trên:</span>
                  <br />
                  <span class="cell-value-1">
                    {{ scope.row.refPercentOverTeam }}
                  </span>
                </el-col>
              </el-row>
              <el-row class="cell-2-row">
                <el-col :span="24" :offset="0">
                  <span class="cell-label-2">Dưới:</span>
                  <br />
                  <span class="cell-value-2">
                    {{ scope.row.refPercentUnderTeam }}
                  </span>
                </el-col>
              </el-row>
            </template>
          </u-table-column>
        </u-table-column>

        <!-- 2.3 -->
        <u-table-column
          label="Tài"
          prop="refPercentOver"
          header-align="center"
          width="80"
          sortable
        >
          <u-table-column
            label="Xỉu"
            prop="refPercentUnder"
            header-align="center"
            width="80"
            sortable
          >
            <template slot-scope="scope">
              <el-row class="cell-2-row">
                <el-col :span="24" :offset="0">
                  <span class="cell-label-1">Tài:</span>
                  <br />
                  <span class="cell-value-1">
                    {{ scope.row.refPercentOver }}
                  </span>
                </el-col>
              </el-row>
              <el-row class="cell-2-row">
                <el-col :span="24" :offset="0">
                  <span class="cell-label-2">Xỉu:</span>
                  <br />
                  <span class="cell-value-2">
                    {{ scope.row.refPercentUnder }}
                  </span>
                </el-col>
              </el-row>
            </template>
          </u-table-column>
        </u-table-column>

        <!-- 2.4 -->
        <u-table-column
          label="Góc"
          prop="refPercentCorner"
          header-align="center"
          width="115"
          sortable
        >
          <u-table-column
            label="Game khác"
            prop="refPercentOther"
            header-align="center"
            width="115"
            sortable
          >
            <template slot-scope="scope">
              <el-row class="cell-2-row">
                <el-col :span="24" :offset="0">
                  <span class="cell-label-3">Góc:</span>
                  <br />
                  <span class="cell-value-3">
                    {{ scope.row.refPercentCorner }}
                  </span>
                </el-col>
              </el-row>
              <el-row class="cell-2-row">
                <el-col :span="24" :offset="0">
                  <span class="cell-label-3">Game khác:</span>
                  <br />
                  <span class="cell-value-3">
                    {{ scope.row.refPercentOther }}
                  </span>
                </el-col>
              </el-row>
            </template>
          </u-table-column>
        </u-table-column>

        <!-- 2.5 -->
        <u-table-column
          label="Tổng"
          prop="refTotal"
          header-align="center"
          sortable
          width="80"
        >
          <template slot-scope="scope">
            <span class="cell-label-total">Tổng:</span>
            <br />
            <span class="cell-value-total">
              {{ scope.row.refTotal }}
            </span>
          </template>
        </u-table-column>
      </u-table-column>

      <!-- 3 -->
      <u-table-column label="TurnOver" header-align="center">
        <!-- 3.1 -->
        <u-table-column
          label="Đồng"
          prop="turnOverPercentZero"
          header-align="center"
          width="80"
          sortable
        >
          <template slot-scope="scope">
            <span class="cell-label-dong">Đồng:</span>
            <br />
            <span class="cell-value-dong">
              {{ scope.row.turnOverPercentZero }}
            </span>
          </template>
        </u-table-column>

        <!-- 3.2 -->
        <u-table-column
          label="Trên"
          prop="turnOverPercentOverTeam"
          header-align="center"
          width="80"
          sortable
        >
          <u-table-column
            label="Dưới"
            prop="turnOverPercentUnderTeam"
            header-align="center"
            width="80"
            sortable
          >
            <template slot-scope="scope">
              <el-row class="cell-2-row">
                <el-col :span="24" :offset="0">
                  <span class="cell-label-1">Trên:</span>
                  <br />
                  <span class="cell-value-1">
                    {{ scope.row.turnOverPercentOverTeam }}
                  </span>
                </el-col>
              </el-row>
              <el-row class="cell-2-row">
                <el-col :span="24" :offset="0">
                  <span class="cell-label-2">Dưới:</span>
                  <br />
                  <span class="cell-value-2">
                    {{ scope.row.turnOverPercentUnderTeam }}
                  </span>
                </el-col>
              </el-row>
            </template>
          </u-table-column>
        </u-table-column>

        <!-- 3.3 -->
        <u-table-column
          label="Tài"
          prop="turnOverPercentOver"
          header-align="center"
          width="80"
          sortable
        >
          <u-table-column
            label="Xỉu"
            prop="turnOverPercentUnder"
            header-align="center"
            width="80"
            sortable
          >
            <template slot-scope="scope">
              <el-row class="cell-2-row">
                <el-col :span="24" :offset="0">
                  <span class="cell-label-1">Tài:</span>
                  <br />
                  <span class="cell-value-1">
                    {{ scope.row.turnOverPercentOver }}
                  </span>
                </el-col>
              </el-row>
              <el-row class="cell-2-row">
                <el-col :span="24" :offset="0">
                  <span class="cell-label-2">Xỉu:</span>
                  <br />
                  <span class="cell-value-2">
                    {{ scope.row.turnOverPercentUnder }}
                  </span>
                </el-col>
              </el-row>
            </template>
          </u-table-column>
        </u-table-column>

        <!-- 3.4 -->
        <u-table-column
          label="Góc"
          prop="turnOverPercentCorner"
          header-align="center"
          width="115"
          sortable
        >
          <u-table-column
            label="Game khác"
            prop="turnOverPercentOther"
            header-align="center"
            width="115"
            sortable
          >
            <template slot-scope="scope">
              <el-row class="cell-2-row">
                <el-col :span="24" :offset="0">
                  <span class="cell-label-3">Góc:</span>
                  <br />
                  <span class="cell-value-3">
                    {{ scope.row.turnOverPercentCorner }}
                  </span>
                </el-col>
              </el-row>
              <el-row class="cell-2-row">
                <el-col :span="24" :offset="0">
                  <span class="cell-label-3">Game khác:</span>
                  <br />
                  <span class="cell-value-3">
                    {{ scope.row.turnOverPercentOther }}
                  </span>
                </el-col>
              </el-row>
            </template>
          </u-table-column>
        </u-table-column>

        <!-- 3.5 -->
        <u-table-column
          label="Tổng"
          prop="refTotal"
          header-align="center"
          width="80"
          sortable
        >
          <template slot-scope="scope">
            <span class="cell-label-total">Tổng:</span>
            <br />
            <span class="cell-value-total">{{ scope.row.turnOverTotal }}</span>
          </template>
        </u-table-column>
      </u-table-column>

      <!-- 4 -->
      <u-table-column label="Nhỏ / Lớn" header-align="center">
        <u-table-column
          label="Nhỏ"
          prop="minStake"
          header-align="center"
          width="70"
          sortable
        >
          <u-table-column
            label="Lớn"
            prop="maxStake"
            header-align="center"
            width="70"
            sortable
          >
            <template slot-scope="scope">
              <el-row class="cell-2-row">
                <el-col :span="24" :offset="0">
                  <span class="cell-label-3">Nhỏ:</span>
                  <br />
                  <span class="cell-value-2">
                    {{ scope.row.minStake }}
                  </span>
                </el-col>
              </el-row>
              <el-row class="cell-2-row">
                <el-col :span="24" :offset="0">
                  <span class="cell-label-3">Lớn:</span>
                  <br />
                  <span class="cell-value-1">
                    {{ scope.row.maxStake }}
                  </span>
                </el-col>
              </el-row>
            </template>
          </u-table-column>
        </u-table-column>
      </u-table-column>

      <!-- 5 -->
      <u-table-column label="Win Loss" header-align="center">
        <u-table-column
          label="Win Loss"
          prop="totalWinLoss"
          header-align="center"
          min-width="80"
          sortable
        >
          <u-table-column
            label="COM"
            prop="totalCom"
            header-align="center"
            min-width="80"
            sortable
          >
            <template slot-scope="scope">
              <el-row class="cell-2-row">
                <el-col :span="24" :offset="0">
                  <span class="cell-label">Winloss:</span>
                  <br />
                  <span
                    :class="`${
                      scope.row.totalWinLoss > 0
                        ? 'cell-value-1'
                        : 'cell-value-total'
                    }`"
                  >
                    {{ scope.row.totalWinLoss }}
                  </span>
                </el-col>
              </el-row>
              <el-row class="cell-2-row">
                <el-col :span="24" :offset="0">
                  <span class="cell-label">Com:</span>
                  <br />
                  <span class="cell-value-1">
                    {{ scope.row.com }}
                  </span>
                </el-col>
              </el-row>
            </template>
          </u-table-column>
        </u-table-column>
      </u-table-column>

      <!-- 6 -->
      <u-table-column label="Cược xấu" header-align="center">
        <u-table-column
          label="Số mã cược"
          prop="refPercentUnderDirty"
          header-align="center"
          width="120"
          sortable
        >
          <u-table-column
            label="TurnOver"
            prop="turnOverPercentUnderDirty"
            header-align="center"
            width="120"
            sortable
          >
            <template slot-scope="scope">
              <el-row class="cell-2-row">
                <el-col :span="24" :offset="0">
                  <span class="cell-label-3">Số mã cược:</span>
                  <br />
                  <span class="cell-value-3">
                    {{ scope.row.refPercentUnderDirty }}
                  </span>
                </el-col>
              </el-row>
              <el-row class="cell-2-row">
                <el-col :span="24" :offset="0">
                  <span class="cell-label-3">TurnOver:</span>
                  <br />
                  <span class="cell-value-3">
                    {{ scope.row.turnOverPercentUnderDirty }}
                  </span>
                </el-col>
              </el-row>
            </template>
          </u-table-column>
        </u-table-column>
      </u-table-column>

      <!-- 6 -->
      <!-- Comment code by a.Lân request 2021-08-21 -->
      <!--
      <u-table-column label="Giải cấm" header-align="center">
        <u-table-column
          label="Số mã cược"
          prop="countBanLeague"
          header-align="center"
          width="120"
          sortable
        >
          <u-table-column
            label="TurnOver"
            prop="turnOverBanLeague"
            header-align="center"
            width="120"
            sortable
          >
            <template slot-scope="scope">
              <el-row class="cell-2-row">
                <el-col :span="24" :offset="0">
                  <span class="cell-label-3">Số mã cược:</span>
                  <br />
                  <span class="cell-value-3">
                    {{ scope.row.countBanLeague }}
                  </span>
                </el-col>
              </el-row>
              <el-row class="cell-2-row">
                <el-col :span="24" :offset="0">
                  <span class="cell-label-3">TurnOver:</span>
                  <br />
                  <span class="cell-value-3">
                    {{ scope.row.turnOverBanLeague }}
                  </span>
                </el-col>
              </el-row>
            </template>
          </u-table-column>
        </u-table-column>
      </u-table-column>
      -->

      <!-- 7 -->
      <u-table-column label="Trùng IP / DS đen" header-align="center">
        <u-table-column
          label="Trùng IP"
          prop="sameIp"
          header-align="center"
          sortable
        >
          <u-table-column
            label="DS đen"
            prop="banIp"
            header-align="center"
            sortable
          >
            <template slot-scope="scope">
              <el-row class="cell-2-row">
                <el-col :span="24" :offset="0">
                  <div
                    class="click-cell"
                    @click="onClickBet(0, 5, scope.row, 0)"
                  >
                    <span class="cell-label-3">Trùng IP:</span>
                    <br />
                    <span class="cell-value-2">
                      {{ scope.row.sameIp }}
                    </span>
                  </div>
                </el-col>
              </el-row>
              <el-row class="cell-2-row">
                <el-col :span="24" :offset="0">
                  <span class="cell-label-3">DS đen:</span>
                  <br />
                  <span class="cell-value-2">
                    {{ scope.row.banIp || "-" }}
                  </span>
                </el-col>
              </el-row>
            </template>
          </u-table-column>
        </u-table-column>
      </u-table-column>

      <!-- 8 -->
      <u-table-column label="Cược theo" header-align="center">
        <!-- 8.1 -->
        <u-table-column
          label="0-5s"
          prop="betA1"
          header-align="center"
          sortable
        >
          <u-table-column
            label="21s-100s"
            prop="betA3"
            header-align="center"
            sortable
          >
            <template slot-scope="scope">
              <el-row class="cell-2-row">
                <el-col :span="24" :offset="0">
                  <div
                    class="click-cell"
                    @click="onClickBet(0, 5, scope.row, 1)"
                  >
                    <span class="cell-label-3">0s-5s:</span>
                    <br />
                    <span class="cell-value-2">
                      {{ scope.row.betA1 }}
                    </span>
                  </div>
                </el-col>
              </el-row>
              <el-row class="cell-2-row">
                <el-col :span="24" :offset="0">
                  <div
                    class="click-cell"
                    @click="onClickBet(21, 100, scope.row, 1)"
                  >
                    <span class="cell-label-3">21s-100s:</span>
                    <br />
                    <span class="cell-value-2">
                      {{ scope.row.betA3 }}
                    </span>
                  </div>
                </el-col>
              </el-row>
            </template>
          </u-table-column>
        </u-table-column>

        <!-- 8.2 -->
        <u-table-column
          label="6s-20s"
          prop="betA2"
          header-align="center"
          sortable
        >
          <u-table-column
            label="101s-200s"
            prop="betA4"
            header-align="center"
            sortable
          >
            <template slot-scope="scope">
              <el-row class="cell-2-row">
                <el-col :span="24" :offset="0">
                  <div
                    class="click-cell"
                    @click="onClickBet(6, 20, scope.row, 1)"
                  >
                    <span class="cell-label-3">6s-20s:</span>
                    <br />
                    <span class="cell-value-2">
                      {{ scope.row.betA2 }}
                    </span>
                  </div>
                </el-col>
              </el-row>
              <el-row class="cell-2-row">
                <el-col :span="24" :offset="0">
                  <div
                    class="click-cell"
                    @click="onClickBet(101, 200, scope.row, 1)"
                  >
                    <span class="cell-label-3">101s-200s:</span>
                    <br />
                    <span class="cell-value-2">
                      {{ scope.row.betA4 }}
                    </span>
                  </div>
                </el-col>
              </el-row>
            </template>
          </u-table-column>
        </u-table-column>
      </u-table-column>

      <!-- 9 -->
      <u-table-column label="Buôn Com" header-align="center">
        <!-- 9.1 -->
        <u-table-column
          label="0-5s"
          prop="betB1"
          header-align="center"
          sortable
        >
          <u-table-column
            label="21s-100s"
            prop="betB3"
            header-align="center"
            sortable
          >
            <template slot-scope="scope">
              <el-row class="cell-2-row">
                <el-col :span="24" :offset="0">
                  <div
                    class="click-cell"
                    @click="onClickBet(0, 5, scope.row, 2)"
                  >
                    <span class="cell-label-3">0s-5s:</span>
                    <br />
                    <span class="cell-value-2">
                      {{ scope.row.betB1 }}
                    </span>
                  </div>
                </el-col>
              </el-row>
              <el-row class="cell-2-row">
                <el-col :span="24" :offset="0">
                  <div
                    class="click-cell"
                    @click="onClickBet(101, 200, scope.row, 2)"
                  >
                    <span class="cell-label-3">101s-200s:</span>
                    <br />
                    <span class="cell-value-2">
                      {{ scope.row.betB3 }}
                    </span>
                  </div>
                </el-col>
              </el-row>
            </template>
          </u-table-column>
        </u-table-column>

        <!-- 9.2 -->
        <u-table-column
          label="6s-20s"
          prop="betB2"
          header-align="center"
          sortable
        >
          <u-table-column
            label="101s-200s"
            prop="betB4"
            header-align="center"
            sortable
          >
            <template slot-scope="scope">
              <el-row class="cell-2-row">
                <el-col :span="24" :offset="0">
                  <div
                    class="click-cell"
                    @click="onClickBet(6, 20, scope.row, 2)"
                  >
                    <span class="cell-label-3">6s-20s:</span>
                    <br />
                    <span class="cell-value-2">
                      {{ scope.row.betB2 }}
                    </span>
                  </div>
                </el-col>
              </el-row>
              <el-row class="cell-2-row">
                <el-col :span="24" :offset="0">
                  <div
                    class="click-cell"
                    @click="onClickBet(101, 200, scope.row, 2)"
                  >
                    <span class="cell-label-3">101s-200s:</span>
                    <br />
                    <span class="cell-value-2">
                      {{ scope.row.betB4 }}
                    </span>
                  </div>
                </el-col>
              </el-row>
            </template>
          </u-table-column>
        </u-table-column>
      </u-table-column>
    </u-table>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ApiService from "../../../service/api.service";

export default {
  name: "ReportByChoice",
  props: {
    isClickSearch: {
      default: false,
    },
    domain: {
      default: "00",
    },
    report: {
      default: "0",
    },
    nSportChecked: {
      default: true,
    },
    vSportChecked: {
      default: true,
    },
    memberId: {
      default: "",
    },
    superId: {
      default: "",
    },
    dateFromTo: {
      default: [],
    },
    level: {
      default: 1,
    },
  },
  data() {
    return {
      dataTable: [],
      pageTable: 1,
      pageSizeTable: 5,
      totalTable: 0,
      trungIp: true,
      cuocTheo: true,
      buonCom: true,
      isSearchInit: false,
      trungGiaiCam: true,
      giaiCam: true,
      cuocXau: true,
    };
  },
  computed: {
    ...mapState("XemTheoWinLoss", ["domainOptions"]),
  },
  watch: {
    isClickSearch(val) {
      if (val) {
        if (this.report === "1") {
          this.getReport();
        } else {
          this.dataTable = [];
        }
      }
    },
  },
  beforeCreate() {
    console.log("I have not been created yet!");
  },
  created() {
    console.log("I have just been created!");
  },
  beforeMount() {
    console.log("I am about to be mounted to the DOM!");
  },
  mounted() {
    console.log("I am mounted to the DOM!");
  },
  beforeDestroy() {
    console.log("I am about to be removed from the DOM!");
  },
  destroyed() {
    console.log("I no longer exist...");
  },
  methods: {
    ...mapActions("XemTheoWinLoss", ["setDomainNm", "setReportTable"]),
    errorApi(error) {
      console.log(error);
      let message = "";
      let code = "";
      if (error.response) {
        message = error.response.data.message;
        code = error.response.data.code;
      } else {
        message = error.message;
      }
      this.$alert(message, `Lỗi ${code}`, {
        confirmButtonText: "OK",
        confirmButtonClass: "btn-success",
        type: "error",
      });
    },
    getReport() {
      let url = `${this.$root.url}/betscan/`;
      switch (this.level) {
        case 1:
          switch (this.report) {
            case "0":
              url += "getReportByProduct";
              break;
            case "1":
              url += "getReportByChoice";
              break;
          }
          break;
        case 2:
          switch (this.report) {
            case "0":
              url += "getReportByProductMember";
              break;
            case "1":
              url += "getReportByChoiceMember";
              break;
          }
          break;
      }
      const dateFrom = this.$moment(this.dateFromTo[0]).format(
        this.$root.formatDateAPI
      );
      const dateTo = this.$moment(this.dateFromTo[1]).format(
        this.$root.formatDateAPI
      );
      let param = {
        page: this.pageTable,
        pageSize: this.pageSizeTable,
        dateFrom: dateFrom,
        dateTo: dateTo,
        typeDomain: this.domain,
        superId: this.superId,
        memberId: this.memberId,
        normalSport: this.nSportChecked,
        virtualSport: this.vSportChecked,
      };
      let _this = this;
      ApiService.get(url, param)
        .then((response) => {
          _this.$root.fullScreenLoading = false;
          _this.$emit("update:isClickSearch", false);

          if (response && response.data) {
            _this.dataTable = response.data.list;
            _this.totalTable = response.data.total;

            let domain = _this.domainOptions.filter(
              (item) => item.codeKey === _this.domain
            )[0];
            _this.setDomainNm(domain ? domain.codeName : "");
            _this.setReportTable(this.report);
          }
          _this.$nextTick(() => {
            if (_this.$refs.table) {
              _this.$refs.table.doLayout();
            }
          });
        })
        .catch((error) => {
          _this.$root.fullScreenLoading = false;
          _this.$emit("update:isClickSearch", false);

          _this.errorApi(error);
        });
    },
    moveToThongKeTheoMember(row, newTab) {
      switch (this.level) {
        case 1:
          localStorage.setItem(
            "PARAM_FOR_LEVEL_2",
            JSON.stringify({
              superId: row.userName,
              inputSuperId: this.superId,
              domain: row.domainType,
              domainName: row.domainName,
              report: this.report,
              dateFromTo: this.dateFromTo,
              normalSport: this.normalSport,
              virtualSport: this.virtualSport,
            })
          );
          if (newTab) {
            let routeData = this.$router.resolve("thong-ke-theo-member");
            window.open(routeData.href, "_blank");
          } else {
            this.$router.push("thong-ke-theo-member");
          }
          break;
        case 2:
          localStorage.setItem(
            "PARAM_FOR_LEVEL_3",
            JSON.stringify({
              superId: this.superId,
              memberId: row.userName,
              domain: row.domainType,
              domainName: row.domainName,
              report: this.report,
              dateFromTo: this.dateFromTo,
              normalSport: this.normalSport,
              virtualSport: this.virtualSport,
              milisFrom: this.milisFrom,
              milisTo: this.milisTo,
              isFromScreenXemNhanh: false,
              isSearchInit: this.isSearchInit,
              trungIp: this.trungIp,
              buonCom: this.buonCom,
              cuocTheo: this.cuocTheo,
              trungGiaiCam: this.trungGiaiCam,
              giaiCam: this.giaiCam,
              cuocXau: this.cuocXau,
            })
          );
          if (newTab) {
            let routeData = this.$router.resolve("report");
            window.open(routeData.href, "_blank");
          } else {
            this.$router.push("report");
          }
          break;
      }
    },
    onClickBet(start, end, row, checkBox) {
      this.milisFrom = start.toString();
      this.milisTo = end.toString();
      // setting
      this.isSearchInit = true;
      this.trungGiaiCam = false;
      this.giaiCam = false;
      this.cuocXau = false;
      this.buonCom = false;
      this.cuocTheo = false;
      this.trungIp = false;
      if (checkBox === 0) {
        this.trungIp = true;
      }
      if (checkBox === 1) {
        this.cuocTheo = true;
      }
      if (checkBox === 2) {
        this.buonCom = true;
      }
      this.moveToThongKeTheoMember(row, true);
    },
    sizeChangeTable(val) {
      this.pageSizeTable = val;
      this.pageTable = 1;
      this.getReport();
    },
    currentPageChangeTable(val) {
      this.pageTable = val;
      this.getReport();
    },
  },
};
</script>
<style lang="scss">
.report-by-choice {
  .el-table__body {
    tr {
      td {
        .cell-2-row {
          height: 50%;

          &:first-child {
            border-bottom: 1px solid #ebeef5;
          }

          .cell-label-1 {
            margin-right: 3px;
            color: #c0181c;
            font-size: 14px;
          }

          .cell-label-2 {
            margin-right: 3px;
            color: #59bed7;
            font-size: 14px;
          }

          .cell-label-3 {
            margin-right: 3px;
            //color: #606266;
            font-size: 14px;
          }

          .cell-value-1 {
            font-weight: bold;
            color: #c0181c;
            white-space: nowrap;
            font-size: 14px;
          }

          .cell-value-2 {
            font-weight: bold;
            color: #59bed7;
            white-space: nowrap;
            font-size: 14px;
          }

          .cell-value-3 {
            font-weight: bold;
            //color: #606266;
            white-space: nowrap;
            font-size: 14px;
          }
        }

        .cell-label-total {
          margin-right: 3px;
          font-size: 14px;
        }

        .cell-label-dong {
          margin-right: 3px;
          color: #3e966c;
          font-size: 14px;
        }

        .cell-value-total {
          font-weight: bold;
          color: red;
          white-space: normal;
          word-break: break-all;
          font-size: 14px;
        }

        .cell-value-dong {
          font-weight: bold;
          color: #3e966c;
          white-space: nowrap;
          font-size: 14px;
        }
      }
    }
  }

  .click-cell {
    cursor: pointer;
  }
}
</style>
