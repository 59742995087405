<template>
  <div id="bet-top-member" class="bet-top-member">
    <Header :title="title" />

    <!-- Điều kiện tìm kiếm -->
    <el-row :gutter="20" class="conditions-search">
      <el-col :span="6" :offset="1">
        <label class="title-select">Chọn kiểu lọc</label>
        <el-select
          v-model="mode"
          value-key="codeKey"
          placeholder="Chọn"
          @change="onModeChanged()"
        >
          <el-option
            v-for="item in modeOptions"
            :key="item.codeKey"
            :label="item.codeName"
            :value="item.codeKey"
            no-data-text="Không có dữ liệu"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <div class="block">
          <el-date-picker
            v-model="dateFromTo"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="Tới"
            start-placeholder="Data Start"
            end-placeholder="Date End"
            :format="
              $root.formatDate.replace('YYYY', 'yyyy').replace('DD', 'dd')
            "
            :default-value="[
              $moment().subtract(1, 'months').format('YYYY/MM/DD'),
              $moment().format('YYYY/MM/DD'),
            ]"
            :picker-options="setPickerOptions()"
            @change="changeDateFromTo()"
          >
          </el-date-picker>
        </div>
      </el-col>
      <el-col :span="6" :offset="0">
        <el-button type="success" @click="search()">Tìm kiếm</el-button>
      </el-col>
    </el-row>

    <!-- Tên mode -->
    <el-row :gutter="20" class="name-mdoe">
      <el-col :span="24" :offset="0">
        <label>{{ modeNm }}</label>
      </el-col>
    </el-row>

    <!-- Table -->
    <el-row :gutter="20">
      <!-- Table 1-->
      <el-col :offset="0">
        <u-table
          ref="table1"
          :data="dataTable1"
          :height="700"
          use-virtual
          style="width: 100%"
          header-row-class-name="header-row-class-name"
          border
          stripe
          highlight-current-row
          size="small"
          empty-text="Không có dữ liệu"
        >
          <u-table-column
            prop="memberId"
            label="Member ID"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.memberId }}</span>
            </template>
          </u-table-column>

          <u-table-column
            prop="domainName"
            label="Nhà cái"
            header-align="center"
            sortable
          >
            <template slot-scope="scope">
              <span>{{ scope.row.domainName }}</span>
            </template>
          </u-table-column>

          <u-table-column
            prop="betCount"
            label="Tổng mã cược"
            header-align="center"
            align="right"
            sortable
          >
            <template slot-scope="scope">
              <span>{{ scope.row.betCount }}</span>
            </template>
          </u-table-column>

          <u-table-column
            prop="turnOver"
            label="Tổng điểm cược"
            header-align="center"
            align="right"
            sortable
          >
            <template slot-scope="scope">
              <span>{{ scope.row.turnOver }}</span>
            </template>
          </u-table-column>

          <u-table-column
            prop="totalWinLoss"
            label="WIN LOSS"
            header-align="center"
            align="center"
            sortable
          >
            <template slot-scope="scope">
              <span
                :class="`${
                  scope.row.totalWinLoss > 0 ? 'cell-value-1' : 'cell-value-2'
                }`"
                >{{ scope.row.totalWinLoss }}</span
              >
            </template>
          </u-table-column>
        </u-table>
        <el-pagination
          style="text-align: center; margin-top: 10px"
          layout="total, sizes, prev, pager, next, jumper"
          @sizeChangeTable="sizeChangeTable1"
          @current-change="currentPageChangeTable1"
          :pageTable="pageTable1"
          :page-sizes="pageSizesTable1"
          :page-size.sync="pageSizeTable1"
          :total="totalTable1"
        >
        </el-pagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Header from "../Common/Header.vue";
import ApiService from "../../service/api.service";

export default {
  name: "BetTopMember",
  components: {
    Header,
  },
  data() {
    return {
      title: "Top member thắng",
      mode: "DESC",
      modeNm: "Top thắng",
      modeOptions: [
        {
          codeKey: "DESC",
          codeName: "Top member thắng",
        },
        {
          codeKey: "ASC",
          codeName: "Top member thua",
        },
      ],
      dateFromTo: [
        this.$moment().startOf("weeks").format(this.$root.formatDate),
        this.$moment().format(this.$root.formatDate),
      ],
      dataTable1: [],
      pageTable1: 1,
      pageSizesTable1: [500, 1000, 2000, 5000],
      pageSizeTable1: 1000,
      totalTable1: 0,
    };
  },
  computed: {
    ...mapState("BetTopMember", []),
  },
  beforeCreate() {
    console.log("I have not been created yet!");
  },
  created() {
    console.log("I have just been created!");
  },
  beforeMount() {
    console.log("I am about to be mounted to the DOM!");
  },
  mounted() {
    this.getTopMember();
  },
  beforeDestroy() {
    console.log("I am about to be removed from the DOM!");
  },
  destroyed() {
    console.log("I no longer exist...");
  },
  methods: {
    ...mapActions("HistoryBatchJob", []),
    errorApi(error) {
      console.log(error);
      let message = "";
      let code = "";
      if (error.response) {
        message = error.response.data.message;
        code = error.response.data.code;
      } else {
        message = error.message;
      }
      this.$alert(message, `Lỗi ${code}`, {
        confirmButtonText: "OK",
        confirmButtonClass: "btn-success",
        type: "error",
      });
    },
    getTopMember() {
      this.$root.fullScreenLoading = true;
      let url = `${this.$root.url}/betscan/getTopMember`;
      let _this = this;
      const dateFrom = this.$moment(this.dateFromTo[0]).format(
        this.$root.formatDateAPI
      );
      const dateTo = this.$moment(this.dateFromTo[1]).format(
        this.$root.formatDateAPI
      );
      ApiService.get(url, {
        dateFrom: dateFrom,
        dateTo: dateTo,
        orderBy: _this.mode,
        page: _this.pageTable1,
        limit: _this.pageSizeTable1,
      })
        .then((response) => {
          this.$root.fullScreenLoading = false;
          if (response.data && response.data.total > 0) {
            _this.dataTable1 = response.data.data;
            _this.totalTable1 = response.data.total;
          }
        })
        .catch((error) => {
          this.$root.fullScreenLoading = false;
          _this.errorApi(error);
        });
    },
    setPickerOptions() {
      let _this = this;
      let pickerOptions = {
        disabledDate(time) {
          let twoMonthsAgo = _this.$moment().subtract(2, "months");
          let currentMonth = _this.$moment();
          let timeFormat = _this.$moment(time);
          let returnValue = !(
            _this.$moment(timeFormat).isAfter(twoMonthsAgo) &&
            _this.$moment(timeFormat).isBefore(currentMonth)
          );
          return returnValue;
        },
        shortcuts: [
          {
            text: "Hôm nay",
            onClick(picker) {
              const end = _this.$moment().format(_this.$root.formatDate);
              const start = _this.$moment().format(_this.$root.formatDate);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Hôm qua",
            onClick(picker) {
              const end = _this
                .$moment()
                .subtract(1, "days")
                .format(_this.$root.formatDate);
              const start = _this
                .$moment()
                .subtract(1, "days")
                .format(_this.$root.formatDate);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Tuần này",
            onClick(picker) {
              const end = _this.$moment().format(_this.$root.formatDate);
              const start = _this
                .$moment()
                .startOf("weeks")
                .format(_this.$root.formatDate);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Tuần trước",
            onClick(picker) {
              const end = _this
                .$moment()
                .subtract(1, "weeks")
                .endOf("weeks")
                .format(_this.$root.formatDate);
              const start = _this
                .$moment()
                .subtract(1, "weeks")
                .startOf("weeks")
                .format(_this.$root.formatDate);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Tháng này",
            onClick(picker) {
              const end = _this.$moment().format(_this.$root.formatDate);
              const start = _this
                .$moment()
                .startOf("months")
                .format(_this.$root.formatDate);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Tháng trước",
            onClick(picker) {
              const end = _this
                .$moment()
                .subtract(1, "months")
                .endOf("months")
                .format(_this.$root.formatDate);
              const start = _this
                .$moment()
                .subtract(1, "months")
                .startOf("months")
                .format(_this.$root.formatDate);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      };
      return pickerOptions;
    },
    search() {
      this.pageTable1 = 1;
      this.getTopMember();
    },
    onModeChanged() {
      if (this.mode === "DESC") {
        this.modeNm = "Top thắng";
      } else {
        this.modeNm = "Top thua";
      }
      this.getTopMember();
    },
    goBack() {
      this.$router.push("menu");
    },
    sizeChangeTable1(val) {
      this.pageSizeTable1 = val;
      this.pageTable1 = 1;
    },
    currentPageChangeTable1(val) {
      this.pageTable1 = val;
      this.getTopMember();
    },
  },
};
</script>

<style lang="scss">
.bet-top-member {
  .title-select {
    margin-right: 1vw;
  }

  .conditions-search {
    margin-bottom: 1vh;
  }

  .name-mdoe {
    height: 5.6vh;
    background-color: #0e9cc0;
    color: white;
    font-size: 18px;

    [class*="el-col-"] {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  .header-row-class-name {
    th {
      background-color: #f56c6c;
      color: black;
    }
  }

  .el-table {
    .cell {
      word-break: break-word;
    }
  }

  .el-table__body {
    tr {
      &.current-row {
        td {
          background-color: #96dcee !important;
        }
      }
      &:hover > td {
        background-color: #96dcee !important;
      }
      td {
        .cell-value-1 {
          font-weight: bold;
          color: #21bb19;
          white-space: nowrap;
          font-size: 14px;
        }

        .cell-value-2 {
          font-weight: bold;
          color: #ad1e19;
          white-space: nowrap;
          font-size: 14px;
        }
      }
    }
  }

  .pagination {
    text-align: right;
    margin-top: 1vh;

    .el-pagination button,
    .el-pagination span:not([class*="suffix"]) {
      font-size: 14px;
    }
  }
}

.btn-success {
  color: #fff !important;
  background-color: #0e9cc0 !important;
  border-color: #0e9cc0 !important;

  &:hover {
    color: #fff !important;
    background: #85ce61 !important;
    border-color: #85ce61 !important;
  }
}

.btn-success-plain {
  color: #0e9cc0 !important;
  background: #f0f9eb !important;
  border-color: #c2e7b0 !important;

  &:hover {
    color: #fff !important;
    background: #85ce61 !important;
    border-color: #85ce61 !important;
  }
}
</style>
