<template>
  <el-dialog
    width="80vw"
    top="10vh"
    :title="`${type === 'add' ? 'Thêm' : 'Chỉnh sửa'} Player`"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    :lock-scroll="false"
    class="add-or-edit-player"
  >
    <!-- body -->

    <el-row justify="center" align="center" class="group-input">
      <el-col :span="12" :offset="0">
        <el-col :span="4" :offset="1">
          <span class="required-icon" v-if="type === 'add'">*</span>
          <span>Domain</span>
        </el-col>
        <el-col :span="18" :offset="0">
          <el-select
            v-model="data.typeDomain"
            value-key="codeKey"
            size="small"
            placeholder="Chọn Domain"
          >
            <el-option
              v-for="item in domainOptions"
              :key="item.codeKey"
              :label="item.codeName"
              :value="item.codeKey"
              no-data-text="Không có dữ liệu"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-col>

      <el-col :span="12" :offset="0">
        <el-col :span="4" :offset="1">
          <span class="required-icon" v-if="type === 'add'">*</span>
          <span>Đại lý (super)</span>
        </el-col>
        <el-col :span="18" :offset="0">
          <el-input v-model="data.superId" size="small" clearable></el-input>
        </el-col>
      </el-col>
    </el-row>

    <el-row justify="center" align="center" class="group-input">
      <el-col :span="12" :offset="0">
        <el-col :span="4" :offset="1">
          <span class="required-icon" v-if="type === 'add'">*</span>
          <span>MemberId</span>
        </el-col>
        <el-col :span="18" :offset="0">
          <el-input
            v-model="data.memberId"
            size="small"
            clearable
            placeholder="MemberId"
          ></el-input>
        </el-col>
      </el-col>
      <el-col :span="12" :offset="0">
        <el-col :span="4" :offset="1">
          <span>Note</span>
        </el-col>
        <el-col :span="18" :offset="0">
          <el-input
            v-model="data.note"
            size="small"
            clearable
            placeholder="note"
          ></el-input>
        </el-col>
      </el-col>
    </el-row>

    <el-row justify="center" align="center" class="group-input">
      <el-col :span="12" :offset="0">
        <el-col :span="4" :offset="1">
          <span class="required-icon" v-if="type === 'add'">*</span>
          <span>Loại Gian Lận</span>
        </el-col>
        <el-col :span="18" :offset="0">
          <el-select
            v-model="data.cheatMode"
            value-key="codeKey"
            size="small"
            placeholder="Chọn Loại Gian Lận"
          >
            <el-option
              v-for="item in gLOptions"
              :key="item.codeKey"
              :label="item.codeName"
              :value="item.codeKey"
              no-data-text="Không có dữ liệu"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-col>
      <el-col :span="12" :offset="0">
        <el-col :span="4" :offset="1">
          <span>Ngày bắt</span>
        </el-col>
        <el-col :span="18" :offset="0">
          <el-date-picker
            v-model="data.inputDate"
            type="date"
            size="small"
            placeholder="Chọn ngày"
            format="yyyy/MM/dd"
          >
          </el-date-picker>
        </el-col>
      </el-col>
    </el-row>

    <!-- footer -->
    <el-row slot="footer" class="dialog-footer">
      <el-col :span="24">
        <el-button type="success" plain @click="cancel()">Hủy</el-button>
        <el-button type="success" @click="confirm()">{{
          type === "add" ? "Thêm" : "Lưu"
        }}</el-button>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "AddOrEditPlayer",
  props: ["dialogVisible", "dataRow", "mode", "type"],
  data() {
    return {
      data: {},
      modeOptions: [
        {
          codeKey: "",
          codeName: "",
        },
      ],
      gLOptions: [
        {
          codeClass: "00",
          codeKey: 0,
          codeName: "Chọn Loại Gian Lận",
          codeValue: "00",
        },
        { codeClass: "01", codeKey: 1, codeName: "Buôn Com", codeValue: "01" },
        { codeClass: "02", codeKey: 2, codeName: "Cược Theo", codeValue: "02" },
        { codeClass: "03", codeKey: 3, codeName: "Sập giá", codeValue: "03" },
        {
          codeClass: "04",
          codeKey: 4,
          codeName: "Cược Rủi Ro",
          codeValue: "04",
        },
        {
          codeClass: "05",
          codeKey: 5,
          codeName: "Cách chơi đặc biệt",
          codeValue: "05",
        },
        { codeClass: "06", codeKey: 6, codeName: "Buôn Bóng", codeValue: "06" },
        { codeClass: "07", codeKey: 7, codeName: "Khác", codeValue: "07" },
      ],
      isShowDate: true,
      modeGL: 0,
    };
  },
  computed: {
    ...mapState("Player", ["domainOptions"]),
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        return;
      }
      if (this.dataRow) {
        this.data = this.dataRow;
        this.data.crDateFrom = this.dataRow.crDateFrom
          ? this.dataRow.crDateFrom
          : this.$moment();
        this.data.crDateTo = this.dataRow.crDateTo
          ? this.dataRow.crDateTo
          : this.$moment();
      } else {
        this.data = {
          crDateFrom: this.$moment(),
          crDateTo: this.$moment(),
          crDatetimeRun: "",
          delivered: "",
          downLine: "",
          loginId: "",
          mode: this.mode.mode,
          modeNm: this.mode.modeNm,
          note: "",
          passCode: "",
          passWord: "",
          status: "",
          supplier: "",
          typeDomain: "",
          typeDomainNm: "",
          typeManager: "",
          typeManagerNm: "",
          updDate: "",
          userName: "",
        };
      }
    },
    mode(val) {
      this.isShowDate = val.mode === "4" || val.mode === "5";
    },
  },
  beforeCreate() {
    console.log("I have not been created yet!");
  },
  created() {
    console.log("I have just been created!");
  },
  beforeMount() {
    console.log("I am about to be mounted to the DOM!");
  },
  mounted() {
    console.log("I am mounted to the DOM!");
  },
  beforeDestroy() {
    console.log("I am about to be removed from the DOM!");
  },
  destroyed() {
    console.log("I no longer exist...");
  },
  methods: {
    handleClose() {
      this.$confirm(
        `Bạn có chắc muốn hủy ${
          this.type === "add" ? "thêm" : "chỉnh sửa"
        } Note?`,
        `Xác nhận`,
        {
          confirmButtonText: "Đồng Ý",
          cancelButtonText: "Hủy",
          confirmButtonClass: "btn-success",
          cancelButtonClass: "btn-success-plain",
        }
      )
        .then((response) => {
          console.log(response);
          this.close();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    close() {
      this.data = {};
      this.$emit("closeDialog");
    },
    cancel() {
      this.handleClose();
    },
    confirm() {
      let isValid =
        this.checkInput("text", this.data.typeDomain, "Domain") &&
        this.checkInput("text", this.data.superId, "Đại lý") &&
        this.checkInput("text", this.data.memberId, "Member Id") &&
        this.checkInput("text", this.data.note, "note") &&
        this.checkInput("text", this.data.cheatMode, "Loại Gian Lận") &&
        this.checkInput("date", this.data.inputDate);
      if (isValid) {
        if (this.type === "add") {
          this.$emit("addPlayer", this.data);
        } else {
          this.$emit("editPlayer", this.data);
        }
        this.close();
      }
    },
    checkInput(type, input, text) {
      if (type === "text") {
        if (input === "") {
          this.$alert(`Bạn phải chọn ${text}`, `Lỗi`, {
            confirmButtonText: "OK",
            confirmButtonClass: "btn-success",
            type: "error",
          });
          return false;
        }
      } else if (type === "date") {
        if (this.$moment(input.crDateFrom).isAfter(input.crDateTo)) {
          this.$alert(`Bạn phải chọn Date Start trước Date End`, `Lỗi`, {
            confirmButtonText: "OK",
            confirmButtonClass: "btn-success",
            type: "error",
          });
          return false;
        }
      }
      return true;
    },
  },
};
</script>

<style lang="scss">
.add-or-edit-player {
  .el-dialog__body {
    padding-top: 20px;
    height: 50vh;
    overflow: auto;
  }

  .el-select {
    width: 100%;
  }

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100% !important;
  }

  .group-input {
    height: 32px;
    margin-bottom: 10px;

    > div {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  [class*="el-col-"] {
    position: relative;

    .required-icon {
      color: red;
      position: absolute;
      top: 0;
      left: -10px;
    }
  }
}

.btn-success {
  color: #fff !important;
  background-color: #0e9cc0 !important;
  border-color: #0e9cc0 !important;

  &:hover {
    color: #fff !important;
    background: #85ce61 !important;
    border-color: #85ce61 !important;
  }
}

.btn-success-plain {
  color: #0e9cc0 !important;
  background: #f0f9eb !important;
  border-color: #c2e7b0 !important;

  &:hover {
    color: #fff !important;
    background: #85ce61 !important;
    border-color: #85ce61 !important;
  }
}
</style>
