<template>
  <div id="xem-nhanh" class="xem-theo-win-loss" v-on:keyup.enter="search()">
    <Header :title="title" />

    <!-- Điều kiện tìm kiếm -->
    <el-row :gutter="20" class="conditions-search">
      <el-col :span="18" :offset="1">
        <el-checkbox v-model="trungIp"> Trùng IP </el-checkbox>
        <el-checkbox v-model="cuocTheo"> Cược Theo </el-checkbox>
        <el-checkbox v-model="buonCom"> Buôn Com </el-checkbox>
        <el-checkbox v-model="hangsapgia"> Hàng sập giá </el-checkbox>
        <el-checkbox v-model="cuocRuiRo"> Cược Rủi Ro </el-checkbox>
        <el-checkbox v-model="cuocDacBiet"> Cách chơi đặc biệt </el-checkbox>
        <el-checkbox v-model="buonBong"> Buôn Bóng </el-checkbox>
      </el-col>
      <el-col :span="6" :offset="0">
        <el-button type="success" @click="$router.push('xem-nhanh-note')"
          >Xem ghi chú</el-button
        >
      </el-col>
    </el-row>

    <!-- Điều kiện tìm kiếm -->
    <el-row :gutter="20" class="conditions-search">
      <el-col :span="3" :offset="1">
        <el-select
          v-model="domain"
          value-key="codeKey"
          placeholder="Chọn Domain"
        >
          <el-option
            v-for="item in domainOptions"
            :key="item.codeKey"
            :label="item.codeName"
            :value="item.codeKey"
            no-data-text="Không có dữ liệu"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <div class="block">
          <el-date-picker
            v-model="dateFromTo"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="Tới"
            start-placeholder="Data Start"
            end-placeholder="Date End"
            :format="
              $root.formatDate.replace('YYYY', 'yyyy').replace('DD', 'dd')
            "
            :default-value="[
              $moment().subtract(1, 'months').format('YYYY/MM/DD'),
              $moment().format('YYYY/MM/DD'),
            ]"
            :picker-options="setPickerOptions()"
            @change="changeDateFromTo()"
          >
          </el-date-picker>
        </div>
      </el-col>
      <el-col :span="6" :offset="0">
        <el-button
          type="success"
          @click="search()"
          :disabled="isDisabledBtnSearch()"
          >Tìm kiếm</el-button
        >
      </el-col>
    </el-row>

    <!-- Text mô tả -->
    <el-row :gutter="20" class="name-mdoe">
      <el-col :span="12" :offset="0">
        <label>TÀI KHOẢN MEMBER TRONG HỆ THỐNG</label>
      </el-col>
      <el-col :span="12" :offset="0">
        <label> Kết quả được tính tới thời điểm: {{ timeUpdate }} </label>
      </el-col>
    </el-row>

    <!-- Table -->
    <el-row :gutter="10" v-show="buonCom">
      <XemNhanhBuonCom
        :dataBuonCom="dataBuonCom"
        :dateFromTo="dateFromTo"
        @clickFromChildComponent="handleClickInParent"
      >
      </XemNhanhBuonCom>
    </el-row>

    <el-row :gutter="10" v-show="cuocTheo">
      <XemNhanhCuocTheo
        :dataCuocTheo="dataCuocTheo"
        :dateFromTo="dateFromTo"
        @clickFromChildComponent="handleClickInParent"
      >
      </XemNhanhCuocTheo>
    </el-row>
    <el-row :gutter="10" v-show="trungIp">
      <XemNhanhTrungIp
        :dataTrungIp="dataTrungIp"
        :dateFromTo="dateFromTo"
        @clickFromChildComponent="handleClickInParent"
      >
      </XemNhanhTrungIp>
    </el-row>
    <el-row :gutter="10" v-show="hangsapgia">
      <XemNhanhSapGia
        :dataSapGia="dataSapGia"
        :dateFromTo="dateFromTo"
        @clickFromChildComponent="handleClickInParent"
      >
      </XemNhanhSapGia>
    </el-row>
    <el-row :gutter="10" v-show="cuocRuiRo">
      <XemNhanhRuiRo
        :dataRuiRo="dataRuiRo"
        :dateFromTo="dateFromTo"
        @clickFromChildComponent="handleClickInParent"
      >
      </XemNhanhRuiRo>
    </el-row>
    <el-row :gutter="10" v-show="cuocDacBiet">
      <XemNhanhDacBiet
        :dataDacBiet="dataDacBiet"
        :dateFromTo="dateFromTo"
        @clickFromChildComponent="handleClickInParent"
      >
      </XemNhanhDacBiet>
    </el-row>
    <el-row :gutter="10" v-show="buonBong">
      <XemNhanhBuonBong
        :dataBuonBong="dataBuonBong"
        :dateFromTo="dateFromTo"
        @clickFromChildComponent="handleClickInParent"
      >
      </XemNhanhBuonBong>
    </el-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Header from "../Common/Header.vue";
import XemNhanhBuonCom from "./componetsXemnhanh/XemNhanhBuonCom.vue";
import XemNhanhCuocTheo from "./componetsXemnhanh/XemNhanhCuocTheo.vue";
import XemNhanhTrungIp from "./componetsXemnhanh/XemNhanhTrungIp.vue";
import XemNhanhDacBiet from "./componetsXemnhanh/XemNhanhDacBiet.vue";
import XemNhanhRuiRo from "./componetsXemnhanh/XemNhanhRuiRo.vue";
import XemNhanhSapGia from "./componetsXemnhanh/XemNhanhSapGia.vue";
import XemNhanhBuonBong from "./componetsXemnhanh/XemNhanhBuonBong.vue";
import ApiService from "../../service/api.service";

export default {
  name: "XemNhanh",
  components: {
    XemNhanhBuonCom,
    XemNhanhCuocTheo,
    XemNhanhTrungIp,
    XemNhanhDacBiet,
    XemNhanhRuiRo,
    XemNhanhSapGia,
    XemNhanhBuonBong,
    Header,
  },
  data() {
    return {
      title: "Xem nhanh",
      urlMain: `${this.$root.url}/betscanv2`,
      modeSystem: 0,
      domain: "00",
      domainOptions: [
        {
          codeKey: "00",
          codeName: "Tất cả",
        },
      ],
      report: "0",
      dateFromTo: [
        this.$moment().startOf("weeks").format(this.$root.formatDate),
        this.$moment().format(this.$root.formatDate),
      ],
      dataBuonCom: [],
      dataTrungIp: [],
      dataCuocTheo: [],
      dataSapGia: [],
      dataRuiRo: [],
      dataDacBiet: [],
      dataBuonBong: [],
      buonBong: false,
      thayDoiGio: false,
      trungIp: false,
      cuocTheo: false,
      buonCom: true,
      cuocRuiRo: false,
      cuocDacBiet: false,
      hangsapgia: false,
      isLoadingBuonCom: false,
      isLoadingCuocTheo: false,
      isLoadingSapGia: false,
      dataCuocTheoCopy: [],
      dataBuonComCopy: [],
      dataRuiRoCopy: [],
      dataSapGiaCopy: [],
      dataBuonBongCopy: [],
      dataDacBietCopy: [],
    };
  },
  computed: {
    ...mapState("XemTheoWinLoss", ["domainName", "reportTable", "timeUpdate"]),
  },
  beforeCreate() {
    console.log("I have not been created yet!");
  },
  created() {
    console.log("I have just been created!");
  },
  beforeMount() {
    console.log("I am about to be mounted to the DOM!");
  },
  mounted() {
    console.log("I am mounted to the DOM!");
    this.getTimeUpdate();
    this.getDomainOptions();
  },
  beforeDestroy() {
    console.log("I am about to be removed from the DOM!");
  },
  destroyed() {
    console.log("I no longer exist...");
  },
  methods: {
    ...mapActions("XemTheoWinLoss", ["setDomainOptions", "setTimeUpdate"]),
    errorApi(error) {
      console.log(error);
      let message = "";
      let code = "";
      if (error.response) {
        message = error.response.data.message;
        code = error.response.data.code;
      } else {
        message = error.message;
      }
      this.$alert(message, `Lỗi ${code}`, {
        confirmButtonText: "OK",
        confirmButtonClass: "btn-success",
        type: "error",
      });
    },
    getTimeUpdate() {
      let url = `${this.$root.url}/betscan/getTimeUpdate`;
      let _this = this;
      let params = {};
      ApiService.get(url, params)
        .then((response) => {
          const timeUpdate = _this
            .$moment(response.data)
            .format(this.$root.formatDateTime);
          _this.setTimeUpdate(timeUpdate);
        })
        .catch((error) => {
          _this.errorApi(error);
        });
    },
    getDomainOptions() {
      let url = `${this.$root.url}/master/getListMasterCodeByClass`;
      let _this = this;
      ApiService.get(url, { codeClass: "00002" })
        .then((response) => {
          response.data.forEach((item) => {
            _this.domainOptions.push({
              codeKey: item.codeKey,
              codeName: item.codeName,
            });
          });
          _this.setDomainOptions(_this.domainOptions);
        })
        .catch((error) => {
          _this.errorApi(error);
        });
    },
    setPickerOptions() {
      let _this = this;
      let pickerOptions = {
        disabledDate(time) {
          let twoMonthsAgo = _this.$moment().subtract(2, "months");
          let currentMonth = _this.$moment();
          let timeFormat = _this.$moment(time);
          let returnValue = !(
            _this.$moment(timeFormat).isAfter(twoMonthsAgo) &&
            _this.$moment(timeFormat).isBefore(currentMonth)
          );
          return returnValue;
        },
        shortcuts: [
          {
            text: "Hôm nay",
            onClick(picker) {
              const end = _this.$moment().format(_this.$root.formatDate);
              const start = _this.$moment().format(_this.$root.formatDate);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Hôm qua",
            onClick(picker) {
              const end = _this
                .$moment()
                .subtract(1, "days")
                .format(_this.$root.formatDate);
              const start = _this
                .$moment()
                .subtract(1, "days")
                .format(_this.$root.formatDate);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Tuần này",
            onClick(picker) {
              const end = _this.$moment().format(_this.$root.formatDate);
              const start = _this
                .$moment()
                .startOf("weeks")
                .format(_this.$root.formatDate);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Tuần trước",
            onClick(picker) {
              const end = _this
                .$moment()
                .subtract(1, "weeks")
                .endOf("weeks")
                .format(_this.$root.formatDate);
              const start = _this
                .$moment()
                .subtract(1, "weeks")
                .startOf("weeks")
                .format(_this.$root.formatDate);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Tháng này",
            onClick(picker) {
              const end = _this.$moment().format(_this.$root.formatDate);
              const start = _this
                .$moment()
                .startOf("months")
                .format(_this.$root.formatDate);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Tháng trước",
            onClick(picker) {
              const end = _this
                .$moment()
                .subtract(1, "months")
                .endOf("months")
                .format(_this.$root.formatDate);
              const start = _this
                .$moment()
                .subtract(1, "months")
                .startOf("months")
                .format(_this.$root.formatDate);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      };
      return pickerOptions;
    },
    search() {
      this.dataBuonCom = [];
      this.dataCuocTheo = [];
      this.dataTrungIp = [];
      if (this.buonCom) {
        this.getDataBuonCom();
      }
      if (this.cuocTheo) {
        this.getDataCuocTheo();
      }
      if (this.trungIp) {
        this.getDataTrungIp();
      }
      if (this.hangsapgia) {
        this.getDataSapGia();
      }
      if (this.cuocRuiRo) {
        this.getDataCuocRuiRo();
      }
      if (this.cuocDacBiet) {
        this.getDataCuocDacBiet();
      }
      if (this.buonBong) {
        this.getDataBuonBong();
      }
    },
    getDataBuonCom() {
      this.$root.fullScreenLoading = true;
      this.isLoadingBuonCom = true;
      let url = `${this.urlMain}/listMemberRateDirtyCom`;
      let _this = this;
      let params = this.setParamAPI();
      ApiService.get(url, params)
        .then((response) => {
          if (response && response.data) {
            _this.dataBuonCom = response.data;
            _this.dataBuonComCopy = response.data;
          } else {
            _this.dataBuonCom = [];
          }
          this.isLoadingBuonCom = false;
          this.checkLoading();
        })
        .catch((error) => {
          _this.$root.fullScreenLoading = false;
          _this.errorApi(error);
        });
    },
    getDataSapGia() {
      this.$root.fullScreenLoading = true;
      this.isLoadingSapGia = true;
      let url = `${this.urlMain}/listMemberRateCrashPrice`;
      let _this = this;
      let params = this.setParamAPI();
      ApiService.get(url, params)
        .then((response) => {
          if (response && response.data) {
            _this.dataSapGia = response.data;
            _this.dataSapGiaCopy = response.data;
          } else {
            _this.dataSapGia = [];
          }
          this.isLoadingSapGia = false;
          this.checkLoading();
        })
        .catch((error) => {
          _this.$root.fullScreenLoading = false;
          _this.errorApi(error);
        });
    },
    getDataCuocRuiRo() {
      this.$root.fullScreenLoading = true;
      this.isLoadingSapGia = true;
      let url = `${this.urlMain}/listMemberBetRisk`;
      let _this = this;
      let params = this.setParamAPI();
      ApiService.get(url, params)
        .then((response) => {
          if (response && response.data) {
            _this.dataRuiRo = response.data;
            _this.dataRuiRoCopy = response.data;
          } else {
            _this.dataRuiRo = [];
          }
          this.isLoadingSapGia = false;
          this.checkLoading();
        })
        .catch((error) => {
          _this.$root.fullScreenLoading = false;
          _this.errorApi(error);
        });
    },
    getDataCuocDacBiet() {
      this.$root.fullScreenLoading = true;
      this.isLoadingSapGia = true;
      let url = `${this.urlMain}/listMemberBetSpecial`;
      let _this = this;
      let params = this.setParamAPI();

      ApiService.get(url, params)
        .then((response) => {
          if (response && response.data) {
            _this.dataDacBiet = response.data;
            _this.dataDacBietCopy = response.data;
          } else {
            _this.dataDacBiet = [];
          }
          this.isLoadingSapGia = false;
          this.checkLoading();
        })
        .catch((error) => {
          _this.$root.fullScreenLoading = false;
          _this.errorApi(error);
        });
    },
    getDataBuonBong() {
      this.$root.fullScreenLoading = true;
      this.isLoadingSapGia = true;
      let url = `${this.urlMain}/listMemberDirtySoccer`;
      let _this = this;
      let params = this.setParamAPI();

      ApiService.get(url, params)
        .then((response) => {
          if (response && response.data) {
            _this.dataBuonBong = response.data;
            _this.dataBuonBongCopy = response.data;
          } else {
            _this.dataBuonBong = [];
          }
          this.isLoadingSapGia = false;
          this.checkLoading();
        })
        .catch((error) => {
          _this.$root.fullScreenLoading = false;
          _this.errorApi(error);
        });
    },
    getDataCuocTheo() {
      this.$root.fullScreenLoading = true;
      this.isLoadingCuocTheo = true;
      let url = `${this.urlMain}/listMemberRateSameProduct`;
      let _this = this;
      let params = this.setParamAPI();
      ApiService.get(url, params)
        .then((response) => {
          if (response && response.data) {
            _this.dataCuocTheo = response.data;
            _this.dataCuocTheoCopy = response.data;
          } else {
            _this.dataCuocTheo = [];
          }
          this.isLoadingCuocTheo = false;
          this.checkLoading();
        })
        .catch((error) => {
          _this.$root.fullScreenLoading = false;
          _this.errorApi(error);
        });
    },
    getDataTrungIp() {
      this.$root.fullScreenLoading = true;
      let url = `${this.urlMain}/listMemberRateSameIp`;
      let _this = this;
      let params = this.setParamAPI();
      ApiService.get(url, params)
        .then((response) => {
          if (response && response.data) {
            _this.dataTrungIp = response.data;
          } else {
            _this.dataTrungIp = [];
          }
          _this.$root.fullScreenLoading = false;
        })
        .catch((error) => {
          _this.$root.fullScreenLoading = false;
          _this.errorApi(error);
        });
    },
    setParamAPI() {
      const dateFrom = this.$moment(this.dateFromTo[0]).format(
        this.$root.formatDateAPI
      );
      const dateTo = this.$moment(this.dateFromTo[1]).format(
        this.$root.formatDateAPI
      );
      let params = {
        dateFrom: dateFrom,
        dateTo: dateTo,
        typeDomain: this.domain,
      };
      return params;
    },
    goBack() {
      this.$router.push("menu");
    },
    isDisabledBtnSearch() {
      if (!this.dateFromTo || this.dateFromTo.length === 0) {
        return true;
      }
      return false;
    },
    changeDateFromTo() {
      console.log(this.dateFromTo);
    },
    checkLoading() {
      if (!this.isLoadingBuonCom && !this.isLoadingCuocTheo) {
        this.$root.fullScreenLoading = false;
      }
    },
    handleClickInParent(searchData) {
      if (this.cuocTheo) {
        this.dataCuocTheo = this.dataCuocTheoCopy.filter(
          (data) =>
            !searchData ||
            data.userName
              .toLowerCase()
              .includes(searchData.trim().toLowerCase())
        );
      }
      if (this.buonCom) {
        this.dataBuonCom = this.dataBuonComCopy.filter(
          (data) =>
            !searchData ||
            data.userName
              .toLowerCase()
              .includes(searchData.trim().toLowerCase())
        );
      }
      if (this.cuocRuiRo) {
        this.dataRuiRo = this.dataRuiRoCopy.filter(
          (data) =>
            !searchData ||
            data.userName
              .toLowerCase()
              .includes(searchData.trim().toLowerCase())
        );
      }
      if (this.cuocDacBiet) {
        this.dataDacBiet = this.dataDacBietCopy.filter(
          (data) =>
            !searchData ||
            data.userName
              .toLowerCase()
              .includes(searchData.trim().toLowerCase())
        );
      }
      if (this.hangsapgia) {
        this.dataSapGia = this.dataSapGiaCopy.filter(
          (data) =>
            !searchData ||
            data.userName
              .toLowerCase()
              .includes(searchData.trim().toLowerCase())
        );
      }
    },
  },
};
</script>

<style lang="scss">
.xem-nhanh {
  .title-select {
    margin-right: 1vw;
  }

  .conditions-search {
    margin-bottom: 1vh;

    &.el-row {
      display: flex;
    }

    .el-date-editor {
      width: 100%;
    }
  }

  .checkbox-report-choice {
    height: 100%;

    [class*="el-col-"] {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  .name-mdoe {
    height: 5.6vh;
    background-color: #0e9cc0;
    color: white;
    font-size: 18px;

    [class*="el-col-"] {
      display: flex;
      align-items: center;
      height: 100%;

      &:last-child {
        justify-content: flex-end;
      }
    }
  }

  .breadcrumb {
    height: 5.6vh;
    color: black;
    font-size: 18px;

    [class*="el-col-"] {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  .header-row-class-name {
    &:first-child {
      th {
        background-color: #d96767 !important;
        color: black !important;
        height: 3vh !important;
      }
    }

    th {
      background-color: #eac9b8 !important;
      color: black !important;
      height: 3vh !important;

      .no-sort {
        .el-icon-caret-top,
        .el-icon-caret-bottom {
          opacity: 0.2;
        }
      }

      .descending {
        .el-icon-caret-top {
          opacity: 0.2;
        }
      }

      .ascending {
        .el-icon-caret-bottom {
          opacity: 0.2;
        }
      }

      .header-sort {
        display: flex;
        justify-content: center;

        .icon-sort {
          padding-left: 3px;
          position: relative;

          .el-icon-caret-top {
            position: absolute;
            top: 0;
          }

          .el-icon-caret-bottom {
            position: absolute;
            bottom: 0;
          }
        }
      }
    }
  }

  .el-table {
    thead.is-group th {
      padding: 0px !important;
    }

    th .cell {
      //white-space: nowrap;
      white-space: pre-wrap;
      word-break: break-word;
    }

    td .cell {
      word-break: break-word;
    }
  }

  .el-table__body {
    tr {
      &.current-row {
        td {
          background-color: #96dcee !important;
        }
      }
      &:hover > td {
        background-color: #96dcee !important;
      }

      td {
        .cell-label-user-name {
          font-weight: bold;
          font-size: 14px;

          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }

        .cell-label-number-click {
          font-weight: bold;
          font-size: 14px;
          color: #0e9cc0;

          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }

  .pagination {
    text-align: right;
    margin-top: 1vh;

    .el-pagination button,
    .el-pagination span:not([class*="suffix"]) {
      font-size: 14px;
    }
  }
}

.btn-success {
  color: #fff !important;
  background-color: #0e9cc0 !important;
  border-color: #0e9cc0 !important;

  &:hover {
    color: #fff !important;
    background: #85ce61 !important;
    border-color: #85ce61 !important;
  }
}

.btn-success-plain {
  color: #0e9cc0 !important;
  background: #f0f9eb !important;
  border-color: #c2e7b0 !important;

  &:hover {
    color: #fff !important;
    background: #85ce61 !important;
    border-color: #85ce61 !important;
  }
}
.title-table {
  height: 40px;
  display: flex;
  align-items: center;
  margin-top: 10px;

  span {
    font-weight: bold;
  }
}
</style>
