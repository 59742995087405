import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
import Menu from "../components/Menu/Menu.vue";
import ListPlayer from "../components/Player/ListPlayer.vue";
import PlayerKhongChoi from "../components/Player/PlayerKhongChoi.vue";
import HistoryBatchJob from "../components/BatchJob/HistoryBatchJob.vue";
import MappingMatchLeague from "../components/BatchJob/MappingMatchLeague.vue";
import BetTopMember from "../components/ThongKeGianLan/BetTopMember.vue";
import XemTheoWinLoss from "../components/ThongKeGianLan/XemTheoWinLoss.vue";
import XemNhanh from "../components/ThongKeGianLan/XemNhanh.vue";
import XemNhanhNote from "../components/ThongKeGianLan/componetsXemnhanh/XemNhanhNote.vue";
import ThongKeTheoMember from "../components/ThongKeGianLan/ThongKeTheoMember.vue";
import Report from "../components/ThongKeGianLan/Report.vue";
import Login from "../components/Login/Login.vue";
import ApiService from "../service/api.service";
import TongDaiLy from "../components/User/TongDaiLy.vue";
import ListUser from "../components/User/ListUser.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    {
      path: "/",
      component: Login,
      meta: {
        guest: true,
      },
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        guest: true,
      },
    },
    {
      path: "/menu",
      name: "menu",
      component: Menu,
      meta: {
        requiresAuth: true,
        role: "ROLE_MEMBER",
      },
    },
    {
      path: "/quan-ly-player",
      component: ListPlayer,
      meta: {
        requiresAuth: true,
        role: "ROLE_MEMBER",
      },
    },
    {
      path: "/mapping-match-league",
      component: MappingMatchLeague,
      meta: {
        requiresAuth: true,
        role: "ROLE_MEMBER",
      },
    },
    {
      path: "/bet-top-member",
      component: BetTopMember,
      meta: {
        requiresAuth: true,
        role: "ROLE_MEMBER",
      },
    },
    {
      path: "/history-batch-job",
      component: HistoryBatchJob,
      meta: {
        requiresAuth: true,
        role: "ROLE_MEMBER",
      },
    },
    {
      path: "/xem-theo-win-loss",
      component: XemTheoWinLoss,
      meta: {
        requiresAuth: true,
        role: "ROLE_MEMBER",
      },
    },
    {
      path: "/xem-nhanh",
      name: "xemnhanh",
      component: XemNhanh,
      meta: {
        requiresAuth: true,
        role: "ROLE_MEMBER",
      },
    },
    {
      path: "/thong-ke-khong-choi",
      name: "PlayerKhongChoi",
      component: PlayerKhongChoi,
      meta: {
        requiresAuth: true,
        role: "ROLE_MEMBER",
      },
    },
    {
      path: "/xem-nhanh-note",
      name: "xemnhanhnote",
      component: XemNhanhNote,
      meta: {
        requiresAuth: true,
        role: "ROLE_MEMBER",
      },
    },
    {
      path: "/thong-ke-theo-member",
      component: ThongKeTheoMember,
      meta: {
        requiresAuth: true,
        role: "ROLE_MEMBER",
      },
    },
    {
      path: "/report",
      component: Report,
      meta: {
        requiresAuth: true,
        role: "ROLE_MEMBER",
      },
    },
    {
      path: "/manager-agent-player",
      component: TongDaiLy,
      meta: {
        requiresAuth: true,
        role: "ROLE_MEMBER",
      },
    },
    {
      path: "/list-user",
      component: ListUser,
      meta: {
        requiresAuth: true,
        role: "ROLE_ADMIN",
      },
    },
  ],
});
router.beforeEach((to, from, next) => {
  let jwt = localStorage.getItem("jwt");
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!jwt) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      if (!ApiService.checkTokenValidity(jwt)) {
        localStorage.clear();
        next({ name: "login" });
      } else {
        let user = JSON.parse(localStorage.getItem("user"));
        if (to.matched.some((record) => record.meta.role)) {
          if (user.roles.includes(to.meta.role)) {
            next();
          } else {
            next(false);
          }
        } else {
          next();
        }
      }
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (!jwt) {
      next();
    } else {
      if (!ApiService.checkTokenValidity(jwt)) {
        localStorage.clear();
        next({ name: "login" });
      } else {
        next({ name: "menu" });
      }
    }
  } else {
    next();
  }
});

axios.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    if (error.response.status) {
      let namePath = router.currentRoute.path;
      switch (error.response.status) {
        case 401:
          if (namePath && namePath !== "/" && namePath !== "/login") {
            alert("Hết thời gian đăng nhập!");
            setTimeout(() => {
              localStorage.removeItem("jwt");
              localStorage.removeItem("user");
              localStorage.removeItem("userName");
              router.push("/login");
            }, 2000);
          }
          break;
        case 403:
          router.push("/menu");
          break;
        // case 404:
        //   break;
      }
      return Promise.reject(error);
    }
  }
);

export default router;
