import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Element from "element-ui";
import locale from "element-ui/lib/locale/lang/vi";
import "element-ui/lib/theme-chalk/index.css";
import { UTable, UTableColumn } from "umy-ui";
import store from "./store";
import moment from "moment";
import VueMomentJS from "vue-momentjs";
import VueHtml2Canvas from "vue-html2canvas";
import Pagination from "@/components/Common/Pagination.vue";
import lightVueTree from "light-vue-tree";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import regeneratorRuntime from "regenerator-runtime";

Vue.config.productionTip = false;

moment.locale("vi");

Vue.use(Element, { locale });
Vue.use(VueMomentJS, moment);
Vue.use(VueHtml2Canvas);
Vue.use(regeneratorRuntime);

Vue.component("Pagination", Pagination);
Vue.component(UTable.name, UTable);
Vue.component(UTableColumn.name, UTableColumn);
Vue.component("VueTree", lightVueTree);
Vue.use(Toast, { timeout: 1000 });

new Vue({
  router,
  store,
  render: (h) => h(App),
  data: {
    url: "https://be.test.zf1.us",
    //url: "http://localhost:8086",
    fullScreenLoading: false,
    formatDate: "YYYY/MM/DD",
    formatDateTimeNoSecond: "YYYY/MM/DD HH:mm",
    formatDateTime: "YYYY/MM/DD HH:mm:ss",
    formatDateAPI: "YYYY-MM-DD",
    formatDateTimeNoSecondAPI: "YYYY-MM-DD HH:mm",
    formatDateTimeAPI: "YYYY-MM-DD HH:mm:ss",
    formatDateTimeFileName: "YYYY-MM-DD_HH-mm-ss",
  },
}).$mount("#app");
