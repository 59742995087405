<template>
  <div id="mapping-match-league" class="mapping-match-league">
    <Header :title="title" />

    <!-- Điều kiện tìm kiếm -->
    <el-row :gutter="12" class="conditions-search">
      <el-col :span="6" :offset="1">
        <label class="title-select">Chọn kiểu ghép</label>
        <el-select
          v-model="mode"
          value-key="codeKey"
          placeholder="Chọn kiểu ghép"
          @change="onModeChanged()"
        >
          <el-option
            v-for="item in modeOptions"
            :key="item.codeKey"
            :label="item.codeName"
            :value="item.codeKey"
            no-data-text="Không có dữ liệu"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <div class="block">
          <el-date-picker
            v-model="dateFromTo"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="Tới"
            start-placeholder="Data Start"
            end-placeholder="Date End"
            :format="
              $root.formatDate.replace('YYYY', 'yyyy').replace('DD', 'dd')
            "
            :default-value="[
              $moment().subtract(3, 'days').format('YYYY/MM/DD'),
              $moment().format('YYYY/MM/DD'),
            ]"
            :picker-options="setPickerOptions()"
          >
          </el-date-picker>
        </div>
      </el-col>
      <el-col :span="4">
        <el-checkbox v-model="virtualSport">Bóng Ảo</el-checkbox>
      </el-col>
    </el-row>

    <!-- Tên mode -->
    <el-row :gutter="20" class="name-mdoe">
      <el-col :span="24" :offset="0">
        <label>{{ modeNm }}</label>
      </el-col>
    </el-row>

    <!-- Table match -->
    <el-row :gutter="20" v-if="mode === '0'">
      <!-- Table 1-->
      <el-col :span="14" :offset="0">
        <el-row :gutter="20" class="conditions-search2">
          <!-- action in table 1-->
          <el-col :span="6" :offset="0">
            <el-button type="success" @click="searchSameMatch()"
              >Tìm kiếm trận giống nhau</el-button
            >
          </el-col>
          <el-col :span="6" :offset="0">
            <el-input
              v-model="txtSearchLeftSameMatch"
              placeholder="Nhập tên bên trái trận đấu"
              clearable
            ></el-input>
          </el-col>
          <el-col :span="6" :offset="0">
            <el-button type="warning" @click="mapSameMatch()"
              >Ghép Trận</el-button
            >
          </el-col>
        </el-row>
        <u-table
          ref="table1"
          :data="computeDataTable1"
          height="65vh"
          header-row-class-name="header-row-class-name"
          border
          stripe
          highlight-current-row
          size="small"
          empty-text="Không có dữ liệu"
        >
          <u-table-column
            prop="rootMatch"
            label="Trận đấu chuẩn(viva) "
            header-align="center"
            align="right"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.root.match }}</span>
            </template>
          </u-table-column>

          <u-table-column
            prop="diffMatch"
            label="Trận cần ghép"
            header-align="center"
            sortable
            align="right"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.diff.match }}</span>
            </template>
          </u-table-column>

          <u-table-column
            prop="status"
            label="Xác Nhận"
            header-align="center"
            sortable
            align="center"
          >
            <template slot-scope="scope">
              <el-checkbox
                title="Giống nhau"
                v-model="scope.row.status"
                @change="changeStatusMatch(scope.row)"
              ></el-checkbox>
            </template>
          </u-table-column>
        </u-table>
        <el-pagination
          style="text-align: center; margin-top: 10px"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalTable1"
          @current-change="currentPageChangeTable1"
          :page-sizes="pageSizesTable1"
          :page-size.sync="pageSizeTable1"
          @size-change="sizeChangeTable1"
        >
        </el-pagination>
      </el-col>

      <!-- Table 2-->
      <el-col :span="10" :offset="0">
        <!-- action in table 2-->
        <el-row :gutter="20" class="conditions-search2">
          <el-col :span="10" :offset="0">
            <el-button type="success" @click="searchListMatchNeedMap()"
              >Tìm các trận chưa ghép</el-button
            >
          </el-col>
          <el-col :span="6" :offset="0">
            <el-input
              v-model="txtsearchListMatchNeedMap"
              placeholder="Nhập tên trận đấu"
              clearable
            ></el-input>
          </el-col>
          <el-col :span="6" :offset="0">
            <el-button type="warning" @click="hideMatch()">Ẩn Trận</el-button>
          </el-col>
        </el-row>
        <u-table
          ref="table2"
          :data="computeDataTable2"
          @current-change="handleCurrentTableRowChange"
          height="65vh"
          header-row-class-name="header-row-class-name"
          border
          stripe
          highlight-current-row
          size="small"
          empty-text="Không có dữ liệu"
        >
          <u-table-column
            prop="no"
            label="Những trận đấu cần ghép"
            header-align="left"
            align="left"
          >
            <template prod="matchName" slot-scope="scope">
              <span>{{ scope.row.match }}</span>
            </template>
          </u-table-column>
          <u-table-column
            prop="status"
            label="Ẩn trận đã tìm"
            header-align="center"
            sortable
            align="center"
          >
            <template slot-scope="scope">
              <el-checkbox
                title="Ẩn trận"
                v-model="scope.row.hidden"
              ></el-checkbox>
            </template>
          </u-table-column>
        </u-table>
        <Pagination
          v-if="dataTable2.length > 0"
          @sizeChangeTable="sizeChangeTable2"
          @currentPageChangeTable="currentPageChangeTable2"
          :pageTable="pageTable2"
          :pageSizesTable="pageSizesTable2"
          :pageSizeTable="pageSizeTable2"
          :totalTable="totalTable2"
        ></Pagination>
      </el-col>
    </el-row>

    <!-- Table League -->
    <el-row :gutter="20" v-if="mode === '1'">
      <!-- Table 3-->
      <el-col :span="14" :offset="0">
        <el-row :gutter="20" class="conditions-search2">
          <!-- action in table 3-->
          <el-col :span="6" :offset="0">
            <el-button type="success" @click="searchSameLeague()"
              >Tìm kiếm giải giống nhau</el-button
            >
          </el-col>
          <el-col :span="6" :offset="0">
            <el-input
              v-model="txtSearchLeftSameLeague"
              placeholder="Nhập tên bên trái của giải đấu"
              clearable
            ></el-input>
          </el-col>
          <el-col :span="6" :offset="0">
            <el-button type="warning" @click="mapSameLeague()"
              >Ghép Giải</el-button
            >
          </el-col>
        </el-row>
        <u-table
          ref="table3"
          :data="computeDataTable3"
          height="65vh"
          header-row-class-name="header-row-class-name"
          border
          stripe
          highlight-current-row
          size="small"
          empty-text="Không có dữ liệu"
        >
          <u-table-column
            prop="rootLeague"
            label="Giải đấu chuẩn(viva) "
            header-align="center"
            align="right"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.root.league }}</span>
            </template>
          </u-table-column>

          <u-table-column
            prop="diffLeague"
            label="Giải cần ghép"
            header-align="center"
            sortable
            align="right"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.diff.league }}</span>
            </template>
          </u-table-column>

          <u-table-column
            prop="status"
            label="Xác Nhận"
            header-align="center"
            sortable
            align="center"
          >
            <template slot-scope="scope">
              <el-checkbox
                title="Giống nhau"
                v-model="scope.row.status"
              ></el-checkbox>
            </template>
          </u-table-column>
        </u-table>
        <el-pagination
          style="text-align: center; margin-top: 10px"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalTable3"
          @current-change="currentPageChangeTable3"
          :page-sizes="pageSizesTable3"
          :page-size.sync="pageSizeTable3"
          @size-change="sizeChangeTable3"
        >
        </el-pagination>
      </el-col>

      <!-- Table 4-->
      <el-col :span="10" :offset="0">
        <!-- action in table 4-->
        <el-row :gutter="20" class="conditions-search2">
          <el-col :span="10" :offset="0">
            <el-button type="success" @click="searchListLeagueNeedMap()"
              >Tìm các giải chưa ghép</el-button
            >
          </el-col>
          <el-col :span="6" :offset="0">
            <el-input
              v-model="txtsearchListLeagueNeedMap"
              placeholder="Nhập tên giải đấu"
              clearable
            ></el-input>
          </el-col>
        </el-row>
        <u-table
          ref="table4"
          :data="computeDataTable4"
          height="65vh"
          header-row-class-name="header-row-class-name"
          border
          stripe
          highlight-current-row
          @current-change="handleCurrentTableRowChange"
          size="small"
          empty-text="Không có dữ liệu"
        >
          <u-table-column
            prop="no"
            label="Những giải đấu cần ghép"
            header-align="left"
            align="left"
          >
            <template prod="leagueName" slot-scope="scope">
              <span>{{ scope.row.league }}</span>
            </template>
          </u-table-column>
        </u-table>
        <Pagination
          v-if="dataTable4.length > 0"
          @sizeChangeTable="sizeChangeTable4"
          @currentPageChangeTable="currentPageChangeTable4"
          :pageTable="pageTable4"
          :pageSizesTable="pageSizesTable4"
          :pageSizeTable="pageSizeTable4"
          :totalTable="totalTable4"
        ></Pagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Header from "../Common/Header.vue";
import ApiService from "../../service/api.service";

export default {
  name: "MappingMatchLeague",
  components: {
    Header,
  },
  data() {
    return {
      title: "Ghép trận đấu & giải đấu",
      mode: "0",
      modeNm: "Ghép trận đấu",
      modeOptions: [
        {
          codeKey: "0",
          codeName: "Ghép trận đấu",
        },
        {
          codeKey: "1",
          codeName: "Ghép giải đấu",
        },
      ],
      virtualSport: false,
      dateFromTo: [
        this.$moment().subtract(3, "days").format(this.$root.formatDate),
        this.$moment().endOf("year").format(this.$root.formatDate),
      ],
      // Match table 1
      dataTable1: [],
      pageTable1: 1,
      pageSizeTable1: 1000,
      pageSizesTable1: [100, 200, 500, 1000, 10000],
      totalTable1: 0,
      txtSearchLeftSameMatch: "",
      // Match table 2
      txtsearchListMatchNeedMap: "",
      dataTable2: [],
      pageTable2: 1,
      pageSizeTable2: 500,
      pageSizesTable2: [100, 200, 500, 1000],
      totalTable2: 0,

      // Match table 3
      dataTable3: [],
      pageTable3: 1,
      pageSizeTable3: 1000,
      pageSizesTable3: [100, 200, 500, 1000, 10000],
      totalTable3: 0,
      txtSearchLeftSameLeague: "",
      // Match table 3
      txtsearchListLeagueNeedMap: "",
      dataTable4: [],
      pageTable4: 1,
      pageSizeTable4: 500,
      pageSizesTable4: [100, 200, 500, 1000],
      totalTable4: 0,
      // click row
    };
  },
  computed: {
    ...mapState("MappingMatchLeague", []),
    computeDataTable1() {
      if (
        this.txtSearchLeftSameMatch !== "" &&
        this.txtSearchLeftSameMatch.length >= 3
      ) {
        return this.dataTable1.filter((row) => {
          const userNameSearch = row.diff.match.toString().toLowerCase();
          const searchTerm = this.txtSearchLeftSameMatch.toLowerCase();
          return userNameSearch.includes(searchTerm);
        });
      } else {
        return this.dataTable1.slice(
          this.pageSizeTable1 * this.pageTable1 - this.pageSizeTable1,
          this.pageSizeTable1 * this.pageTable1
        );
      }
    },
    computeDataTable2() {
      if (
        this.txtsearchListMatchNeedMap !== "" &&
        this.txtsearchListMatchNeedMap.length >= 3
      ) {
        return this.dataTable2.filter((row) => {
          const userNameSearch = row.match.toString().toLowerCase();
          const searchTerm = this.txtsearchListMatchNeedMap.toLowerCase();
          return userNameSearch.includes(searchTerm);
        });
      } else {
        return this.dataTable2;
      }
    },
    computeDataTable3() {
      if (
        this.txtSearchLeftSameLeague !== "" &&
        this.txtSearchLeftSameLeague.length >= 3
      ) {
        return this.dataTable3.filter((row) => {
          const userNameSearch = row.diff.league.toString().toLowerCase();
          const searchTerm = this.txtSearchLeftSameLeague.toLowerCase();
          return userNameSearch.includes(searchTerm);
        });
      } else {
        return this.dataTable3.slice(
          this.pageSizeTable3 * this.pageTable3 - this.pageSizeTable3,
          this.pageSizeTable3 * this.pageTable3
        );
      }
    },
    computeDataTable4() {
      if (
        this.txtsearchListLeagueNeedMap !== "" &&
        this.txtsearchListLeagueNeedMap.length >= 3
      ) {
        return this.dataTable4.filter((row) => {
          const userNameSearch = row.league.toString().toLowerCase();
          const searchTerm = this.txtsearchListLeagueNeedMap.toLowerCase();
          return userNameSearch.includes(searchTerm);
        });
      } else {
        return this.dataTable4;
      }
    },
  },
  beforeCreate() {
    console.log("I have not been created yet!");
  },
  created() {
    console.log("I have just been created!");
  },
  beforeMount() {
    console.log("I am about to be mounted to the DOM!");
  },
  mounted() {
    this.getDataMode1();
    console.log(" tôi bấm tới đây ");
  },
  beforeDestroy() {
    console.log("I am about to be removed from the DOM!");
  },
  destroyed() {
    console.log("I no longer exist...");
  },
  methods: {
    ...mapActions("HistoryBatchJob", ["setModeOptions", "setDomainOptions"]),
    errorApi(error) {
      console.log(error);
      let message = "";
      let code = "";
      if (error.response) {
        message = error.response.data.message;
        code = error.response.data.code;
      } else {
        message = error.message;
      }
      this.$alert(message, `Lỗi ${code}`, {
        confirmButtonText: "OK",
        confirmButtonClass: "btn-success",
        type: "error",
      });
    },
    handleCurrentTableRowChange(currentRow) {
      if (currentRow.match) {
        this.txtSearchLeftSameMatch = currentRow.match;
      }

      if (currentRow.league) {
        this.txtSearchLeftSameLeague = currentRow.league;
      }
    },
    setPickerOptions() {
      let _this = this;
      let pickerOptions = {
        disabledDate(time) {
          let twoMonthsAgo = _this.$moment().subtract(2, "months");
          let currentMonth = _this.$moment();
          let timeFormat = _this.$moment(time);
          let returnValue = !(
            _this.$moment(timeFormat).isAfter(twoMonthsAgo) &&
            _this.$moment(timeFormat).isBefore(currentMonth)
          );
          return returnValue;
        },
        shortcuts: [
          {
            text: "Chọn nhanh",
            onClick(picker) {
              const end = _this
                .$moment()
                .endOf("year")
                .format(_this.$root.formatDate);
              const start = _this
                .$moment()
                .subtract(3, "days")
                .format(_this.$root.formatDate);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Hôm nay",
            onClick(picker) {
              const end = _this.$moment().format(_this.$root.formatDate);
              const start = _this.$moment().format(_this.$root.formatDate);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      };
      return pickerOptions;
    },
    getDataMode1() {
      this.searchListMatchNeedMap();
      this.searchSameMatch();
    },
    getDataMode2() {
      this.searchListLeagueNeedMap();
      this.searchSameLeague();
    },
    onModeChanged() {
      if (this.mode === "0") {
        this.modeNm = "Ghép trận đấu";
        this.getDataMode1();
      } else {
        this.modeNm = "Ghép giải đấu";
        this.getDataMode2();
      }
    },
    goBack() {
      this.$router.push("menu");
    },
    setParamAPI(pageTable, pageSizeTable) {
      const dateFrom = this.$moment(this.dateFromTo[0]).format(
        this.$root.formatDateAPI
      );
      const dateTo = this.$moment(this.dateFromTo[1]).format(
        this.$root.formatDateAPI
      );
      let params = {
        virtualSport: this.virtualSport,
        dateFrom: dateFrom,
        dateTo: dateTo,
        page: pageTable,
        limit: pageSizeTable,
      };
      return params;
    },
    searchSameMatch() {
      this.$root.fullScreenLoading = true;
      let url = `${this.$root.url}/bet-mapping/find-same-match`;
      let _this = this;
      let params = this.setParamAPI(this.pageTable1, this.pageSizeTable1);
      this.txtSearchLeftSameMatch = "";
      ApiService.get(url, params)
        .then((response) => {
          this.$root.fullScreenLoading = false;
          if (response.data && response.data.length > 0) {
            _this.dataTable1 = response.data;
            _this.totalTable1 = response.data.length;
          }
        })
        .catch((error) => {
          this.$root.fullScreenLoading = false;
          _this.errorApi(error);
        });
    },
    mapSameMatch() {
      this.$root.fullScreenLoading = true;
      let url = `${this.$root.url}/bet-mapping/mapping-match`;
      let _this = this;
      let params = this.setParamAPI(this.pageTable1, this.pageSizeTable1);
      let data = this.dataTable1.filter((ele) => ele.status === true);
      ApiService.putWithParam(url, data, params)
        .then((response) => {
          this.$root.fullScreenLoading = false;
          console.log(response);
          this.getDataMode1();
        })
        .catch((error) => {
          this.$root.fullScreenLoading = false;
          _this.errorApi(error);
        });
    },
    hideMatch() {
      this.$root.fullScreenLoading = true;
      let url = `${this.$root.url}/bet-mapping/hide-list-match`;
      let _this = this;
      let params = this.setParamAPI(this.pageTable1, this.pageSizeTable1);
      let data = this.dataTable2.filter((ele) => ele.hidden === true);
      ApiService.putWithParam(url, data, params)
        .then((response) => {
          this.$root.fullScreenLoading = false;
          console.log(response);
          this.getDataMode1();
        })
        .catch((error) => {
          this.$root.fullScreenLoading = false;
          _this.errorApi(error);
        });
    },
    searchListMatchNeedMap() {
      this.txtsearchListMatchNeedMap = "";
      let url = `${this.$root.url}/bet-mapping/get-match-need-mapping`;
      let _this = this;
      let params = this.setParamAPI(this.pageTable2, this.pageSizeTable2);
      ApiService.get(url, params)
        .then((response) => {
          if (response.data.total > 0) {
            _this.dataTable2 = response.data.listMapping;
            _this.totalTable2 = response.data.total;
          }
        })
        .catch((error) => {
          _this.errorApi(error);
        });
    },
    sizeChangeTable1(val) {
      this.pageSizeTable1 = val;
    },
    currentPageChangeTable1(val) {
      this.pageTable1 = val;
    },
    sizeChangeTable2(val) {
      this.pageSizeTable2 = val;
      this.searchListMatchNeedMap();
    },
    currentPageChangeTable2(val) {
      this.pageTable2 = val;
      this.searchListMatchNeedMap();
    },
    searchSameLeague() {
      this.$root.fullScreenLoading = true;
      let url = `${this.$root.url}/bet-mapping/find-same-league`;
      let _this = this;
      this.txtSearchLeftSameLeague = "";
      let params = this.setParamAPI(this.pageTable3, this.pageSizeTable3);
      ApiService.get(url, params)
        .then((response) => {
          this.$root.fullScreenLoading = false;

          if (response.data && response.data.length > 0) {
            _this.dataTable3 = response.data;
            _this.totalTable3 = response.data.length;
          }
        })
        .catch((error) => {
          this.$root.fullScreenLoading = false;
          _this.errorApi(error);
        });
    },
    mapSameLeague() {
      this.$root.fullScreenLoading = true;
      let url = `${this.$root.url}/bet-mapping/mapping-league`;
      let _this = this;
      let params = this.setParamAPI(this.pageTable3, this.pageSizeTable3);
      let data = this.dataTable3.filter((ele) => ele.status === true);
      ApiService.putWithParam(url, data, params)
        .then((response) => {
          this.$root.fullScreenLoading = false;
          console.log(response);
          this.getDataMode2();
        })
        .catch((error) => {
          this.$root.fullScreenLoading = false;
          _this.errorApi(error);
        });
    },
    searchListLeagueNeedMap() {
      this.txtsearchListLeagueNeedMap = "";
      let url = `${this.$root.url}/bet-mapping/get-league-need-mapping`;
      let _this = this;
      let params = this.setParamAPI(this.pageTable4, this.pageSizeTable4);
      ApiService.get(url, params)
        .then((response) => {
          if (response?.data && response.data.total > 0) {
            _this.dataTable4 = response.data.listMapping;
            _this.totalTable4 = response.data.total;
          }
        })
        .catch((error) => {
          _this.errorApi(error);
        });
    },
    sizeChangeTable3(val) {
      this.pageSizeTable1 = val;
    },
    currentPageChangeTable3(val) {
      this.pageTable1 = val;
    },
    sizeChangeTable4(val) {
      this.pageSizeTable4 = val;
      this.searchListLeagueNeedMap();
    },
    currentPageChangeTable4(val) {
      this.pageTable4 = val;
      this.searchListLeagueNeedMap();
    },
  },
};
</script>

<style lang="scss">
.mapping-match-league {
  .title-select {
    margin-right: 1vw;
  }

  .conditions-search {
    margin-bottom: 1vh;
  }
  .conditions-search2 {
    align-items: center;
    margin-bottom: 1vh;
    margin-top: 1vh;
  }

  .name-mdoe {
    height: 5.6vh;
    background-color: #0e9cc0;
    color: white;
    font-size: 18px;

    [class*="el-col-"] {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  .header-row-class-name {
    th {
      background-color: #f56c6c;
      color: black;
    }
  }

  .u-table {
    .cell {
      word-break: break-word;
    }
  }

  .u-table__body {
    tr {
      &.current-row {
        td {
          background-color: #96dcee !important;
        }
      }
      &:hover > td {
        background-color: #96dcee !important;
      }
    }
  }

  .pagination {
    text-align: right;
    margin-top: 1vh;

    .el-pagination button,
    .el-pagination span:not([class*="suffix"]) {
      font-size: 14px;
    }
  }
}

.btn-success {
  color: #fff !important;
  background-color: #0e9cc0 !important;
  border-color: #0e9cc0 !important;

  &:hover {
    color: #fff !important;
    background: #85ce61 !important;
    border-color: #85ce61 !important;
  }
}

.btn-success-plain {
  color: #0e9cc0 !important;
  background: #f0f9eb !important;
  border-color: #c2e7b0 !important;

  &:hover {
    color: #fff !important;
    background: #85ce61 !important;
    border-color: #85ce61 !important;
  }
}
</style>
