<template>
  <div id="add-edit-user" class="add-edit-user" v-on:keyup.enter="search()">
    <el-dialog
      width="60vw"
      top="10vh"
      :title="`${type === 'add' ? 'Thêm' : 'Chỉnh sửa'} User`"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :lock-scroll="false"
      class="add-or-edit-user"
    >
      <el-form label-width="120px">
        <el-form-item label="TÊN TÀI KHOẢN">
          <el-input v-model="data.name"></el-input>
        </el-form-item>
        <el-form-item label="Login id" required>
          <el-input v-model="data.userName"></el-input>
          <el-button
            type="primary"
            class="generatePass"
            minlength="6"
            maxlength="20"
            :disabled="type === 'edit'"
            plain
            @click="onGenerateUserName()"
            >Tạo Tài khoản</el-button
          >
        </el-form-item>
        <el-form-item label="MẬT KHẨU" required>
          <el-input v-model="data.password"></el-input>
          <el-button
            type="primary"
            minlength="6"
            maxlength="20"
            class="generatePass"
            plain
            @click="onGenerate()"
            >Tạo Mật Khẩu</el-button
          >
        </el-form-item>
        <el-form-item label="GHI CHÚ">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            v-model="data.note"
          ></el-input>
        </el-form-item>
        <el-form-item label="Phone 1">
          <el-input
            type="primary"
            maxlength="20"
            v-model="data.phone"
          ></el-input>
        </el-form-item>
        <el-form-item label="Phone 2">
          <el-input
            type="primary"
            maxlength="20"
            v-model="data.phone2"
          ></el-input>
        </el-form-item>
        <el-form-item label="Email">
          <el-input
            type="primary"
            maxlength="100"
            v-model="data.email"
          ></el-input>
        </el-form-item>
        <el-form-item label="Roles">
          <el-select
            multiple
            v-model="data.roles"
            :reduce="(item) => genre.value"
          >
            <el-option
              v-for="item in rolesOptions"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <b style="color: red; margin-left: 10px"
            >Chú ý: Role member sẽ là mặt định</b
          >
        </el-form-item>
      </el-form>
      <!-- footer -->
      <el-row slot="footer" class="dialog-footer">
        <el-col :span="24">
          <el-button type="success" plain @click="cancel()">Hủy</el-button>
          <el-button type="success" @click="confirm()">{{
            type === "add" ? "Thêm" : "Lưu"
          }}</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "AddEditUser",
  props: ["dialogVisible", "dataRow", "type"],
  data() {
    return {
      data: {},
      title: "Add User",
      rolesOptions: [
        {
          name: "ADMIN",
          value: "ROLE_ADMIN",
        },
        {
          name: "Quản lý",
          value: "ROLE_MANAGER",
        },
        {
          name: "Support",
          value: "ROLE_SUPPORT",
        },
        {
          name: "Kế toán",
          value: "ROLE_ACCOUNTANT",
        },
        {
          name: "Member",
          value: "ROLE_MEMBER",
        },
      ],

      characters: [
        {
          name: "Lowercase",
          value: "abcdefghijklmnopqrstuvwxyz",
        },
        {
          name: "Uppercase",
          value: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
        },
        {
          name: "Numbers",
          value: "0123456789",
        },
      ],
      password: "",
      gLength: 8,
    };
  },
  computed: {
    ...mapState("AddEditUser", []),
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        return;
      }
      if (this.dataRow) {
        this.data = this.dataRow;
      } else {
        this.data = {
          id: "",
          userName: "",
          name: "",
          password: "",
          note: "",
          phone: "",
          phone2: "",
          email: "",
          roles: [],
        };
      }
    },
  },
  beforeCreate() {
    console.log("I have not been created yet!");
  },
  created() {
    console.log("I have just been created!");
  },
  beforeMount() {
    console.log("I am about to be mounted to the DOM!");
  },
  mounted() {
    console.log("I am mounted to the DOM!");
  },
  beforeDestroy() {
    console.log("I am about to be removed from the DOM!");
  },
  destroyed() {
    console.log("I no longer exist...");
  },
  methods: {
    ...mapActions("AddEditUser", []),
    errorApi(error) {
      console.log(error);
      let message = "";
      let code = "";
      if (error.response) {
        message = error.response.data.message;
        code = error.response.data.code;
      } else {
        message = error.message;
      }
      this.$alert(message, `Lỗi ${code}`, {
        confirmButtonText: "OK",
        confirmButtonClass: "btn-success",
        type: "error",
      });
    },
    goBack() {
      this.$router.push("list-user");
    },
    handleClose() {
      this.$confirm(
        `Bạn có chắc muốn hủy ${
          this.type === "add" ? "thêm" : "chỉnh sửa"
        } Player?`,
        `Xác nhận`,
        {
          confirmButtonText: "Đồng Ý",
          cancelButtonText: "Hủy",
          confirmButtonClass: "btn-success",
          canhandleClosecelButtonClass: "btn-success-plain",
        }
      )
        .then((response) => {
          console.log(response);
          this.close();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    close() {
      this.data = {};
      this.$emit("closeDialog");
    },
    cancel() {
      this.handleClose();
    },
    confirm() {
      let isValid =
        this.checkInputUserName("text", this.data.userName, "ID tài khoản") &&
        this.checkInputPass("text", this.data.password, "Mật khẩu");
      if (isValid) {
        if (this.type === "add") {
          this.$emit("addUser", this.data);
        } else {
          this.$emit("editUser", this.data);
        }
        this.close();
      }
    },
    checkInputUserName(type, input, text) {
      if (type === "text") {
        if (input === "") {
          this.$alert(`Bạn phải nhập ${text}`, `Lỗi`, {
            confirmButtonText: "OK",
            confirmButtonClass: "btn-success",
            type: "error",
          });
          return false;
        } else {
          let e1 = /^(?=[a-zA-Z0-9._]{6,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/;
          if (!e1.test(input)) {
            this.$alert(
              `${text} không được chứa khoảng trắng hoặc ký tự đặc biệt và it nhất 6 kí tự`,
              `Lỗi`,
              {
                confirmButtonText: "OK",
                confirmButtonClass: "btn-success",
                type: "error",
              }
            );
            return false;
          }
        }
      }
      return true;
    },
    checkInputPass(type, input, text) {
      if (type === "text") {
        if (input === "") {
          this.$alert(`Bạn phải nhập ${text}`, `Lỗi`, {
            confirmButtonText: "OK",
            confirmButtonClass: "btn-success",
            type: "error",
          });
          return false;
        }
      }
      return true;
    },
    onGenerateUserName: function () {
      let result = "";
      let charactersVal = "";
      for (var j = 0; j < this.characters.length; j++) {
        charactersVal += this.characters[j].value;
      }
      for (var i = 0; i < this.gLength; i++) {
        result += charactersVal.charAt(
          Math.floor(Math.random() * charactersVal.length)
        );
      }
      this.data.userName = result;
    },
    onGenerate: function () {
      let result = "";
      let charactersVal = "";
      for (var j = 0; j < this.characters.length; j++) {
        charactersVal += this.characters[j].value;
      }
      for (var i = 0; i < this.gLength; i++) {
        result += charactersVal.charAt(
          Math.floor(Math.random() * charactersVal.length)
        );
      }
      this.data.password = result;
    },
  },
};
</script>

<style lang="scss">
.add-edit-user {
  .el-dialog {
    max-height: 90%;
    overflow: auto;
    .upload-img .el-form-item__content {
      margin-left: 0 !important;
    }
  }
}
.generatePass {
  margin-top: 10px !important;
}
.btn-success {
  color: #fff !important;
  background-color: #0e9cc0 !important;
  border-color: #0e9cc0 !important;

  &:hover {
    color: #fff !important;
    background: #85ce61 !important;
    border-color: #85ce61 !important;
  }
}
@media (max-width: 767px) {
  .el-message-box {
    width: 90vw !important;
  }
  .el-form-item__label {
    font-size: 11px !important;
    width: 100px !important;
  }
}
</style>
