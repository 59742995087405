<template>
  <div class="rui-ro">
    <el-table
      ref="tableRuiRo"
      :data="dataRuiRo"
      style="width: 100%"
      header-row-class-name="header-row-class-name"
      border
      size="small"
      highlight-current-row
      empty-text="Không có dữ liệu"
      :row-class-name="rowClassName"
      @current-change="currentChange"
    >
      <el-table-column
        prop="stt"
        label="STT"
        header-align="center"
        align="center"
        width="40"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.rowNo }}</span>
        </template>
      </el-table-column>

      <el-table-column label="" header-align="center" align="center" width="40">
        <template slot-scope="scope">
          <span @click="removeRow(scope)">
            <i class="el-icon-circle-close"></i>
          </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="refNo"
        label="Mã Cược"
        header-align="center"
        align="left"
        min-width="140"
      >
        <template slot-scope="scope">
          <span class="text-bold">
            {{ scope.row.refNo }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="domainName"
        label="Hệ Thống"
        header-align="center"
        align="left"
        min-width="100"
      >
        <template slot-scope="scope">
          <span>
            {{ scope.row.domainName }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="ip"
        label="IP"
        header-align="center"
        align="center"
        min-width="120"
      >
        <template slot-scope="scope">
          <span>
            {{ getIp(scope.row) }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="memberId"
        label="Tài Khoản"
        header-align="center"
        align="center"
        min-width="130"
      >
        <template slot-scope="scope">
          <span class="text-bold"> {{ getUsername(scope.row) }} </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="match"
        label="Trận Đấu"
        header-align="center"
        align="center"
        min-width="220"
      >
        <template slot-scope="scope">
          <span> {{ scope.row.matchLabel }} {{ scope.row.match }} </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="league"
        label="Giải Đấu"
        header-align="center"
        align="center"
        min-width="200"
      >
        <template slot-scope="scope">
          <span>
            {{ scope.row.league }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="choiceDisplay"
        label="Cược/live"
        header-align="center"
        align="center"
        min-width="130"
      >
        <template slot-scope="scope">
          <span
            :class="getClassChoiceDisplay(scope.row)"
            v-html="getChoiceDisplay(scope.row.choiceDisplay)"
          >
          </span>
        </template>
      </el-table-column>

      <!--<el-table-column
        prop="live"
        label="Chưa đá/Đang đá"
        header-align="center"
        align="center"
        min-width="130"
      >
        <template slot-scope="scope">
          <span>
            {{ scope.row.live ? "Đang đá" : "Chưa đá" }}
          </span>
        </template>
      </el-table-column>-->

      <el-table-column
        prop="odds"
        label="Giá"
        header-align="center"
        align="center"
        min-width="75"
      >
        <template slot-scope="scope">
          <span>
            {{ scope.row.odds }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="stake"
        label="Điểm"
        header-align="center"
        align="center"
        min-width="75"
      >
        <template slot-scope="scope">
          <span>
            {{ scope.row.stake }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="winLoss"
        label="Kết quả"
        header-align="center"
        align="center"
        min-width="75"
      >
        <template slot-scope="scope">
          <span>
            {{ scope.row.status }}
            <br />
            {{ scope.row.winLoss }}
            <!-- <br />
            {{ scope.row.com }} -->
          </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="thoiGianCuoc"
        label="Thời Gian Cược"
        header-align="center"
        align="center"
        min-width="160"
      >
        <template slot-scope="scope">
          <span
            v-show="!thayDoiGio"
            v-html="getTimeVN(scope.row.timeChoiceVst)"
          >
          </span>
          <span v-show="thayDoiGio">
            {{ scope.row.timeChoice }}
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "RuiRo",
  props: {
    dataRuiRo: {
      default: [],
    },
    anDong: {
      default: false,
    },
    anIp: {
      default: false,
    },
    anUsername: {
      default: false,
    },
    anStake: {
      default: true,
    },
    demTheoUsername: {
      default: false,
    },
    thayDoiGio: {
      default: false,
    },
    count: {
      default: 0,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("Report", [""]),
  },
  watch: {
    demTheoUsername() {
      this.setDemTheoUsernameData();
    },
  },
  beforeCreate() {
    console.log("I have not been created yet!");
  },
  created() {
    console.log("I have just been created!");
  },
  beforeMount() {
    console.log("I am about to be mounted to the DOM!");
  },
  mounted() {
    console.log("I am mounted to the DOM!");
  },
  beforeDestroy() {
    console.log("I am about to be removed from the DOM!");
  },
  destroyed() {
    console.log("I no longer exist...");
  },
  methods: {
    ...mapActions("Report", []),
    currentChange(currentRow) {
      currentRow.selectedRow = !currentRow.selectedRow;
      this.$refs.tableRuiRo.setCurrentRow({});
    },
    rowClassName({ row }) {
      if (row.selectedRow) {
        return "selected-row";
      } else if (row.children) {
        return "row-cuoc-goc";
      } else {
        return "";
      }
    },
    setDemTheoUsernameData() {},
    getIp(row) {
      if (this.anIp) {
        let str = row.ip.split(".").pop();
        return `*********${str}`;
      } else {
        return row.ip;
      }
    },
    getUsername(row) {
      if (this.anUsername) {
        let str = row.memberId.substr(row.memberId.length - 3);
        return `*********${str}`;
      } else {
        return row.memberId;
      }
    },
    getStake(row) {
      if (this.anStake && !row.children) {
        return `*******`;
      } else {
        return row.stake;
      }
    },
    getChoiceDisplay(choiceDisplay) {
      if (choiceDisplay) {
        let arrayStr = choiceDisplay.split("||");
        let str = arrayStr[0];
        str += "<br />";
        str += arrayStr[1] || "";
        return str;
      } else {
        return "";
      }
    },
    getClassChoiceDisplay(row) {
      let classStr = "";
      if (row.children) {
        classStr += "cuoc-goc ";
      }
      if (row.choice === 1 || row.choice === 3) {
        classStr += "tai-tren";
      } else {
        classStr += "xiu-duoi";
      }
      return classStr;
    },
    getTimeVN(timeVn) {
      if (timeVn) {
        let arrayStr = timeVn.split(" ");
        let str = "Giờ VN:";
        str += "<br />";
        str += arrayStr[0] + " ";
        str +=
          //"<font class='text-bold'>" +
          arrayStr[1] + " " + arrayStr[2];
        //+ "</font>";
        return str;
      } else {
        return "";
      }
    },
    getTimeDomain(domain, time) {
      if (time) {
        let arrayStr = time.split(" ");
        let str = "Giờ theo: " + domain;
        str += "<br />";
        str += arrayStr[0] + " ";
        str +=
          //"<font class='text-bold'>" +
          arrayStr[1] + " " + arrayStr[2];
        //+ "</font>";
        return str;
      } else {
        return "";
      }
    },
    removeRow(scope) {
      this.dataRuiRo.splice(scope.$index, 1);
      this.$refs.tableRuiRo.setCurrentRow({});
    },
  },
};
</script>
<style lang="scss">
.rui-ro {
  .el-table {
    color: black;
    .selected-row {
      td {
        background-color: #96dcee !important;
      }
    }

    .row-cuoc-goc {
      td {
        background-color: #e2eff1 !important;
      }
    }

    .cell {
      line-height: unset;
      word-break: break-word;

      .el-icon-circle-close {
        color: red;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
          opacity: 0.8;
          font-size: 18px;
        }
      }
    }

    .text-bold {
      font-weight: bold;

      [class*=" el-icon-"],
      [class^="el-icon-"] {
        font-weight: bold;
      }
    }

    .text-red {
      color: red;
    }

    .tai-tren {
      color: blue;
      font-weight: bold;
    }

    .xiu-duoi {
      color: mediumseagreen;
      font-weight: bold;
    }

    .tai-tren {
      color: mediumslateblue;
      font-weight: bold;
    }

    .xiu-duoi {
      color: mediumseagreen;
      font-weight: bold;
    }

    .cuoc-goc {
      &.tai-tren {
        color: blue;
        font-weight: bold;
      }

      &.xiu-duoi {
        color: green;
        font-weight: bold;
      }
    }
  }
}
</style>
