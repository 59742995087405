<template>
  <el-page-header @back="goBack" title="BACK">
    <template slot="content">
      <div class="icon-home" @click="$router.push('menu')">
        <img alt="icon" src="../../assets/img/logo.svg" />
      </div>
      <div>
        <span>{{ title.toUpperCase() }}</span>
      </div>
      <div class="header-menu">
        <i class="el-icon-user-solid" title="Quyền"></i>
        <span class="hidden-mobile">
          {{ this.highestRole }}
        </span>
        <span class="hidden-mobile">
          Xin chào: {{ this.name }} ({{ this.userName }})</span
        >
        <i class="el-icon-switch-button" title="Logout" v-on:click="logout"></i>
      </div>
    </template>
  </el-page-header>
</template>

<script>
export default {
  name: "Header",
  props: {
    title: {
      default: "",
    },
    backTo: {
      default: "",
    },
  },
  data() {
    return {
      userName: "",
      name: "",
      highestRole: "",
    };
  },
  mounted() {
    this.getUserName();
  },
  methods: {
    logout() {
      localStorage.clear();
      this.$router.push("/");
    },
    getUserName() {
      this.userName = localStorage.getItem("userName");
      let user = {
        userName: "",
        name: "",
        roles: [],
      };
      user = JSON.parse(localStorage.getItem("user"));
      if (user) {
        this.name = user.name;
        const roles = user.roles;
        if (roles.includes("ROLE_ADMIN")) {
          this.highestRole = "ADMIN";
        } else if (roles.includes("ROLE_MANAGER")) {
          this.highestRole = "MANAGER";
        } else if (roles.includes("ROLE_ACCOUNTANT")) {
          this.highestRole = "ACCOUNTANT";
        } else if (roles.includes("ROLE_SUPPORT")) {
          this.highestRole = "SUPPORT";
        } else {
          this.highestRole = "MEMBER";
        }
      }
    },
    goBack() {
      if (this.backTo !== "") {
        this.$router.push(this.backTo);
      } else {
        if (window.history.state) {
          this.$router.back();
        } else {
          this.$router.push("menu");
        }
      }
    },
  },
};
</script>

<style lang="scss">
.header {
  padding: 0 10px;
  display: flex;
  height: 60px;
  line-height: 60px;
  background-color: #214785;
}

.header-title {
  float: left;
  cursor: pointer;
  .title-page {
    color: #fff;
    padding: 0 15px;
    border-right: 1px solid;
    border-color: #fff;
    text-decoration: none;
  }
  .title-page:last-child {
    border-right: none;
  }
}

.header-menu {
  float: right;
  padding: 0 15px;
  text-align: right;
  line-height: 50px;
  color: #fff;
  height: 60px;
  width: 100%;
  flex: 1;
  font-size: 30px;
  i {
    font-size: 20px;
    cursor: pointer;
  }
  span {
    margin-right: 30px;
    font-size: 16px;
  }
}
.header-submenu-a {
  text-decoration: none;
  color: #4cc4b8;
  font-weight: bold;
  font-size: 16px;
}
.header-span {
  display: flex;
  img {
    height: 40px;
    line-height: 40px;
    margin: 10px 10px 10px 10px;
  }
  .active {
    color: red !important;
  }
}
.el-dropdown-menu__item .active {
  color: red !important;
}
@media (min-width: 768px) {
  .hidden-desktop {
    display: none;
  }
}
</style>
