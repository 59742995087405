import axios from "axios";
import jwt_decode from "jwt-decode";

const PREFIX_TOKEN = "Bearer";

const ApiService = {
  setHeader() {
    let token = localStorage.getItem("jwt");
    axios.defaults.headers.common["Authorization"] = `${PREFIX_TOKEN} ${token}`;
  },

  removeHeader() {
    axios.defaults.headers.common = {};
  },

  get(url, params) {
    this.setHeader();
    return axios.get(url, { params: params });
  },

  post(resource, data) {
    this.setHeader();
    return axios.post(resource, data);
  },

  put(resource, data) {
    this.setHeader();
    return axios.put(resource, data);
  },

  putWithParam(resource, data, params) {
    this.setHeader();
    return axios.put(resource, data, { params: params });
  },

  patch(resource, data) {
    this.setHeader();
    return axios.patch(resource, data);
  },

  delete(resource, data) {
    this.setHeader();
    return axios.delete(resource, { data: data });
  },

  checkTokenValidity(token) {
    if (!token) {
      return false;
    }
    const decodedToken = jwt_decode(token);
    return decodedToken && decodedToken.exp * 1000 > new Date().getTime();
  },
};

export default ApiService;
