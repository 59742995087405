<template>
  <div id="list-player" class="list-player" v-on:keyup.enter="search()">
    <Header :title="title" :backTo="backTo" />

    <!-- Điều kiện tìm kiếm -->
    <el-row :gutter="20" class="conditions-search">
      <el-col :span="17" :offset="1">
        <label class="title-select">User Name</label>
        <el-input v-model="userName" clearable></el-input>
      </el-col>
      <el-col :span="6" :offset="0">
        <el-button type="success" @click="search()">Tìm kiếm</el-button>
        <el-button
          type="success"
          icon="el-icon-circle-plus-outline"
          @click="AddOrEditPlayer('add')"
          >Thêm Note</el-button
        >
      </el-col>
    </el-row>

    <!-- Tên mode -->
    <el-row :gutter="20" class="name-mdoe">
      <el-col :span="18" :offset="0">
        <label>Note</label>
      </el-col>
    </el-row>

    <!-- Danh sách player -->
    <el-table
      ref="tableListPlayer"
      :data="dataListPlayer"
      height="70vh"
      style="width: 100%"
      header-row-class-name="header-row-class-name"
      border
      stripe
      highlight-current-row
      size="small"
      empty-text="Không có dữ liệu"
      @current-change="handleCurrentChange"
    >
      <el-table-column label="NO" width="50">
        <template slot-scope="scope">
          <span>{{ scope.$index + 1 }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="typeDomain" label="Domain" sortable>
        <template slot-scope="scope">
          <span>{{ scope.row.typeDomain }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="memberId" label="Tên tài khoản" sortable>
        <template slot-scope="scope">
          <span>{{ scope.row.memberId }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="superId" label="Đại lý" sortable>
        <template slot-scope="scope">
          <span>{{ scope.row.superId }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="cheatMode" label="Gian Lận" sortable>
        <template slot-scope="scope">
          <span style="hidden">{{ checkCheatMode(scope.row.cheatMode) }}</span>
        </template>
      </el-table-column>

      <el-table-column
        prop="inputDate"
        label="Ngày bắt"
        v-if="isShowDate"
        sortable
      >
        <template slot-scope="scope">
          <span>{{
            $moment(scope.row.inputDate).format($root.formatDate)
          }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="note" label="Ghi chú" sortable>
        <template slot-scope="scope">
          <span>{{ scope.row.note }}</span>
        </template>
      </el-table-column>

      <el-table-column>
        <template slot-scope="scope">
          <div class="cell-btn">
            <el-button
              title="Chỉnh sửa Player"
              type="success"
              icon="el-icon-edit-outline"
              circle
              :disabled="
                !(
                  currentRow &&
                  currentRow.loginId === scope.row.loginId &&
                  currentRow.typeDomain === scope.row.typeDomain
                )
              "
              @click="AddOrEditPlayer('edit')"
            ></el-button>
            <el-button
              title="Xóa Player"
              type="danger"
              icon="el-icon-delete"
              circle
              :disabled="
                !(
                  currentRow &&
                  currentRow.loginId === scope.row.loginId &&
                  currentRow.typeDomain === scope.row.typeDomain
                )
              "
              @click="deletePlayer()"
            ></el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <AddOrEditPlayer
      :dialog-visible="visibleAddOrEditPlayer"
      :data-row="dataRow"
      :mode="{ mode: mode, modeNm: modeNm }"
      :type="modeAddOrEditPlayer"
      @closeDialog="closeAddOrEditPlayer"
      @addPlayer="addPlayer"
      @editPlayer="editPlayer"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AddOrEditPlayer from "./AddOrEditNote.vue";
import Header from "../../Common/Header.vue";
import ApiService from "../../../service/api.service";

export default {
  name: "XemNhanhNote",
  components: {
    AddOrEditPlayer,
    Header,
  },
  data() {
    return {
      title: "Note vi phạm",
      backTo: "xem-nhanh",
      mode: "",
      modeNm: "",
      modeOptions: [
        {
          codeKey: "",
          codeName: "",
        },
      ],
      domain: "",
      domainOptions: [
        {
          codeKey: "00",
          codeName: "Tất cả",
        },
      ],
      userName: "",
      dataListPlayer: [],
      currentRow: "",
      visibleAddOrEditPlayer: false,
      dataRow: "",
      modeAddOrEditPlayer: "",
      isShowDate: true,
      cheatMode: 0,
    };
  },
  computed: {
    ...mapState("Player", []),
  },
  beforeCreate() {
    console.log("I have not been created yet!");
  },
  created() {
    console.log("I have just been created!");
  },
  beforeMount() {
    console.log("I am about to be mounted to the DOM!");
  },
  mounted() {
    console.log("I am mounted to the DOM!");
    this.mode = this.mode ? this.mode : "4";
    this.getModeOptions();
    this.getDomainOptions();
    this.getDataListPlayer();
  },
  beforeDestroy() {
    console.log("I am about to be removed from the DOM!");
  },
  destroyed() {
    console.log("I no longer exist...");
  },
  methods: {
    ...mapActions("Player", ["setModeOptions", "setDomainOptions"]),
    errorApi(error) {
      console.log(error);
      let message = "";
      let code = "";
      if (error.response) {
        message = error.response.data.message;
        code = error.response.data.code;
      } else {
        message = error.message;
      }
      this.$alert(message, `Lỗi ${code}`, {
        confirmButtonText: "OK",
        confirmButtonClass: "btn-success",
        type: "error",
      });
    },
    getModeOptions() {
      let url = `${this.$root.url}/master/getListMasterCodeByClass`;
      let _this = this;
      ApiService.get(url, { codeClass: "00003" })
        .then((response) => {
          _this.modeOptions = [];
          response.data.forEach((item) => {
            _this.modeOptions.push({
              codeKey: item.codeKey,
              codeName: item.codeKey === "0" ? " " : item.codeName,
            });
          });
          _this.modeNm = _this.modeOptions.find(
            (item) => item.codeKey === this.mode
          ).codeName;
          _this.setModeOptions(_this.modeOptions);
        })
        .catch((error) => {
          _this.errorApi(error);
        });
    },
    getDomainOptions() {
      let url = `${this.$root.url}/master/getListMasterCodeByClass`;
      let _this = this;
      ApiService.get(url, { codeClass: "00002" })
        .then((response) => {
          response.data.forEach((item) => {
            _this.domainOptions.push({
              codeKey: item.codeKey,
              codeName: item.codeName,
            });
          });
          _this.setDomainOptions(_this.domainOptions);
        })
        .catch((error) => {
          _this.errorApi(error);
        });
    },
    getDataListPlayer() {
      this.$root.fullScreenLoading = true;
      let url = `${this.$root.url}/crawldata/note/getNoteHistory`;
      let _this = this;
      let params = {
        mode: this.cheatMode,
        typeDomain: this.domain,
        userName: this.userName,
      };
      ApiService.get(url, params)
        .then((response) => {
          this.$root.fullScreenLoading = false;
          _this.dataListPlayer = response.data.map((item) => {
            return item;
          });
          setTimeout(() => {
            _this.$refs.tableListPlayer.doLayout();
          }, 100);
        })
        .catch((error) => {
          this.$root.fullScreenLoading = false;
          _this.errorApi(error);
        });
    },
    addPlayer(val) {
      let param = this.setData(val);
      let url = `${this.$root.url}/crawldata/note/insert`;
      let _this = this;
      ApiService.put(url, param)
        .then((response) => {
          console.log(response);
          _this.getDataListPlayer();
        })
        .catch((error) => {
          _this.errorApi(error);
        });
    },
    editPlayer(val) {
      let param = this.setData(val);
      let url = `${this.$root.url}/crawldata/note/update`;
      let _this = this;
      ApiService.put(url, param)
        .then((response) => {
          console.log(response);
          _this.getDataListPlayer();
        })
        .catch((error) => {
          _this.errorApi(error);
        });
    },
    deletePlayer() {
      this.$confirm(`Bạn có chắc muốn xóa Player?`, `Xác nhận`, {
        confirmButtonText: "Đồng Ý",
        cancelButtonText: "Hủy",
        confirmButtonClass: "btn-success",
        cancelButtonClass: "btn-success-plain",
      })
        .then(() => {
          let param = this.setData(this.currentRow);
          let url = `${this.$root.url}/crawldata/note/delete`;
          let _this = this;
          ApiService.delete(url, param)
            .then((response) => {
              console.log(response);
              _this.getDataListPlayer();
            })
            .catch((error) => {
              _this.errorApi(error);
            });
        })
        .catch((response) => {
          console.log(response);
        });
    },
    setData(val) {
      let row = {};
      row.id = val.id;
      row.cheatMode = val.cheatMode;
      row.note = val.note;
      row.typeDomain = val.typeDomain;
      row.superId = val.superId;
      row.memberId = val.memberId;
      row.inputDate = new Date(val.inputDate);
      return row;
    },
    goBack() {
      this.$router.push("menu");
    },
    search() {
      this.getDataListPlayer();
    },
    handleCurrentChange(currentRow) {
      this.currentRow = currentRow;
    },
    AddOrEditPlayer(val) {
      this.visibleAddOrEditPlayer = true;
      this.dataRow =
        val === "add" ? "" : JSON.parse(JSON.stringify(this.currentRow));
      this.modeAddOrEditPlayer = val;
    },
    closeAddOrEditPlayer() {
      this.visibleAddOrEditPlayer = false;
      this.dataRow = "";
      this.modeAddOrEditPlayer = "";
    },
    checkCheatMode(mode) {
      let cheatMode = "";
      switch (mode) {
        case 1:
          cheatMode = "Buôn Com";
          break;
        case 2:
          cheatMode = "Cược Theo";
          break;
        case 3:
          cheatMode = "Sập giá";
          break;
        case 4:
          cheatMode = "Cược Rủi Ro";
          break;
        case 5:
          cheatMode = "Cách chơi đặc biệt";
          break;
        case 6:
          cheatMode = "Buôn Bóng";
          break;
        case 7:
          cheatMode = "Khác";
          break;
        default:
          cheatMode = "";
      }
      return cheatMode;
    },
  },
};
</script>

<style lang="scss">
.list-player {
  .title-select {
    margin-right: 1vw;
    white-space: nowrap;
  }

  .conditions-search {
    margin-bottom: 1vh;

    .el-col {
      display: flex;
      align-items: center;

      .el-select {
        width: 100%;
      }

      .el-input {
        width: 90%;
      }
    }
  }

  .name-mdoe {
    height: 5.6vh;
    background-color: #0e9cc0;
    color: white;
    font-size: 18px;

    [class*="el-col-"] {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  .header-row-class-name {
    th {
      background-color: #f56c6c;
      color: black;
    }
  }

  .el-table {
    .cell {
      word-break: break-word;
    }
  }

  .el-table__body {
    tr {
      &.current-row {
        td {
          background-color: #96dcee !important;
        }
      }
      &:hover > td {
        background-color: #96dcee !important;
      }
    }
  }

  .cell-btn {
    display: flex;
  }

  .add-player-btn {
    width: 100% !important;
    padding: 12px 5px !important;
  }
}

.btn-success {
  color: #fff !important;
  background-color: #0e9cc0 !important;
  border-color: #0e9cc0 !important;

  &:hover {
    color: #fff !important;
    background: #85ce61 !important;
    border-color: #85ce61 !important;
  }
}

.btn-success-plain {
  color: #0e9cc0 !important;
  background: #f0f9eb !important;
  border-color: #c2e7b0 !important;

  &:hover {
    color: #fff !important;
    background: #85ce61 !important;
    border-color: #85ce61 !important;
  }
}
</style>
