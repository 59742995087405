<template>
  <div class="pagination">
    <el-pagination
      background
      @size-change="sizeChangeTable"
      @current-change="currentPageChangeTable"
      :current-page="pageTable"
      :page-sizes="pageSizesTable"
      :page-size="pageSizeTable"
      :layout="layout"
      :total="totalTable"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    pageTable: {
      default: 1,
    },
    pageSizeTable: {
      default: 5,
    },
    totalTable: {
      default: 0,
    },
    pageSizesTable: {
      default: [5, 20, 50, 100],
    },
  },
  data() {
    return {
      layout: "total, sizes, prev, pager, next",
    };
  },
  computed: {},
  beforeCreate() {
    console.log("I have not been created yet!");
  },
  created() {
    console.log("I have just been created!");
  },
  beforeMount() {
    console.log("I am about to be mounted to the DOM!");
  },
  mounted() {
    console.log("I am mounted to the DOM!");
  },
  beforeDestroy() {
    console.log("I am about to be removed from the DOM!");
  },
  destroyed() {
    console.log("I no longer exist...");
  },
  methods: {
    sizeChangeTable(val) {
      this.$emit("sizeChangeTable", val);
    },
    currentPageChangeTable(val) {
      this.$emit("currentPageChangeTable", val);
    },
  },
};
</script>
<style lang="scss">
.pagination {
  text-align: right;
  margin-top: 1vh;

  .el-pagination button,
  .el-pagination span:not([class*="suffix"]) {
    font-size: 14px;
  }
}
</style>
