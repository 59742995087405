<template>
  <div id="thong-ke-theo-member" class="thong-ke-theo-member">
    <Header :title="title" />

    <!-- Điều kiện tìm kiếm -->
    <el-row :gutter="20" class="conditions-search">
      <el-col :span="3" :offset="1">
        <el-select
          v-model="report"
          value-key="codeKey"
          placeholder="Chọn kiểu Report"
        >
          <el-option
            v-for="item in reportOptions"
            :key="item.codeKey"
            :label="item.codeName"
            :value="item.codeKey"
            no-data-text="Không có dữ liệu"
          >
          </el-option>
        </el-select>
      </el-col>

      <el-col :span="4" v-if="report === '1'">
        <el-row class="checkbox-report-choice">
          <el-col :span="12">
            <el-checkbox v-model="nSportChecked">N-Sport</el-checkbox>
          </el-col>
          <el-col :span="12">
            <el-checkbox v-model="vSportChecked">V-Sport</el-checkbox>
          </el-col>
        </el-row>
      </el-col>

      <el-col :span="4">
        <el-input
          v-model="memberId"
          placeholder="Nhập User ID"
          clearable
        ></el-input>
      </el-col>

      <el-col :span="6">
        <div class="block">
          <el-date-picker
            v-model="dateFromTo"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="Tới"
            start-placeholder="Data Start"
            end-placeholder="Date End"
            :format="
              $root.formatDate.replace('YYYY', 'yyyy').replace('DD', 'dd')
            "
            :default-value="[
              $moment().subtract(1, 'months').format('YYYY/MM/DD'),
              $moment().format('YYYY/MM/DD'),
            ]"
            :picker-options="setPickerOptions()"
            @change="changeDateFromTo()"
          >
          </el-date-picker>
        </div>
      </el-col>
      <el-col :span="6" :offset="0">
        <el-button
          type="success"
          @click="search()"
          :disabled="isDisabledBtnSearch()"
          >Tìm kiếm</el-button
        >
      </el-col>
    </el-row>

    <!-- Text mô tả -->
    <el-row :gutter="20" class="name-mdoe">
      <el-col :span="24" :offset="0">
        <label> Kết quả được tính tới thời điểm: {{ timeUpdate }} </label>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="breadcrumb">
      <el-col :span="24" :offset="0">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>{{ domainName }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ superId }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>

    <!-- Table -->
    <el-row :gutter="20">
      <ReportByProducts
        v-show="reportTable === '0'"
        :isClickSearch.sync="isClickSearch"
        :domain="domain"
        :report="report"
        :nSportChecked="nSportChecked"
        :vSportChecked="vSportChecked"
        :memberId="memberId"
        :superId="superId"
        :dateFromTo="dateFromTo"
        :level="2"
      ></ReportByProducts>
      <ReportByChoice
        v-show="reportTable === '1'"
        :isClickSearch.sync="isClickSearch"
        :domain="domain"
        :report="report"
        :nSportChecked="nSportChecked"
        :vSportChecked="vSportChecked"
        :memberId="memberId"
        :superId="superId"
        :dateFromTo="dateFromTo"
        :level="2"
      ></ReportByChoice>
    </el-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Header from "../Common/Header.vue";
import ReportByProducts from "./componentsReport/ReportByProducts.vue";
import ReportByChoice from "./componentsReport/ReportByChoice.vue";

export default {
  name: "ThongKeTheoMember",
  components: {
    ReportByProducts,
    ReportByChoice,
    Header,
  },
  data() {
    return {
      title: "Thống kê gian lận",
      isClickSearch: false,
      modeSystem: 0,
      domain: "",
      domainName: "",
      report: "",
      reportOptions: [
        {
          codeKey: "0",
          codeName: "Report By Products",
        },
        {
          codeKey: "1",
          codeName: "Report By Choice",
        },
      ],
      nSportChecked: true,
      vSportChecked: true,
      memberId: "",
      superId: "",
      dateFromTo: [
        this.$moment().startOf("weeks").format(this.$root.formatDate),
        this.$moment().format(this.$root.formatDate),
      ],
    };
  },
  computed: {
    ...mapState("XemTheoWinLoss", ["reportTable", "timeUpdate"]),
    ...mapState("ThongKeTheoMember", []),
  },
  beforeCreate() {
    console.log("I have not been created yet!");
  },
  created() {
    console.log("I have just been created!");
  },
  beforeMount() {
    console.log("I am about to be mounted to the DOM!");
  },
  mounted() {
    console.log("I am mounted to the DOM!");
    let param = JSON.parse(localStorage.getItem("PARAM_FOR_LEVEL_2"));
    this.superId = param.superId;
    this.domain = param.domain;
    this.domainName = param.domainName;
    this.report = param.report;
    this.dateFromTo = param.dateFromTo;
    this.isClickSearch = true;
  },
  beforeDestroy() {
    console.log("I am about to be removed from the DOM!");
  },
  destroyed() {
    console.log("I no longer exist...");
  },
  methods: {
    ...mapActions("ThongKeTheoMember", [""]),
    errorApi(error) {
      console.log(error);
      let message = "";
      let code = "";
      if (error.response) {
        message = error.response.data.message;
        code = error.response.data.code;
      } else {
        message = error.message;
      }
      this.$alert(message, `Lỗi ${code}`, {
        confirmButtonText: "OK",
        confirmButtonClass: "btn-success",
        type: "error",
      });
    },
    setPickerOptions() {
      let _this = this;
      let pickerOptions = {
        disabledDate(time) {
          let twoMonthsAgo = _this.$moment().subtract(2, "months");
          let currentMonth = _this.$moment();
          let timeFormat = _this.$moment(time);
          let returnValue = !(
            _this.$moment(timeFormat).isAfter(twoMonthsAgo) &&
            _this.$moment(timeFormat).isBefore(currentMonth)
          );
          return returnValue;
        },
        shortcuts: [
          {
            text: "Hôm nay",
            onClick(picker) {
              const end = _this.$moment().format(_this.$root.formatDate);
              const start = _this.$moment().format(_this.$root.formatDate);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Hôm qua",
            onClick(picker) {
              const end = _this
                .$moment()
                .subtract(1, "days")
                .format(_this.$root.formatDate);
              const start = _this
                .$moment()
                .subtract(1, "days")
                .format(_this.$root.formatDate);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Tuần này",
            onClick(picker) {
              const end = _this.$moment().format(_this.$root.formatDate);
              const start = _this
                .$moment()
                .startOf("weeks")
                .format(_this.$root.formatDate);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Tuần trước",
            onClick(picker) {
              const end = _this
                .$moment()
                .subtract(1, "weeks")
                .endOf("weeks")
                .format(_this.$root.formatDate);
              const start = _this
                .$moment()
                .subtract(1, "weeks")
                .startOf("weeks")
                .format(_this.$root.formatDate);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Tháng này",
            onClick(picker) {
              const end = _this.$moment().format(_this.$root.formatDate);
              const start = _this
                .$moment()
                .startOf("months")
                .format(_this.$root.formatDate);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Tháng trước",
            onClick(picker) {
              const end = _this
                .$moment()
                .subtract(1, "months")
                .endOf("months")
                .format(_this.$root.formatDate);
              const start = _this
                .$moment()
                .subtract(1, "months")
                .startOf("months")
                .format(_this.$root.formatDate);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      };
      return pickerOptions;
    },
    search() {
      this.isClickSearch = true;
    },
    goBack() {
      this.$router.push("xem-theo-win-loss");
    },
    isDisabledBtnSearch() {
      if (this.report === "1" && !this.nSportChecked && !this.vSportChecked) {
        return true;
      }
      if (this.report === "2" && !this.memberId) {
        return true;
      }
      if (!this.superId) {
        return true;
      }
      if (!this.dateFromTo || this.dateFromTo.length === 0) {
        return true;
      }
      return false;
    },
    changeDateFromTo() {
      console.log(this.dateFromTo);
    },
  },
};
</script>

<style lang="scss">
.thong-ke-theo-member {
  .title-select {
    margin-right: 1vw;
  }

  .conditions-search {
    margin-bottom: 1vh;

    &.el-row {
      display: flex;
    }

    .el-date-editor {
      width: 100%;
    }
  }

  .checkbox-report-choice {
    height: 100%;

    [class*="el-col-"] {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  .name-mdoe {
    height: 5.6vh;
    background-color: #0e9cc0;
    color: white;
    font-size: 18px;

    [class*="el-col-"] {
      display: flex;
      align-items: center;
      height: 100%;

      &:last-child {
        justify-content: flex-end;
      }
    }
  }

  .breadcrumb {
    height: 5.6vh;
    color: black;
    font-size: 18px;

    [class*="el-col-"] {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  .header-row-class-name {
    &:first-child {
      th {
        background-color: #d96767 !important;
        color: black !important;
        height: 3vh !important;
      }
    }

    // &:nth-child(2) {
    //   th {
    //     &:nth-child(4) {
    //       background-color: #e62a2a !important;
    //       color: black !important;
    //       height: 3vh !important;
    //     }
    //   }
    // }

    th {
      background-color: #eac9b8 !important;
      color: black !important;
      height: 3vh !important;

      .no-sort {
        .el-icon-caret-top,
        .el-icon-caret-bottom {
          opacity: 0.2;
        }
      }

      .descending {
        .el-icon-caret-top {
          opacity: 0.2;
        }
      }

      .ascending {
        .el-icon-caret-bottom {
          opacity: 0.2;
        }
      }

      .header-sort {
        display: flex;
        justify-content: center;

        .icon-sort {
          padding-left: 3px;
          position: relative;

          .el-icon-caret-top {
            position: absolute;
            top: 0;
          }

          .el-icon-caret-bottom {
            position: absolute;
            bottom: 0;
          }
        }
      }
    }
  }

  .el-table {
    thead.is-group th {
      padding: 3px !important;
    }

    th .cell {
      //white-space: nowrap;
      white-space: pre-wrap;
      word-break: break-word;
    }

    td .cell {
      word-break: break-word;
    }
  }

  .el-table__body {
    tr {
      &.current-row {
        td {
          background-color: #96dcee !important;
        }
      }
      &:hover > td {
        background-color: #96dcee !important;
      }

      td {
        .cell-label-user-name {
          font-weight: bold;
          font-size: 14px;

          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.btn-success {
  color: #fff !important;
  background-color: #0e9cc0 !important;
  border-color: #0e9cc0 !important;

  &:hover {
    color: #fff !important;
    background: #85ce61 !important;
    border-color: #85ce61 !important;
  }
}

.btn-success-plain {
  color: #0e9cc0 !important;
  background: #f0f9eb !important;
  border-color: #c2e7b0 !important;

  &:hover {
    color: #fff !important;
    background: #85ce61 !important;
    border-color: #85ce61 !important;
  }
}
</style>
