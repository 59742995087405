const player = {
  namespaced: true,
  state: {
    isShowCuocTheo: true,
    isShowBuonCom: true,
    setShowTrungIp: true,
  },
  mutations: {
    setShowCuocTheo(state, value) {
      state.isShowCuocTheo = value;
    },
    setShowBuonCom(state, value) {
      state.isShowBuonCom = value;
    },
    setShowTrungIp(state, value) {
      state.isShowTrungIp = value;
    },
  },
  actions: {
    setShowCuocTheo(context, value) {
      context.commit("setShowCuocTheo", value);
    },
    setShowBuonCom(context, value) {
      context.commit("setShowBuonCom", value);
    },
    setShowTrungIp(context, value) {
      context.commit("setShowTrungIp", value);
    },
  },
  modules: {},
};

export default player;
