<template>
  <div id="report" class="report">
    <Header :title="title" />

    <!-- Điều kiện tìm kiếm -->
    <el-row :gutter="20" class="conditions-search">
      <el-col :span="18" :offset="1">
        <el-checkbox v-model="trungIp"> Trùng IP </el-checkbox>
        <el-checkbox v-model="cuocTheo"> Cược Theo </el-checkbox>
        <el-checkbox v-model="buonCom"> Buôn Com </el-checkbox>
        <el-checkbox v-model="sapgia"> Sập Giá </el-checkbox>
        <el-checkbox v-model="cuocXau"> Cược Xấu </el-checkbox>
        <el-checkbox v-model="ruiro"> Rủi Ro </el-checkbox>
        <el-checkbox v-model="dacbiet"> Đặc Biệt </el-checkbox>
        <el-checkbox v-model="buonBong"> Buôn Bóng </el-checkbox>
        <el-checkbox v-model="trungGiaiCam"> Trùng Giải Cấm </el-checkbox>
        <el-checkbox v-model="giaiCam"> Giải Cấm </el-checkbox>
      </el-col>
      <el-col :span="1" class="thong-ke tai"> Tài </el-col>
      <el-col :span="1" class="thong-ke tai"> {{ memberInfo.over }} </el-col>
      <el-col :span="1" class="thong-ke tai"> {{ tai }}% </el-col>
      <el-col :span="1" class="thong-ke xiu"> Xỉu</el-col>
      <el-col :span="1" class="thong-ke xiu"> {{ memberInfo.under }}</el-col>
      <el-col :span="1" class="thong-ke xiu"> {{ xiu }}% </el-col>
    </el-row>

    <el-row :gutter="20" class="conditions-search">
      <el-col :span="18" :offset="1">
        <span>Thời gian khác:</span>
        <div class="input-with-text">
          <el-input
            v-model.number="milisFrom"
            type="number"
            class="another-time-from"
          >
            <template slot="prepend">Từ</template>
          </el-input>
          <el-input
            v-model.number="milisTo"
            type="number"
            class="another-time-to"
          >
            <template slot="prepend">Tới</template>
          </el-input>
          <el-select
            v-model="timeRange"
            @change="changeTimeRangeOptions()"
            class="another-time-range"
          >
            <el-option
              v-for="item in timeRangeOptions"
              :key="item.codeKey"
              :label="item.codeName"
              :value="item.codeKey"
              no-data-text="Không có dữ liệu"
            >
            </el-option>
          </el-select>
        </div>
        <el-checkbox v-show="!isShowBuonCom" v-model="normalSport"
          >Bóng Chính</el-checkbox
        >
        <el-checkbox v-show="!isShowBuonCom" v-model="virtualSport"
          >Bóng Ảo</el-checkbox
        >
        <el-checkbox v-show="isShowBuonCom" v-model="beforeMatch"
          >Trước Trận</el-checkbox
        >
        <el-checkbox v-show="isShowBuonCom" v-model="inMatch"
          >Trong Trận</el-checkbox
        >
      </el-col>
      <el-col :span="1" class="thong-ke tren"> Trên</el-col>
      <el-col :span="1" class="thong-ke tren">
        {{ memberInfo.overTeam }}</el-col
      >
      <el-col :span="1" class="thong-ke tren"> {{ tren }}% </el-col>
      <el-col :span="1" class="thong-ke duoi"> Dưới </el-col>
      <el-col :span="1" class="thong-ke duoi">
        {{ memberInfo.underTeam }}
      </el-col>
      <el-col :span="1" class="thong-ke duoi"> {{ duoi }}%</el-col>
    </el-row>

    <el-row :gutter="20" class="conditions-search">
      <el-col :span="6" :offset="1">
        <div class="block">
          <el-date-picker
            v-model="dateFromTo"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="Tới"
            start-placeholder="Data Start"
            end-placeholder="Date End"
            :format="
              $root.formatDate.replace('YYYY', 'yyyy').replace('DD', 'dd')
            "
            :default-value="[
              $moment().subtract(1, 'months').format('YYYY/MM/DD'),
              $moment().format('YYYY/MM/DD'),
            ]"
            :picker-options="setPickerOptions()"
            @change="changeDateFromTo()"
          >
          </el-date-picker>
        </div>
      </el-col>
      <el-col :span="6" :offset="0">
        <el-button type="success" @click="submit()">Submit</el-button>
        <el-button type="success" @click="print()">Xuất Hình</el-button>
      </el-col>
      <el-col :span="6"> </el-col>
      <el-col :span="1" class="thong-ke goc"> Góc </el-col>
      <el-col :span="1" class="thong-ke goc"> {{ memberInfo.corner }} </el-col>
      <el-col :span="1" class="thong-ke goc"> {{ goc }}% </el-col>
      <el-col :span="2" class="thong-ke total"> Tổng mã cược </el-col>
      <el-col :span="1" class="thong-ke total"> {{ totalBet }} </el-col>
    </el-row>
    <el-row :gutter="20" class="conditions-search">
      <el-col :span="18" :offset="1"> </el-col>
      <el-col :span="1" class="thong-ke ao"> Bóng ảo </el-col>
      <el-col :span="1" class="thong-ke ao"> {{ virtual }}% </el-col>
      <el-col :span="1" class="thong-ke casino"> Casino </el-col>
      <el-col :span="1" class="thong-ke casino"> {{ casino }}% </el-col>
      <el-col :span="1" class="thong-ke other"> Khác </el-col>
      <el-col :span="1" class="thong-ke other"> {{ other }}% </el-col>
    </el-row>

    <el-row :gutter="20" class="conditions-search">
      <el-col :span="14" :offset="1">
        <el-checkbox v-model="anDong" @change="changeAnDong()">
          Ẩn Dòng
        </el-checkbox>
        <el-checkbox v-model="anIp" @change="changeAnIp()"> Ẩn IP </el-checkbox>
        <el-checkbox v-model="anUsername" @change="changeAnUsername()">
          Ẩn Username
        </el-checkbox>
        <el-checkbox v-model="anStake" @change="changeAnStake()">
          Ẩn điểm cược
        </el-checkbox>
        <el-checkbox v-model="anDoiKeo" @change="changeDoiKeo()">
          Ẩn Đổi Kèo/Giá
        </el-checkbox>
        <el-checkbox
          v-model="demTheoUsername"
          @change="changeDemTheoUsername()"
        >
          Đếm theo Username
        </el-checkbox>
        <el-checkbox v-model="thayDoiGio" @change="changeThayDoiGio()">
          Thay đổi giờ (VN/ {{ domainName }} )
        </el-checkbox>
        <el-checkbox v-model="anSoDem" @change="changeAnSoDem()">
          Ẩn số đếm
        </el-checkbox>
      </el-col>
      <el-col :span="10">
        <el-input-number
          class="input-count"
          v-model="count"
          controls-position="right"
          :min="1"
        ></el-input-number>
        <el-button type="success" @click="changeCount()">Ok</el-button>
      </el-col>
    </el-row>

    <div id="reportBody" ref="reportBody">
      <!-- báo cáo phân tích -->
      <el-row type="flex" :gutter="20" class="analysis-report">
        <el-col :span="4" :offset="0" class="img-analysis-report">
          <img alt="EASY24" src="../../assets/img/logo.svg" width="100%" />
        </el-col>

        <el-col :span="10" :offset="0" class="group-analysis-report-1">
          <el-row :gutter="20" class="row-1">
            <el-col :span="24" :offset="0">
              <span class="label-bao-cao">BÁO CÁO PHÂN TÍCH</span>
            </el-col>
          </el-row>

          <el-row
            type="flex"
            justify="center"
            align="center"
            :gutter="20"
            class="row-2"
          >
            <el-col :span="4" :offset="0" class="title">
              <span>Mã quản lý:</span>
            </el-col>
            <el-col :span="12" :offset="0" class="body">
              <el-input
                v-if="isEditMaQuanLy"
                v-model="maQuanLyValue"
                size="small"
                clearable
              ></el-input>
              <span v-if="!isEditMaQuanLy">{{ maQuanLyValue }}</span>
              <el-button
                type="success"
                @click="changeModeEditMaQuanLy()"
                size="small"
                class="btn-edit"
              >
                {{ isEditMaQuanLy ? "SAVE" : "EDIT" }}
              </el-button>
            </el-col>

            <el-col :span="3" :offset="0" class="title">
              <span>Turnover:</span>
            </el-col>
            <el-col :span="5" :offset="0" class="body">
              <span>{{ turnoverValue }}</span>
            </el-col>
          </el-row>

          <el-row
            type="flex"
            justify="center"
            align="center"
            :gutter="20"
            class="row-3"
          >
            <el-col :span="4" :offset="0" class="title">
              <span>Tài Khoản:</span>
            </el-col>
            <el-col :span="12" :offset="0" class="body">
              <span>{{ taiKhoanValue }}</span>
            </el-col>

            <el-col :span="3" :offset="0" class="title">
              <span>Win/Loss:</span>
            </el-col>
            <el-col :span="5" :offset="0" class="body">
              <span>{{ winLossValue }}</span>
            </el-col>
          </el-row>

          <el-row
            type="flex"
            justify="center"
            align="center"
            :gutter="20"
            class="row-4"
          >
            <el-col :span="4" :offset="0" class="title">
              <span>Ngày:</span>
            </el-col>
            <el-col :span="12" :offset="0" class="body">
              <span>{{ dateReportValue }}</span>
            </el-col>

            <el-col :span="3" :offset="0" class="title">
              <span>Com:</span>
            </el-col>
            <el-col :span="5" :offset="0" class="body">
              <span>{{ comValue }}</span>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="6" :offset="0" class="group-analysis-report-2">
          <el-row :gutter="20" class="row-1">
            <el-col :span="24" :offset="0" class="phan-tich">
              <span>Phân Tích:</span>
              <el-input
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 3 }"
                :resize="'none'"
                placeholder="........."
                v-model="phanTichValue"
              >
              </el-input>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row-2">
            <el-col :span="24" :offset="0" class="ket-luan">
              <span>Kết Luận:</span>
              <br />
              <el-input
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 3 }"
                :resize="'none'"
                placeholder="........."
                v-model="ketLuanValue"
                :disabled="!isEditKetMuan"
              >
              </el-input>
              <el-button
                type="success"
                @click="changeModeEditKetMuan()"
                size="small"
                class="btn-edit"
              >
                {{ isEditKetMuan ? "SAVE" : "EDIT" }}
              </el-button>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="4" :offset="0" class="img-analysis-report">
          <img alt="EASY24" src="../../assets/img/ez24QR.png" width="90%" />
        </el-col>
      </el-row>

      <!-- table trung ip -->
      <el-row
        :gutter="20"
        v-show="dataTrungIp.length > 0"
        class="table-trung-ip"
      >
        <el-col :span="24" :offset="0" class="title-table">
          <span>TRÙNG IP</span>
        </el-col>
        <el-col :span="24" :offset="0">
          <TrungIp
            :dataTrungIp="dataTrungIp"
            :anDong="anDong"
            :anIp="anIp"
            :anUsername="anUsername"
            :anStake="anStake"
            :demTheoUsername="demTheoUsername"
            :thayDoiGio="thayDoiGio"
            :anSoDem="anSoDem"
            :count="countParam"
          ></TrungIp>
        </el-col>
      </el-row>

      <!-- table cuoc theo -->
      <el-row
        :gutter="20"
        v-show="dataCuocTheo.length > 0 && isShowCuocTheo"
        class="table-cuoc-theo"
      >
        <el-col :span="24" :offset="0" class="title-table">
          <span>CƯỢC THEO</span>
        </el-col>
        <el-col :span="24" :offset="0">
          <CuocTheo
            :dataCuocTheo="dataCuocTheo"
            :anDong="anDong"
            :anIp="anIp"
            :anUsername="anUsername"
            :anStake="anStake"
            :demTheoUsername="demTheoUsername"
            :thayDoiGio="thayDoiGio"
            :anSoDem="anSoDem"
            :count="countParam"
            :anDoiKeo="anDoiKeo"
          ></CuocTheo>
        </el-col>
      </el-row>

      <!-- table buon com -->
      <el-row
        :gutter="20"
        v-show="dataBuonCom.length > 0 && isShowBuonCom"
        class="table-buon-com"
      >
        <el-col :span="24" :offset="0" class="title-table">
          <span>BUÔN COM</span>
        </el-col>
        <el-col :span="24" :offset="0">
          <BuonCom
            :dataBuonCom="dataBuonCom"
            :anDong="anDong"
            :anIp="anIp"
            :anUsername="anUsername"
            :anStake="anStake"
            :demTheoUsername="demTheoUsername"
            :thayDoiGio="thayDoiGio"
            :anSoDem="anSoDem"
            :count="count"
            :anDoiKeo="anDoiKeo"
          ></BuonCom>
        </el-col>
      </el-row>

      <!-- table sap gia -->
      <el-row :gutter="20" v-show="dataSapGia.length > 0" class="table-sap-gia">
        <el-col :span="24" :offset="0" class="title-table">
          <span>SẬP GIÁ</span>
        </el-col>
        <el-col :span="24" :offset="0">
          <SapGia
            :dataSapGia="dataSapGia"
            :anDong="anDong"
            :anIp="anIp"
            :anUsername="anUsername"
            :anStake="anStake"
            :demTheoUsername="demTheoUsername"
            :thayDoiGio="thayDoiGio"
            :anSoDem="anSoDem"
            :count="countParam"
          ></SapGia>
        </el-col>
      </el-row>

      <!-- table xiu ban -->
      <el-row :gutter="20" v-show="dataXiuBan.length > 0" class="table-xiu-ban">
        <el-col :span="24" :offset="0" class="title-table">
          <span>CƯỢC XẤU</span>
        </el-col>
        <el-col :span="24" :offset="0">
          <XiuBan
            :dataXiuBan="dataXiuBan"
            :anDong="anDong"
            :anIp="anIp"
            :anUsername="anUsername"
            :anStake="anStake"
            :demTheoUsername="demTheoUsername"
            :thayDoiGio="thayDoiGio"
            :anSoDem="anSoDem"
            :count="countParam"
          ></XiuBan>
        </el-col>
      </el-row>
      <el-row :gutter="20" v-show="dataRuiRo.length > 0" class="table-rui-ro">
        <el-col :span="24" :offset="0" class="title-table">
          <span>CƯỢC RỦI RO</span>
        </el-col>
        <el-col :span="24" :offset="0">
          <RuiRo
            :dataRuiRo="dataRuiRo"
            :anDong="anDong"
            :anIp="anIp"
            :anUsername="anUsername"
            :anStake="anStake"
            :demTheoUsername="demTheoUsername"
            :thayDoiGio="thayDoiGio"
            :count="countParam"
          ></RuiRo>
        </el-col>
      </el-row>
      <el-row
        :gutter="20"
        v-show="dacbiet && dataDacBiet.length > 0"
        class="table-dac-biet"
      >
        <el-col :span="24" :offset="0" class="title-table">
          <span>{{ this.titleDacBiet }}</span>
        </el-col>
        <el-col :span="24" :offset="0">
          <DacBiet
            :dataDacBiet="dataDacBiet"
            :anDong="anDong"
            :anIp="anIp"
            :anUsername="anUsername"
            :anStake="anStake"
            :demTheoUsername="demTheoUsername"
            :thayDoiGio="thayDoiGio"
            :count="countParam"
          ></DacBiet>
        </el-col>
      </el-row>
      <el-row
        :gutter="20"
        v-show="buonBong && dataBuonBong.length > 0"
        class="table-dac-biet"
      >
        <el-col :span="24" :offset="0" class="title-table">
          <span>BUÔN BÓNG</span>
        </el-col>
        <el-col :span="24" :offset="0">
          <BuonBong
            :dataBuonBong="dataBuonBong"
            :anDong="anDong"
            :anIp="anIp"
            :anUsername="anUsername"
            :anStake="anStake"
            :demTheoUsername="demTheoUsername"
            :thayDoiGio="thayDoiGio"
            :anSoDem="anSoDem"
            :count="countParam"
            :anDoiKeo="anDoiKeo"
          ></BuonBong>
        </el-col>
      </el-row>
      <el-row class="doc-quyen">
        <el-col :span="12" :offset="0" class="doc-quyen-left">
          Website: https://scan.zf1.us/
        </el-col>
        <el-col
          :span="12"
          :offset="0"
          class="doc-quyen-right"
          style="color: red"
        >
          Hình ảnh độc quyền bởi EZScan
        </el-col>
      </el-row>
      <hr />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RuiRo from "./ComponentsTable/RuiRo.vue";
import XiuBan from "./ComponentsTable/XinBan.vue";
import BuonCom from "./ComponentsTable/BuonCom.vue";
import CuocTheo from "./ComponentsTable/CuocTheo.vue";
import TrungIp from "./ComponentsTable/TrungIp.vue";
import SapGia from "./ComponentsTable/SapGia.vue";
import DacBiet from "./ComponentsTable/DacBiet.vue";
import BuonBong from "./ComponentsTable/BuonBong.vue";
import Header from "../Common/Header.vue";
import ApiService from "../../service/api.service";

export default {
  name: "Report",
  components: {
    RuiRo,
    XiuBan,
    BuonCom,
    CuocTheo,
    TrungIp,
    Header,
    SapGia,
    DacBiet,
    BuonBong,
  },
  data() {
    return {
      title: "THỐNG KÊ GIAN LẬN",
      domain: "",
      domainName: "",
      trungIp: false,
      trungGiaiCam: true,
      giaiCam: true,
      cuocXau: false,
      sapgia: false,
      ruiro: false,
      dacbiet: false,
      buonBong: false,
      titleDacBiet: "CƯỢC ĐẶC BIỆT",
      milisFrom: "",
      milisTo: "",
      timeRange: "",
      timeRangeOptions: [
        {
          codeKey: "",
          codeName: "Other",
          codeValue: "",
          codeValue2: "",
        },
      ],
      cuocTheo: true,
      buonCom: true,
      dateFromTo: [],
      normalSport: true,
      virtualSport: false,
      beforeMatch: true,
      inMatch: true,
      anDong: false,
      anIp: false,
      anUsername: false,
      anStake: true,
      demTheoUsername: false,
      thayDoiGio: false,
      anSoDem: false,
      count: 1,
      countParam: 1,
      maQuanLyValue: "",
      isEditMaQuanLy: false,
      turnoverValue: "",
      taiKhoanValue: "",
      winLossValue: "",
      dateReportValue: "",
      comValue: "",
      phanTichValue: "",
      ketLuanValue: "",
      isEditKetMuan: false,
      dataCuocTheo: [],
      dataSapGia: [],
      dataBuonCom: [],
      dataXiuBan: [],
      dataTrungIp: [],
      dataRuiRo: [],
      dataDacBiet: [],
      dataBuonBong: [],
      api0: true,
      api1: true,
      api2: true,
      api3: true,
      api4: true,
      api5: true,
      api6: true,
      api7: true,
      isSearchInit: false,
      isFromScreenXemNhanh: false,
      anDoiKeo: false,
      sapGiaTruocTran: false,
      sapGiaBatDau: false,
      sapGiaTrongTran: false,
      typeRuiRo: 0,
      tai: 0,
      xiu: 0,
      tren: 0,
      duoi: 0,
      goc: 0,
      totalBet: 0,
      casino: 0,
      virtual: 0,
      other: 0,
      memberInfo: {},
    };
  },
  computed: {
    ...mapState("Report", ["isShowCuocTheo", "isShowBuonCom"]),
  },
  watch: {
    api0() {
      this.setFullScreenLoading();
    },
    api1() {
      this.setFullScreenLoading();
    },
    api2() {
      this.setFullScreenLoading();
    },
    api3() {
      this.setFullScreenLoading();
    },
    api4() {
      this.setFullScreenLoading();
    },
    api5() {
      this.setFullScreenLoading();
    },
    api6() {
      this.setFullScreenLoading();
    },
    api7() {
      this.setFullScreenLoading();
    },
  },
  beforeCreate() {
    console.log("I have not been created yet!");
  },
  created() {
    console.log("I have just been created!");
  },
  beforeMount() {
    console.log("I am about to be mounted to the DOM!");
  },
  mounted() {
    console.log("I am mounted to the DOM!");
    let param = JSON.parse(localStorage.getItem("PARAM_FOR_LEVEL_3"));
    console.log(param);
    if (param) {
      this.taiKhoanValue = param.memberId;
      this.domain = param.domain;
      this.domainName = param.domainName;
      this.dateFromTo = param.dateFromTo;
      this.normalSport = param.normalSport || true;
      this.virtualSport = param.virtualSport || false;
      this.milisFrom = param.milisFrom;
      this.milisTo = param.milisTo;
      this.timeRange = param.masterCode;
      if ("B6" === param.masterCode) {
        this.beforeMatch = true;
        this.inMatch = false;
      } else if ("B8" === param.masterCode || "B5" === param.masterCode) {
        this.beforeMatch = false;
        this.inMatch = true;
      } else {
        this.beforeMatch = true;
        this.inMatch = true;
      }
      // hnh
      this.trungIp = param.trungIp || false;
      this.buonCom = param.buonCom || false;
      this.cuocTheo = param.cuocTheo || false;
      this.cuocXau = param.cuocXau || false;
      this.giaiCam = param.giaiCam || false;
      this.trungGiaiCam = param.trungGiaiCam || false;
      this.isSearchInit = param.isSearchInit || false; // search
      this.isFromScreenXemNhanh = param.isFromScreenXemNhanh || false;
      this.count = param.filterCount || 1;
      // sap gia
      this.sapgia = param.sapgia || false;
      this.sapGiaTruocTran = param.sapGiaTruocTran || false;
      this.sapGiaBatDau = param.sapGiaBatDau || false;
      this.sapGiaTrongTran = param.sapGiaTrongTran || false;
      // rui ro
      this.ruiro = param.ruiro || false;
      this.typeRuiRo = param.typeRuiRo || 0;
      // dac biet
      this.dacbiet = param.dacbiet || false;
      this.typeDacBiet = param.typeDacBiet || 0;
      this.titleDacBiet = this.setTitleDacBiet(this.typeDacBiet);
      // Buon bong
      this.buonBong = param.buonBong || false;
    }
    this.getTimeRangeOptions();
  },
  beforeDestroy() {
    console.log("I am about to be removed from the DOM!");
  },
  destroyed() {
    console.log("I no longer exist...");
  },
  methods: {
    ...mapActions("Report", []),
    setFullScreenLoading() {
      if (this.isSearchInit && this.api0 && this.api1) {
        this.submit();
        this.isSearchInit = false;
      } else if (
        this.api0 &&
        this.api1 &&
        this.api2 &&
        this.api3 &&
        this.api4 &&
        this.api5 &&
        this.api6 &&
        this.api7
      ) {
        this.$root.fullScreenLoading = false;
      } else {
        this.$root.fullScreenLoading = true;
      }
    },
    errorApi(error) {
      console.log(error);
      let message = "";
      let code = "";
      if (error.response) {
        message = error.response.data.message;
        code = error.response.data.code;
      } else {
        message = error.message;
      }
      this.$alert(message, `Lỗi ${code}`, {
        confirmButtonText: "OK",
        confirmButtonClass: "btn-success",
        type: "error",
      });
    },
    getTimeRangeOptions() {
      this.api7 = false;
      let url = `${this.$root.url}/master/getListMasterCodeByClass`;
      let _this = this;
      let params = { codeClass: "00004" };
      ApiService.get(url, params)
        .then((response) => {
          response.data.forEach((item) => {
            _this.timeRangeOptions.push({
              codeKey: item.codeKey,
              codeName: item.codeName,
              codeValue: item.codeValue,
              codeValue2: item.codeValue2,
            });
          });
          _this.api7 = true;
        })
        .catch((error) => {
          _this.api7 = true;
          _this.errorApi(error);
        });
    },
    getMemberInfo() {
      this.api0 = false;
      let params = this.setParamAPI("getMemberInfo");
      let url = `${this.$root.url}/betscan/getMemberInfo`;
      let _this = this;
      ApiService.get(url, params)
        .then((response) => {
          if (response && response.data) {
            _this.maQuanLyValue = "";
            _this.turnoverValue = response.data.turnOver;
            _this.winLossValue = response.data.winLoss;
            _this.totalBet = response.data.betCount;
            _this.memberInfo = response.data;
            _this.tai = Math.round(
              (response.data.over / _this.totalBet + Number.EPSILON) * 100
            );
            _this.xiu = Math.round(
              (response.data.under / _this.totalBet + Number.EPSILON) * 100
            );
            _this.tren = Math.round(
              (response.data.overTeam / _this.totalBet + Number.EPSILON) * 100
            );
            _this.duoi = Math.round(
              (response.data.underTeam / _this.totalBet + Number.EPSILON) * 100
            );
            _this.goc = Math.round(
              (response.data.corner / _this.totalBet + Number.EPSILON) * 100
            );
            _this.casino = Math.round(
              (response.data.casino / _this.totalBet + Number.EPSILON) * 100
            );
            _this.virtual = Math.round(
              (response.data.virtualSport / _this.totalBet + Number.EPSILON) *
                100
            );
            _this.other = Math.round(
              ((response.data.typeOther + response.data.loto) / _this.totalBet +
                Number.EPSILON) *
                100
            );
            // _this.dateReportValue = response.data.dateReport;
            const dateFrom = _this
              .$moment(_this.dateFromTo[0])
              .format(_this.$root.formatDate);
            const dateTo = _this
              .$moment(_this.dateFromTo[1])
              .format(_this.$root.formatDate);
            _this.dateReportValue = dateFrom + " -> " + dateTo;
            _this.comValue = response.data.com;

            _this.getXiuBan();
            _this.getTrungGiaiCam();
            _this.getTrungIp();
            _this.getGiaiCam();
            _this.getCuocTheo();
            _this.getBuonCom();
            _this.getSapGia();
            _this.getRuiRo();
            _this.getDacBiet();
            _this.getBuonBOng();
          }
          _this.api0 = true;
        })
        .catch((error) => {
          _this.api0 = true;
          _this.errorApi(error);
        });
    },
    getMemberUnderDirty() {
      this.api1 = false;
      let params = this.setParamAPI();
      let url = `${this.$root.url}/betscan/getMemberUnderDirty`;
      let _this = this;
      ApiService.get(url, params)
        .then((response) => {
          if (response && response.data) {
            _this.dataXiuBan = response.data;
          } else {
            _this.dataXiuBan = [];
          }
          _this.api1 = true;
        })
        .catch((error) => {
          _this.api1 = true;
          _this.errorApi(error);
        });
    },
    getMemberSameIp(type) {
      this.api2 = false;
      let params = this.setParamAPI(type);
      let url = `${this.$root.url}/betscan/getMemberSameIp`;
      let _this = this;
      ApiService.get(url, params)
        .then((response) => {
          if (response && response.data) {
            _this.dataTrungIp = response.data;
          } else {
            _this.dataTrungIp = [];
          }
          _this.api2 = true;
        })
        .catch((error) => {
          _this.api2 = true;
          _this.errorApi(error);
        });
    },
    getMemberSameBet(type) {
      this.api3 = false;
      let params = this.setParamAPI(type);
      let url = `${this.$root.url}/betscan/getMemberSameBet`;
      let _this = this;
      ApiService.get(url, params)
        .then((response) => {
          if (response && response.data) {
            _this.dataCuocTheo = response.data;
          } else {
            _this.dataCuocTheo = [];
          }
          _this.api3 = true;
        })
        .catch((error) => {
          _this.api3 = true;
          _this.errorApi(error);
        });
    },
    getMemberBeforeCrashPrice(type) {
      this.api3 = false;
      let params = this.setParamAPI(type);
      let url = `${this.$root.url}/betscan/getMemberBeforeCrashPrice`;
      let _this = this;
      ApiService.get(url, params)
        .then((response) => {
          if (response && response.data) {
            _this.dataSapGia = response.data;
          } else {
            _this.dataSapGia = [];
          }
          _this.api3 = true;
        })
        .catch((error) => {
          _this.api3 = true;
          _this.errorApi(error);
        });
    },
    getMemberCrashPriceBefore(type) {
      this.api3 = false;
      let params = this.setParamAPI(type);
      let url = `${this.$root.url}/betscan/getMemberCrashPrice`;
      let _this = this;
      ApiService.get(url, params)
        .then((response) => {
          if (response && response.data) {
            _this.dataSapGia = response.data;
          } else {
            _this.dataSapGia = [];
          }
          _this.api3 = true;
        })
        .catch((error) => {
          _this.api3 = true;
          _this.errorApi(error);
        });
    },
    getMemberCrashPriceAfter(type) {
      this.api3 = false;
      let params = this.setParamAPI(type);
      let url = `${this.$root.url}/betscan/getMemberInCrashPrice`;
      let _this = this;
      ApiService.get(url, params)
        .then((response) => {
          if (response && response.data) {
            _this.dataSapGia = response.data;
          } else {
            _this.dataSapGia = [];
          }
          _this.api3 = true;
        })
        .catch((error) => {
          _this.api3 = true;
          _this.errorApi(error);
        });
    },
    getMemberDirtyCom(type) {
      this.api4 = false;
      let params = this.setParamAPI(type);
      let url = `${this.$root.url}/betscan/getMemberDirtyCom`;
      let _this = this;
      ApiService.get(url, params)
        .then((response) => {
          if (response && response.data) {
            _this.dataBuonCom = response.data;
          } else {
            _this.dataBuonCom = [];
          }
          _this.api4 = true;
        })
        .catch((error) => {
          _this.api4 = true;
          _this.errorApi(error);
        });
    },
    getMemberRuiRo(type) {
      this.api4 = false;
      let params = this.setParamAPI(type);
      let url = `${this.$root.url}/betscan/getMemberBetRisk`;
      let _this = this;
      ApiService.get(url, params)
        .then((response) => {
          if (response && response.data) {
            _this.dataRuiRo = response.data;
          } else {
            _this.dataRuiRo = [];
          }
          _this.api4 = true;
        })
        .catch((error) => {
          _this.api4 = true;
          _this.errorApi(error);
        });
    },
    getMemberDacBiet(type) {
      this.api4 = false;
      let params = this.setParamAPI(type);
      let url = `${this.$root.url}/betscan/getMemberSpecial`;
      let _this = this;
      ApiService.get(url, params)
        .then((response) => {
          if (response && response.data) {
            _this.dataDacBiet = response.data;
          } else {
            _this.dataDacBiet = [];
          }
          _this.api4 = true;
        })
        .catch((error) => {
          _this.api4 = true;
          _this.errorApi(error);
        });
    },
    getMemberBuonBong(type) {
      this.api4 = false;
      let params = this.setParamAPI(type);
      let url = `${this.$root.url}/betscan/getMemberDirtySameMatch`;
      let _this = this;
      ApiService.get(url, params)
        .then((response) => {
          if (response && response.data) {
            _this.dataBuonBong = response.data;
          } else {
            _this.dataBuonBong = [];
          }
          _this.api4 = true;
        })
        .catch((error) => {
          _this.api4 = true;
          _this.errorApi(error);
        });
    },
    setParamAPI(type) {
      // this.dateFromTo[0] = "2021/10/23";
      // this.dateFromTo[1] = "2021/10/23";
      // this.taiKhoanValue = "8K57I100025";
      // V39A81603004 member này test trong trận
      const dateFrom = this.$moment(this.dateFromTo[0]).format(
        this.$root.formatDateAPI
      );
      const dateTo = this.$moment(this.dateFromTo[1]).format(
        this.$root.formatDateAPI
      );
      let params = {
        dateFrom: dateFrom,
        dateTo: dateTo,
        typeDomain: this.domain,
        memberId: this.taiKhoanValue,
      };
      if (type === "ruiro") {
        params.normalSport = this.normalSport;
        params.virtualSport = this.virtualSport;
        params.type = this.typeRuiRo;
      }
      if (type === "dacbiet") {
        params.normalSport = this.normalSport;
        params.virtualSport = this.virtualSport;
        params.type = this.typeDacBiet;
      }
      if (type !== "getMemberInfo") {
        if (
          type === "getMemberSameBet" ||
          type === "getMemberDirtyCom" ||
          type === "getSapGia" ||
          type === "getTrungIp"
        ) {
          params.milisFrom = this.milisFrom;
          params.milisTo = this.milisTo;
          if (this.isShowBuonCom) {
            params.normalSport = this.beforeMatch;
            params.virtualSport = this.inMatch;
          } else {
            params.normalSport = this.normalSport;
            params.virtualSport = this.virtualSport;
          }
          params.filterCount = this.count;
          params.timeRange = this.timeRange;
        }
      }
      return params;
    },
    setPickerOptions() {
      let _this = this;
      let pickerOptions = {
        disabledDate(time) {
          let twoMonthsAgo = _this.$moment().subtract(2, "months");
          let currentMonth = _this.$moment();
          let timeFormat = _this.$moment(time);
          let returnValue = !(
            _this.$moment(timeFormat).isAfter(twoMonthsAgo) &&
            _this.$moment(timeFormat).isBefore(currentMonth)
          );
          return returnValue;
        },
        shortcuts: [
          {
            text: "Hôm nay",
            onClick(picker) {
              const end = _this.$moment().format(_this.$root.formatDate);
              const start = _this.$moment().format(_this.$root.formatDate);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Hôm qua",
            onClick(picker) {
              const end = _this
                .$moment()
                .subtract(1, "days")
                .format(_this.$root.formatDate);
              const start = _this
                .$moment()
                .subtract(1, "days")
                .format(_this.$root.formatDate);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Tuần này",
            onClick(picker) {
              const end = _this.$moment().format(_this.$root.formatDate);
              const start = _this
                .$moment()
                .startOf("weeks")
                .format(_this.$root.formatDate);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Tuần trước",
            onClick(picker) {
              const end = _this
                .$moment()
                .subtract(1, "weeks")
                .endOf("weeks")
                .format(_this.$root.formatDate);
              const start = _this
                .$moment()
                .subtract(1, "weeks")
                .startOf("weeks")
                .format(_this.$root.formatDate);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Tháng này",
            onClick(picker) {
              const end = _this.$moment().format(_this.$root.formatDate);
              const start = _this
                .$moment()
                .startOf("months")
                .format(`${_this.$root.formatDate}`);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Tháng trước",
            onClick(picker) {
              const end = _this
                .$moment()
                .subtract(1, "months")
                .endOf("months")
                .format(`${_this.$root.formatDate}`);
              const start = _this
                .$moment()
                .subtract(1, "months")
                .startOf("months")
                .format(`${_this.$root.formatDate}`);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      };
      return pickerOptions;
    },
    submit() {
      this.anDong = false;
      this.anIp = false;
      this.anUsername = false;
      this.anStake = false;
      this.demTheoUsername = false;
      this.thayDoiGio = false;
      this.anSoDem = false;
      this.dataTrungIp = [];
      this.dataCuocTheo = [];
      this.dataBuonCom = [];
      this.dataXiuBan = [];
      this.dataDacBiet = [];
      this.dataRuiRo = [];
      this.dataBuonBong = [];
      //this.count = 0;
      //this.countParam = 0;
      this.getMemberInfo();
    },
    goBack() {
      if (!this.isFromScreenXemNhanh) {
        this.$router.push("thong-ke-theo-member");
      } else {
        this.$router.push("xem-nhanh");
      }
    },
    getTrungGiaiCam() {
      if (this.trungGiaiCam) {
        // call API trungGiaiCam
      }
    },
    getGiaiCam() {
      if (this.giaiCam) {
        // call API giaiCam
      }
    },
    getTrungIp() {
      if (this.trungIp) {
        this.getMemberSameIp("getTrungIp");
      }
    },
    getXiuBan() {
      if (this.cuocXau) {
        this.getMemberUnderDirty();
      }
    },
    getCuocTheo() {
      if (this.cuocTheo) {
        this.getMemberSameBet("getMemberSameBet");
      }
    },
    getSapGia() {
      if (this.sapgia) {
        if (this.sapGiaBatDau) this.getMemberCrashPriceBefore("getSapGia");
        else if (this.sapGiaTrongTran)
          this.getMemberCrashPriceAfter("getSapGia");
        else if (this.sapGiaTruocTran)
          this.getMemberBeforeCrashPrice("getSapGia");
      }
    },
    getRuiRo() {
      if (this.ruiro) {
        this.getMemberRuiRo("ruiro");
      }
    },
    getDacBiet() {
      if (this.dacbiet) {
        this.getMemberDacBiet("dacbiet");
      }
    },
    getBuonBOng() {
      if (this.buonBong) {
        this.getMemberBuonBong();
      }
    },
    getBuonCom() {
      if (this.buonCom) {
        this.getMemberDirtyCom("getMemberDirtyCom");
      }
    },
    changeTimeRangeOptions() {
      let valueOption = this.timeRangeOptions.filter(
        (item) => item.codeKey === this.timeRange
      )[0];
      this.milisFrom = valueOption.codeValue;
      this.milisTo = valueOption.codeValue2;
    },
    changeModeEditMaQuanLy() {
      if (this.isEditMaQuanLy) {
        // update ma quan ly
        this.isEditMaQuanLy = !this.isEditMaQuanLy;
      } else {
        this.isEditMaQuanLy = !this.isEditMaQuanLy;
      }
    },
    changeModeEditKetMuan() {
      if (this.isEditKetMuan) {
        // update ma quan ly
        this.isEditKetMuan = !this.isEditKetMuan;
      } else {
        this.isEditKetMuan = !this.isEditKetMuan;
      }
    },
    changeDateFromTo() {
      console.log(this.dateFromTo);
    },
    handleCurrentXiuBan(currentRow) {
      console.log(currentRow);
    },
    handleCurrentCuocTheo(currentRow) {
      console.log(currentRow);
    },
    handleCurrentBuonCom(currentRow) {
      console.log(currentRow);
    },
    changeAnDong() {
      console.log(this.anDong);
    },
    changeAnIp() {
      console.log(this.anIp);
    },
    changeAnUsername() {
      console.log(this.anUsername);
    },
    changeAnStake() {
      console.log(this.anStake);
    },
    changeDoiKeo() {
      console.log(this.anDoiKeo);
    },
    changeDemTheoUsername() {
      console.log(this.demTheoUsername);
    },
    changeThayDoiGio() {
      console.log(this.thayDoiGio);
    },
    changeAnSoDem() {
      console.log(this.anSoDem);
    },
    changeCount() {
      this.countParam = this.count;
    },
    async print() {
      this.$root.fullScreenLoading = true;
      const el = this.$refs.reportBody;
      const options = {
        type: "dataURL",
      };
      const output = await this.$html2canvas(el, options);
      // download img
      var link = document.createElement("a");
      link.href = output;
      link.download = `${this.taiKhoanValue}_${this.$moment().format(
        this.$root.formatDateTimeFileName
      )}.jpg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.$root.fullScreenLoading = false;
    },
    setTitleDacBiet(titleDacBiet) {
      let titlesA = "CƯỢC ĐẶC BIỆT";
      if (titleDacBiet) {
        if (titleDacBiet === 1) {
          titlesA = "CƯỢC XẤU";
        }
        if (titleDacBiet === 2) {
          titlesA = "CHUYÊN ĐÁNH KÈO AN TOÀN";
        }
        if (titleDacBiet === 3) {
          titlesA = "CHUYÊN ĐÁNH XỈU VÀ DƯỚI";
        }
      }
      return titlesA;
    },
  },
};
</script>

<style lang="scss">
.report {
  .title-select {
    margin-right: 1vw;
  }

  .conditions-search {
    margin-bottom: 1vh;
    height: 40px;

    &.el-row {
      display: flex;
    }

    .el-date-editor {
      width: 100%;
    }

    [class*="el-col-"] {
      display: flex;
      align-items: center;
      height: 100%;
    }

    .input-count {
      margin-right: 10px;
    }
    .thong-ke {
      border: 0.5px solid #000000;
    }
    .tai {
      background-color: blueviolet;
    }
    .xiu {
      background-color: thistle;
    }
    .tren {
      background-color: chocolate;
    }
    .duoi {
      background-color: cadetblue;
    }
    .goc {
      background-color: yellowgreen;
    }
    .total {
      background-color: #f56c6c;
    }
    .ao {
      background-color: yellow;
    }
    .casino {
      background-color: violet;
    }
    .other {
      background-color: royalblue;
    }
  }

  .breadcrumb {
    height: 5.6vh;
    color: #c0c4cc;
    font-size: 18px;

    [class*="el-col-"] {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  .input-with-text {
    display: flex;
    margin: 0 10px;

    .another-time-from {
      width: calc(100% / 3) !important;
      max-width: 140px !important;

      input {
        border-radius: 0;
      }
    }
    .another-time-to {
      width: calc(100% / 3) !important;
      max-width: 140px !important;

      .el-input-group__prepend {
        border-radius: 0;
        border-left: none;
      }

      input {
        border-radius: 0;
        border-right: none;
      }
    }
    .another-time-range {
      width: calc(100% / 3) !important;
      max-width: 140px !important;

      input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .analysis-report {
    .img-analysis-report {
      border: 1px solid #c0c4cc;
      padding: 10px !important;
      text-align: center;
      vertical-align: middle;
    }
    .group-analysis-report-1 {
      border: 1px solid #c0c4cc;
      border-left: none;
      padding: 0px !important;

      .el-row {
        margin: 0px;
        border-bottom: 1px solid #c0c4cc;

        [class*="el-col-"] {
          display: flex;
          align-items: center;
          height: 100%;
          position: relative;
          white-space: pre-wrap;
          word-break: break-all;
        }
      }

      .row-1 {
        height: 50%;
        color: white;
        background-color: #3973ac;

        [class*="el-col-"] {
          justify-content: center;
          width: 100%;
        }

        .label-bao-cao {
          font-size: 50px;
        }
      }
      .row-2 {
        height: calc(50% / 3);
        background-color: #f5f7fa;

        .el-input {
          width: 75%;
        }
        .btn-edit {
          position: absolute;
          right: 10px;
          width: 60px;
        }
      }
      .row-3 {
        height: calc(50% / 3);
        background-color: #f5f7fa;
      }
      .row-4 {
        height: calc(50% / 3);
        background-color: #f5f7fa;
        border-bottom: none;
      }

      .title {
        border-left: 1px solid #c0c4cc;
        border-right: 1px solid #c0c4cc;
        background-color: #e2eff1;

        &:first-child {
          border-left: none;
        }
      }
    }
    .group-analysis-report-2 {
      border: 1px solid #c0c4cc;
      border-left: none;
      padding: 0px !important;

      .el-row {
        padding: 0px;
        border-bottom: 1px solid #c0c4cc;
      }

      .row-1 {
        height: 50%;

        .phan-tich {
          height: 100%;
          padding: 10px;
        }

        .el-textarea {
          .el-textarea__inner {
            color: #000000;
          }

          &.is-disabled {
            .el-textarea__inner {
              background-color: #f5f7fa;
              border: 1px solid #dcdfe6;
              color: #000000;
              cursor: text;
            }
          }
        }
      }
      .row-2 {
        height: 50%;
        background-color: #f5f7fa;
        border-bottom: none;

        .ket-luan {
          height: 100%;
          padding: 10px;
        }

        .el-textarea {
          width: 85%;

          .el-textarea__inner {
            color: #000000;
          }

          &.is-disabled {
            .el-textarea__inner {
              background-color: #f5f7fa;
              border: 1px solid #dcdfe6;
              color: #000000;
              cursor: text;
            }
          }
        }

        .btn-edit {
          position: absolute;
          right: 10px;
          width: 60px;
        }
      }
    }
  }

  .doc-quyen {
    .doc-quyen-left {
      text-align: left;
      padding-left: 15px;
    }
    .doc-quyen-right {
      text-align: right;
      padding-right: 25px;
    }
  }

  .title-table {
    height: 40px;
    display: flex;
    align-items: center;
    margin-top: 10px;

    span {
      font-weight: bold;
    }
  }

  .header-row-class-name {
    th {
      background-color: #f56c6c !important;
      color: black !important;
      height: 3vh !important;
    }
  }

  .el-table {
    thead.is-group th {
      padding: 3px !important;
    }

    th .cell {
      white-space: nowrap;
    }
  }

  .el-table__body {
    tr {
      &.current-row {
        td {
          background-color: #96dcee !important;
        }
      }
      &:hover > td {
        background-color: #96dcee !important;
      }
    }
  }

  .table-sap-gia,
  .table-rui-ro,
  .table-xiu-ban,
  .table-cuoc-theo,
  .table-buon-com,
  .table-trung-ip {
    // border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    padding-bottom: 20px;
  }
}

.btn-success {
  color: #fff !important;
  background-color: #0e9cc0 !important;
  border-color: #0e9cc0 !important;

  &:hover {
    color: #fff !important;
    background: #85ce61 !important;
    border-color: #85ce61 !important;
  }
}

.btn-success-plain {
  color: #0e9cc0 !important;
  background: #f0f9eb !important;
  border-color: #c2e7b0 !important;

  &:hover {
    color: #fff !important;
    background: #85ce61 !important;
    border-color: #85ce61 !important;
  }
}
// #reportBody {
//   background-image: url("../../assets/img/scan_background.png");
// }
</style>
