<template>
  <div id="menu" class="menu">
    <!-- <Header :title="title" /> -->
    <div class="el-page-header">
      <div class="icon-home" @click="$router.push('menu')">
        <img alt="icon" src="../../assets/img/logo.svg" />
      </div>
      <div class="header-menu">
        <i class="el-icon-user-solid" title="Quyền"></i>
        <span class="hidden-mobile">
          {{ this.nameHighestRole }}
        </span>
        <span class="hidden-mobile">
          Xin chào: {{ this.name }} ({{ this.userName }})</span
        >
        <i class="el-icon-switch-button" title="Logout" v-on:click="logout"></i>
      </div>
    </div>
    <el-row :gutter="20" class="menu-row-1">
      <el-col :span="6" :offset="3" class="logo">
        <img alt="Logo EZ24" src="../../assets/img/logo.svg" />
      </el-col>

      <el-col :span="12" class="btn-area">
        <el-row :gutter="20" class="menu-row-1">
          <el-col
            :span="8"
            :offset="0"
            v-show="isAdmin || isManager || isSupport"
          >
            <el-tooltip
              placement="top-start"
              effect="light"
              popper-class="tooltip-menu"
            >
              <div class="group-btn">
                <div class="title-btn">
                  {{ btn1.text.toUpperCase() }}
                </div>
                <div class="icon-btn">
                  <el-button type="success" plain>
                    <img
                      alt="Layer1"
                      src="../../assets/img/Layer1.png"
                      width="90px"
                    />
                  </el-button>
                </div>
              </div>
              <div slot="content">
                <div class="group-btn" @click="changePage(btn1.subBtn1.page)">
                  <div class="title-btn">
                    {{ btn1.subBtn1.text.toUpperCase() }}
                  </div>
                </div>
                <div class="group-btn" @click="changePage(btn1.subBtn2.page)">
                  <div class="title-btn">
                    {{ btn1.subBtn2.text.toUpperCase() }}
                  </div>
                </div>
                <div class="group-btn" @click="changePage(btn1.subBtn3.page)">
                  <div class="title-btn">
                    {{ btn1.subBtn3.text.toUpperCase() }}
                  </div>
                </div>
                <div class="group-btn" @click="changePage(btn1.subBtn4.page)">
                  <div class="title-btn">
                    {{ btn1.subBtn4.text.toUpperCase() }}
                  </div>
                </div>
              </div>
            </el-tooltip>
          </el-col>

          <el-col :span="8" :offset="0">
            <div class="group-btn" @click="changePage(btn2.subBtn1.page)">
              <div class="title-btn">
                {{ btn2.text.toUpperCase() }}
              </div>
              <div class="icon-btn">
                <el-button type="success" plain>
                  <img
                    alt="Layer1"
                    src="../../assets/img/Layer2.png"
                    width="90px"
                  />
                </el-button>
              </div>
            </div>
          </el-col>

          <el-col
            :span="8"
            :offset="0"
            v-show="isAdmin || isManager || isSupport"
          >
            <div class="group-btn">
              <div class="title-btn">...</div>
              <div class="icon-btn">
                <el-button type="success" plain></el-button>
              </div>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="20" class="menu-row-2">
          <el-col
            :span="8"
            :offset="0"
            v-show="isAdmin || isManager || isSupport"
          >
            <el-tooltip
              placement="bottom-start"
              effect="light"
              popper-class="tooltip-menu"
            >
              <div class="group-btn">
                <div class="title-btn">
                  {{ btn3.text.toUpperCase() }}
                </div>
                <div class="icon-btn">
                  <el-button type="success" plain>
                    <img
                      alt="Layer1"
                      src="../../assets/img/Layer3.png"
                      width="90px"
                    />
                  </el-button>
                </div>
              </div>
              <div slot="content">
                <div class="group-btn" @click="changePage(btn3.subBtn1.page)">
                  <div class="title-btn">
                    {{ btn3.subBtn1.text.toUpperCase() }}
                  </div>
                </div>
                <div class="group-btn" @click="changePage(btn3.subBtn2.page)">
                  <div class="title-btn">
                    {{ btn3.subBtn2.text.toUpperCase() }}
                  </div>
                </div>
                <div class="group-btn" @click="changePage(btn3.subBtn3.page)">
                  <div class="title-btn">
                    {{ btn3.subBtn3.text.toUpperCase() }}
                  </div>
                </div>
              </div>
            </el-tooltip>
          </el-col>

          <el-col
            :span="8"
            :offset="0"
            v-show="isAdmin || isManager || isSupport"
          >
            <div class="group-btn">
              <div class="title-btn">
                {{ btn4.text.toUpperCase() }}
              </div>
              <div class="icon-btn">
                <el-button type="success" plain>
                  <img
                    alt="Layer1"
                    src="../../assets/img/Layer4.png"
                    width="90px"
                  />
                </el-button>
              </div>
            </div>
          </el-col>

          <el-col :span="8" :offset="0" v-show="isAdmin">
            <el-tooltip
              placement="bottom-start"
              effect="light"
              popper-class="tooltip-menu"
            >
              <div class="group-btn" @click="changePage(btn6.subBtn1.page)">
                <div class="title-btn">ADMIN</div>
                <div class="icon-btn">
                  <el-button type="success" plain>
                    <img
                      alt="Layer1"
                      src="../../assets/img/Layer5.png"
                      width="90px"
                    />
                  </el-button>
                </div>
              </div>
              <div slot="content">
                <div class="group-btn" @click="changePage(btn6.subBtn1.page)">
                  <div class="title-btn">
                    {{ btn6.subBtn1.text.toUpperCase() }}
                  </div>
                </div>
              </div>
            </el-tooltip>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "Menu",
  data() {
    return {
      title: "Menu",
      userName: "",
      name: "",
      nameHighestRole: "",
      isAdmin: false,
      isManager: false,
      isSupport: false,
      isKeToan: false,
      isMember: false,
      btn1: {
        text: "Crawl data",
        page: "crawl-data",
        subBtn1: { text: "Quản lý player", page: "quan-ly-player" },
        subBtn2: { text: "History batch job", page: "history-batch-job" },
        subBtn3: {
          text: "Ghép Trận đấu & giải đấu",
          page: "mapping-match-league",
        },
        subBtn4: {
          text: "Thống kê các super không còn chơi",
          page: "thong-ke-khong-choi",
        },
      },
      btn2: {
        text: "Tài Khoản",
        page: "tai-khoan",
        subBtn1: {
          text: "1. Quản lý đại lý và tài khoản",
          page: "manager-agent-player",
        },
      },
      btn3: {
        text: "Quét hàng",
        page: "thong-ke-gian-lan",
        subBtn1: { text: "Xem theo win loss", page: "xem-theo-win-loss" },
        subBtn2: { text: "Xem nhanh", page: "xem-nhanh" },
        subBtn3: { text: "Top thắng thua member", page: "bet-top-member" },
      },
      btn4: { text: "Bot telegram", page: "quan-ly-bot" },
      btn6: {
        text: "Setting",
        page: "list-user",
        subBtn1: { text: "Quản lý user", page: "list-user" },
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    this.getUserName();
  },
  beforeDestroy() {},
  destroyed() {},
  methods: {
    changePage(value) {
      this.$router.push(value);
    },
    logout() {
      localStorage.removeItem("jwt");
      localStorage.removeItem("user");
      this.$router.push("/");
    },
    getUserName() {
      this.userName = localStorage.getItem("userName");
      let user = {
        userName: "",
        name: "",
        roles: [""],
      };
      user = JSON.parse(localStorage.getItem("user"));
      if (user) {
        this.name = user.name;
        const roles = user.roles;
        if (roles.includes("ROLE_ADMIN")) {
          this.nameHighestRole = "ADMIN";
          this.isAdmin = true;
        } else if (roles.includes("ROLE_MANAGER")) {
          this.nameHighestRole = "MANAGER";
          this.isManager = true;
        } else if (roles.includes("ROLE_SUPPORT")) {
          this.nameHighestRole = "SUPPORT";
          this.isSupport = true;
        } else if (roles.includes("ROLE_ACCOUNTANT")) {
          this.nameHighestRole = "ACCOUNTANT";
          this.isKeToan = true;
        } else {
          this.nameHighestRole = "MEMBER";
          this.isMember = true;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.menu {
  height: 100vh;

  > .el-row {
    height: 100%;
  }

  .logo {
    display: flex;
    height: 100%;

    img {
      width: 80%;
      margin: auto;
    }
  }
  .btn-area {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    background-color: white;

    .group-btn {
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        opacity: 0.8;
      }

      .title-btn {
        background-color: #eac9b8;
        padding: 5px;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
        font-weight: bold;
        font-size: 20px;
      }

      .icon-btn {
        background-color: #d96767;
        padding: 5px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
        height: 100px;

        .el-button {
          background-color: transparent;
          border: transparent;
          height: 100%;

          &:hover {
            background-color: transparent;
            border: transparent;
          }
        }
      }
    }

    .el-dropdown {
      width: 100%;
    }

    .el-button {
      width: 100%;
      padding: 0;
      font-weight: bold;
      font-size: 20px;
      white-space: pre-wrap;
      line-height: inherit;
    }

    .menu-row-1 {
      width: 100%;
      margin-top: auto;
      margin-bottom: 10px;
    }

    .menu-row-2 {
      width: 100%;
      margin-bottom: auto;
      margin-top: 10px;
    }
  }
}

.tooltip-menu {
  > div {
    > div {
      width: 300px;
      margin-right: 1vw;
    }
  }
  .group-btn {
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      opacity: 0.8;
    }

    .title-btn {
      background-color: #eac9b8;
      padding: 10px 5px;
      margin: 5px 0;
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
    }

    .icon-btn {
      background-color: #d96767;
      padding: 5px;
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 3px;

      .el-button {
        background-color: transparent;
        border: transparent;
        width: 100%;

        &:hover {
          background-color: transparent;
          border: transparent;
        }
      }
    }
  }

  .el-button {
    font-weight: bold;
    white-space: pre-wrap;
    line-height: inherit;
  }

  &.is-light {
    padding: 0;
    border: 1px solid white !important;

    .popper__arrow {
      border-top-color: white !important;
    }

    &[x-placement^="bottom"] {
      .popper__arrow {
        border-bottom-color: white !important;
      }
    }
  }
}
</style>
