<template>
  <div id="history-batch-job" class="history-batch-job">
    <Header :title="title" />

    <!-- Điều kiện tìm kiếm -->
    <el-row :gutter="20" class="conditions-search">
      <el-col :span="6" :offset="1">
        <label class="title-select">Mode</label>
        <el-select v-model="mode" value-key="codeKey" placeholder="Chọn mode">
          <el-option
            v-for="item in modeOptions"
            :key="item.codeKey"
            :label="item.codeName"
            :value="item.codeKey"
            no-data-text="Không có dữ liệu"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6" :offset="0">
        <el-button type="success" @click="search()">Tìm kiếm</el-button>
      </el-col>
    </el-row>

    <!-- Tên mode -->
    <el-row :gutter="20" class="name-mdoe">
      <el-col :span="24" :offset="0">
        <label>{{ modeNm }}</label>
      </el-col>
    </el-row>

    <!-- Table -->
    <el-row :gutter="20">
      <!-- Table 1-->
      <el-col :span="12" :offset="0">
        <u-table
          ref="table1"
          :data="dataTable1"
          :height="700"
          use-virtual
          style="width: 100%"
          header-row-class-name="header-row-class-name"
          border
          stripe
          highlight-current-row
          size="small"
          empty-text="Không có dữ liệu"
          @current-change="handleCurrentChange"
        >
          <u-table-column prop="jobId" label="Job ID" header-align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.jobId }}</span>
            </template>
          </u-table-column>

          <u-table-column
            prop="jobCount"
            label="Total Account"
            header-align="center"
            align="right"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.jobCount }}</span>
            </template>
          </u-table-column>

          <u-table-column
            prop="jobRun"
            label="Total Run"
            header-align="center"
            align="right"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.jobRun }}</span>
            </template>
          </u-table-column>

          <u-table-column
            prop="status"
            label="Status"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.status }}</span>
            </template>
          </u-table-column>

          <u-table-column
            prop="timeStartFormat"
            label="Time Start"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.row.timeStart
                    ? $moment(scope.row.timeStart).format($root.formatDateTime)
                    : ""
                }}
              </span>
            </template>
          </u-table-column>

          <u-table-column
            prop="timeEndFormat"
            label="Time End"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.row.timeEnd
                    ? $moment(scope.row.timeEnd).format($root.formatDateTime)
                    : ""
                }}
              </span>
            </template>
          </u-table-column>
        </u-table>
        <Pagination
          v-if="dataTable1.length > 0"
          @sizeChangeTable="sizeChangeTable1"
          @currentPageChangeTable="currentPageChangeTable1"
          :pageTable="pageTable1"
          :pageSizeTable="pageSizeTable1"
          :totalTable="totalTable1"
        ></Pagination>
      </el-col>

      <!-- Table 2-->
      <el-col :span="12" :offset="0">
        <u-table
          ref="table2"
          :data="dataTable2"
          :height="700"
          use-virtual
          style="width: 100%"
          header-row-class-name="header-row-class-name"
          border
          stripe
          highlight-current-row
          size="small"
          empty-text="Không có dữ liệu"
        >
          <u-table-column
            prop="no"
            label="NO"
            width="50"
            header-align="center"
            align="right"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.no }}</span>
            </template>
          </u-table-column>

          <u-table-column
            prop="jobName"
            label="User Name"
            header-align="center"
            sortable
          >
            <template slot-scope="scope">
              <span>{{ scope.row.jobName }}</span>
            </template>
          </u-table-column>

          <u-table-column
            prop="status"
            label="Status"
            header-align="center"
            align="center"
            sortable
          >
            <template slot-scope="scope">
              <span>{{ scope.row.status }}</span>
            </template>
          </u-table-column>

          <u-table-column
            prop="error"
            label="Error"
            header-align="center"
            align="center"
            sortable
          >
            <template slot-scope="scope">
              <span>{{ scope.row.error }}</span>
            </template>
          </u-table-column>

          <u-table-column
            prop="timeStartFormat"
            label="Time Start"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.row.timeStart
                    ? $moment(scope.row.timeStart).format($root.formatDateTime)
                    : ""
                }}
              </span>
            </template>
          </u-table-column>

          <u-table-column
            prop="timeEndFormat"
            label="Time End"
            header-align="center"
            align="center"
            sortable
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.row.timeEnd
                    ? $moment(scope.row.timeEnd).format($root.formatDateTime)
                    : ""
                }}
              </span>
            </template>
          </u-table-column>
        </u-table>
        <Pagination
          v-if="dataTable2.length > 0"
          @sizeChangeTable="sizeChangeTable2"
          @currentPageChangeTable="currentPageChangeTable2"
          :pageTable="pageTable2"
          :pageSizeTable="pageSizeTable2"
          :totalTable="totalTable2"
        ></Pagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Header from "../Common/Header.vue";
import ApiService from "../../service/api.service";

export default {
  name: "HistoryBatchJob",
  components: {
    Header,
  },
  data() {
    return {
      title: "History Batch Job",
      mode: "",
      modeNm: "",
      modeOptions: [
        {
          codeKey: "",
          codeName: "",
        },
      ],
      jobId: "",
      dataTable1: [],
      pageTable1: 1,
      pageSizeTable1: 5,
      totalTable1: 0,
      dataTable2: [],
      pageTable2: 1,
      pageSizeTable2: 5,
      totalTable2: 0,
    };
  },
  computed: {
    ...mapState("HistoryBatchJob", []),
  },
  beforeCreate() {
    console.log("I have not been created yet!");
  },
  created() {
    console.log("I have just been created!");
  },
  beforeMount() {
    console.log("I am about to be mounted to the DOM!");
  },
  mounted() {
    console.log("I am mounted to the DOM!");
    this.mode = this.mode ? this.mode : "4";
    this.getModeOptions();
    this.getJobHistory();
  },
  beforeDestroy() {
    console.log("I am about to be removed from the DOM!");
  },
  destroyed() {
    console.log("I no longer exist...");
  },
  methods: {
    ...mapActions("HistoryBatchJob", ["setModeOptions", "setDomainOptions"]),
    errorApi(error) {
      console.log(error);
      let message = "";
      let code = "";
      if (error.response) {
        message = error.response.data.message;
        code = error.response.data.code;
      } else {
        message = error.message;
      }
      this.$alert(message, `Lỗi ${code}`, {
        confirmButtonText: "OK",
        confirmButtonClass: "btn-success",
        type: "error",
      });
    },
    getModeOptions() {
      let url = `${this.$root.url}/master/getListMasterCodeByClass`;
      let _this = this;
      ApiService.get(url, { codeClass: "00003" })
        .then((response) => {
          _this.modeOptions = [];
          response.data.forEach((item) => {
            _this.modeOptions.push({
              codeKey: item.codeKey,
              codeName: item.codeKey === "0" ? " " : item.codeName,
            });
          });
          _this.modeNm = _this.modeOptions.find(
            (item) => item.codeKey === this.mode
          ).codeName;
          _this.setModeOptions(_this.modeOptions);
        })
        .catch((error) => {
          _this.errorApi(error);
        });
    },
    getJobHistory() {
      this.$root.fullScreenLoading = true;
      let url = `${this.$root.url}/crawldata/job/getJobHistory`;
      let _this = this;
      ApiService.get(url, {
        page: _this.pageTable1,
        pageSize: _this.pageSizeTable1,
      })
        .then((response) => {
          this.$root.fullScreenLoading = false;
          _this.totalTable1 = response.data.total;

          if (response.data.list.length > 0) {
            _this.dataTable1 = response.data.list;

            _this.$refs.table1.setCurrentRow(_this.dataTable1[0]);
            _this.jobId = _this.dataTable1[0].jobId;

            _this.getJobHistoryDetail();
          }
        })
        .catch((error) => {
          this.$root.fullScreenLoading = false;
          _this.errorApi(error);
        });
    },
    getJobHistoryDetail() {
      this.$root.fullScreenLoading = true;
      let url = `${this.$root.url}/crawldata/job/getJobHistoryDetail`;
      let _this = this;
      ApiService.get(url, {
        page: _this.pageTable2,
        pageSize: _this.pageSizeTable2,
        jobId: _this.jobId,
      })
        .then((response) => {
          this.$root.fullScreenLoading = false;
          _this.totalTable2 = response.data.total;

          if (response.data.list.length > 0) {
            _this.dataTable2 = response.data.list;
          }
        })
        .catch((error) => {
          this.$root.fullScreenLoading = false;
          _this.errorApi(error);
        });
    },
    search() {
      this.pageTable1 = 1;
      this.getModeOptions();

      this.pageTable2 = 1;
      this.getJobHistory();
    },
    goBack() {
      this.$router.push("menu");
    },
    handleCurrentChange(currentRow) {
      this.jobId = currentRow.jobId;
      this.getJobHistoryDetail();
    },
    sizeChangeTable1(val) {
      this.pageSizeTable1 = val;
      this.pageTable1 = 1;
      this.pageTable2 = 1;
      this.getJobHistory();
    },
    currentPageChangeTable1(val) {
      this.pageTable1 = val;
      this.pageTable2 = 1;
      this.getJobHistory();
    },
    sizeChangeTable2(val) {
      this.pageSizeTable2 = val;
      this.pageTable2 = 1;
      this.getJobHistoryDetail();
    },
    currentPageChangeTable2(val) {
      this.pageTable2 = val;
      this.getJobHistoryDetail();
    },
  },
};
</script>

<style lang="scss">
.history-batch-job {
  .title-select {
    margin-right: 1vw;
  }

  .conditions-search {
    margin-bottom: 1vh;
  }

  .name-mdoe {
    height: 5.6vh;
    background-color: #0e9cc0;
    color: white;
    font-size: 18px;

    [class*="el-col-"] {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  .header-row-class-name {
    th {
      background-color: #f56c6c;
      color: black;
    }
  }

  .el-table {
    .cell {
      word-break: break-word;
    }
  }

  .el-table__body {
    tr {
      &.current-row {
        td {
          background-color: #96dcee !important;
        }
      }
      &:hover > td {
        background-color: #96dcee !important;
      }
    }
  }

  .pagination {
    text-align: right;
    margin-top: 1vh;

    .el-pagination button,
    .el-pagination span:not([class*="suffix"]) {
      font-size: 14px;
    }
  }
}

.btn-success {
  color: #fff !important;
  background-color: #0e9cc0 !important;
  border-color: #0e9cc0 !important;

  &:hover {
    color: #fff !important;
    background: #85ce61 !important;
    border-color: #85ce61 !important;
  }
}

.btn-success-plain {
  color: #0e9cc0 !important;
  background: #f0f9eb !important;
  border-color: #c2e7b0 !important;

  &:hover {
    color: #fff !important;
    background: #85ce61 !important;
    border-color: #85ce61 !important;
  }
}
</style>
