<template>
  <el-dialog
    width="80vw"
    top="5vh"
    :title="this.titlePopupPlayer"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    :lock-scroll="false"
    class="add-or-edit-player"
  >
    <!-- body -->
    <el-form label-width="15vw">
      <el-form-item label="Upline:">
        <treeselect
          :options="treeDataUpline"
          :multiple="false"
          @select="changeGiaoThau"
          v-model="data.distributorId"
        />
      </el-form-item>
      <el-form-item label="DownLine:">
        <treeselect
          :options="treeDataDownLine"
          :multiple="false"
          @select="changeGiaoChild"
          v-model="data.receiverId"
        />
      </el-form-item>

      <el-form-item label="Nhà Cung cấp">
        <el-select
          v-model="data.supplier"
          filterable
          value-key="codeKey"
          placeholder="Chọn NCC"
        >
          <el-option
            v-for="item in nhaCungCapOptions"
            :key="item.codeKey"
            :label="item.codeName"
            :value="item.codeKey"
            no-data-text="Không có dữ liệu"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="WebsiteName" required>
        <el-select
          v-model="data.typeDomain"
          value-key="codeKey"
          filterable
          placeholder="Chọn Domain"
        >
          <el-option
            v-for="item in domainOptions"
            :key="item.codeKey"
            :label="item.codeName"
            :value="item.codeKey"
            no-data-text="Không có dữ liệu"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Tên tài khoản" required>
        <el-input v-model="data.userName" :disabled="type !== 'add'"></el-input>
      </el-form-item>
      <el-form-item label="Level" required>
        <el-select
          v-model="data.levelAccount"
          filterable
          value-key="codeKey"
          placeholder="Chọn level"
        >
          <el-option
            v-for="item in levelAccountOptions"
            :key="item.codeKey"
            :label="item.codeName"
            :value="item.codeKey"
            no-data-text="Không có dữ liệu"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Trạng Thái" required>
        <el-select
          v-model="data.status"
          value-key="codeKey"
          placeholder="Chọn Status"
        >
          <el-option
            v-for="item in statusOptions"
            :key="item.codeKey"
            :label="item.codeName"
            :value="item.codeKey"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Login ID">
        <el-input v-model="data.loginId" maxlength="50" clearable></el-input>
      </el-form-item>
      <el-form-item label="Password">
        <el-input v-model="data.passWord" maxlength="50" clearable></el-input>
      </el-form-item>
      <el-form-item label="PCode">
        <el-input v-model="data.passCode" maxlength="10" clearable></el-input>
      </el-form-item>
      <el-form-item v-show="modeBtnActive === 3" label="Quản lý" required>
        <el-select
          v-show="modeBtnActive === 3"
          v-model="data.originId"
          value-key="codeKey"
          placeholder="Chọn tự mode để show theo sub"
        >
          <el-option
            v-for="item in modeOptions"
            :key="item.codeKey"
            :label="item.codeName"
            :value="item.codeKey"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Quản lý" required>
        <el-select
          v-model="data.typeManager"
          value-key="codeKey"
          placeholder="Chọn tự quản vs công ty"
        >
          <el-option
            v-for="item in managerOptions"
            :key="item.codeKey"
            :label="item.codeName"
            :value="item.codeKey"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Ghi chú">
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }"
          v-model="data.note"
        ></el-input>
      </el-form-item>
    </el-form>

    <!-- footer -->
    <el-row slot="footer" class="dialog-footer">
      <el-col :span="24">
        <el-button type="success" plain @click="cancel()">Hủy</el-button>
        <el-button type="success" @click="confirm()">{{
          type === "add" ? "Thêm" : "Lưu"
        }}</el-button>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
// import the component
import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components: { Treeselect },
  name: "AddOrEditPlayer",
  props: [
    "dialogVisible",
    "dataRow",
    "currentAgentData",
    "selectTreeAgent",
    "modeBtnActive",
    "type",
    "titlePopupPlayer",
  ],
  data() {
    return {
      data: {},
      modeOptions: [
        {
          codeKey: 2,
          codeName: "Support",
        },
        {
          codeKey: 3,
          codeName: "Kế toán",
        },
        {
          codeKey: 4,
          codeName: "Thống kê",
        },
        {
          codeKey: 5,
          codeName: "Backup",
        },
      ],
      isShowDate: true,
      statusOptions: [
        {
          codeKey: 0,
          codeName: "Open",
        },
        {
          codeKey: 1,
          codeName: "Closed",
        },
        {
          codeKey: 2,
          codeName: "SUSPEND",
        },
      ],
      managerOptions: [
        {
          codeKey: 0,
          codeName: "Công Ty",
        },
        {
          codeKey: 1,
          codeName: "Ngoài Công Ty",
        },
      ],
      // CODE MS: 00008
      levelAccountOptions: [
        {
          codeKey: "1",
          codeName: "Super",
        },
        {
          codeKey: "2",
          codeName: "Master",
        },
        {
          codeKey: "3",
          codeName: "Agent",
        },
        {
          codeKey: "4",
          codeName: "Member",
        },
      ],
      treeDataUpline: [],
      treeDataDownLine: [],
    };
  },
  computed: {
    ...mapState("TongDaiLy", ["domainOptions", "nhaCungCapOptions"]),
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        return;
      }
      if (this.dataRow) {
        this.data = this.dataRow;
      } else {
        this.data = {
          playerId: 0,
          typeSub: 0,
          typeDomain: "02",
          supplier: "00",
          typeManager: 0,
          levelAccount: "0",
          originId: null, // id root player internal of table
          parentId: null, // id root player internal of table
          distributorId: null, // AgentId: (list super mà agent cha đang giữ) (có thể giao cho con này (agent hiện tại này))
          receiverId: null, // AgentId: nhận hàng (đã giao cho thằng agent hiện tại này)
          userName: "",
          loginId: "",
          passWord: "",
          passCode: "",
          note: "",
          status: 0,
          mode: -1,
        };
      }
      this.treeDataUpline = this.selectTreeAgent;
      this.treeDataDownLine = this.selectTreeAgent;
    },
    mode(val) {
      this.isShowDate = val.mode === "4" || val.mode === "5";
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    handleClose() {
      this.$confirm(
        `Bạn có chắc muốn hủy ${
          this.type === "add" ? "thêm" : "chỉnh sửa"
        } Player?`,
        `Xác nhận`,
        {
          confirmButtonText: "Đồng Ý",
          cancelButtonText: "Hủy",
          confirmButtonClass: "btn-success",
          cancelButtonClass: "btn-success-plain",
        }
      )
        .then(() => {
          this.close();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    close() {
      this.data = {};
      this.treeDataUpline = [];
      this.treeDataDownLine = [];
      this.$emit("closeDialog");
    },
    cancel() {
      this.handleClose();
    },
    confirm() {
      let isValid =
        this.checkInput("text", this.data.typeDomain, "Domain") &&
        this.checkInput("text", this.data.userName, "TK Chính") &&
        this.checkInput("text", this.data.loginId, "Login ID") &&
        this.checkInput("text", this.data.passWord, "Password") &&
        this.checkInput("text", this.data.passCode, "PCode") &&
        this.checkInput("text", this.data.status, "Trạng Thái");
      if (isValid) {
        // edit and setting data
        if (this.type === "thuHoi") {
          //this.data.receiverId = null;
          this.data.distributorId = null;
        }
        // modeBtnActive = 1 btn hang giao
        // modeBtnActive = 2 btn hang nhan
        // modeBtnActive = 3 subview
        if (this.modeBtnActive === 3) {
          this.data.typeSub = 1;
          this.data.receiverId = this.receiverId;
          this.data.distributorId = null;
          this.data.mode = this.data.originId;
        } else {
          this.data.typeSub = 0;
        }

        if (this.type === "add") {
          if (this.modeBtnActive === 2) {
            this.data.receiverId = this.currentAgentData.agentId;
          }
          this.$emit("callApiInsertPlayer", this.data);
        } else {
          this.$emit("callApiUpdatePlayer", this.data);
        }
        //this.close();
      }
    },
    checkInput(type, input, text) {
      if (type === "text") {
        if (input === "") {
          this.$alert(`Bạn phải chọn ${text}`, `Lỗi`, {
            confirmButtonText: "OK",
            confirmButtonClass: "btn-success",
            type: "error",
          });
          return false;
        }
      }
      return true;
    },
    changeGiaoThau(node) {
      this.receiverId = node.agentId;
      this.treeDataDownLine = [];
      this.treeDataDownLine.push(node);
    },
    changeGiaoChild(node) {
      this.distributorId = node.agentId;
    },
  },
};
</script>

<style lang="scss">
.add-or-edit-player {
  .el-dialog {
    padding-top: 10px;
    min-height: 80vh;
    overflow: auto;
  }

  @media (max-width: 767px) {
    .el-message-box {
      width: 90vw !important;
    }
    .el-form-item__label {
      font-size: 11px !important;
      width: 100px !important;
    }
  }

  .el-select {
    width: 100%;
  }

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100% !important;
  }

  .group-input {
    height: 32px;
    margin-bottom: 10px;

    > div {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  [class*="el-col-"] {
    position: relative;

    .required-icon {
      color: red;
      position: absolute;
      top: 0;
      left: -10px;
    }
  }
}

.btn-success {
  color: #fff !important;
  background-color: #0e9cc0 !important;
  border-color: #0e9cc0 !important;

  &:hover {
    color: #fff !important;
    background: #85ce61 !important;
    border-color: #85ce61 !important;
  }
}

.btn-success-plain {
  color: #0e9cc0 !important;
  background: #f0f9eb !important;
  border-color: #c2e7b0 !important;

  &:hover {
    color: #fff !important;
    background: #85ce61 !important;
    border-color: #85ce61 !important;
  }
}
</style>
