<template>
  <div
    id="thong-ke-khong-choi"
    class="thong-ke-khong-choi"
    v-on:keyup.enter="search()"
  >
    <Header :title="title" />
    <!-- Điều kiện tìm kiếm -->
    <el-row :gutter="20" class="conditions-search">
      <el-col :span="3" :offset="1">
        <el-select
          v-model="domain"
          value-key="codeKey"
          placeholder="Chọn Domain"
        >
          <el-option
            v-for="item in domainOptions"
            :key="item.codeKey"
            :label="item.codeName"
            :value="item.codeKey"
            no-data-text="Không có dữ liệu trong trang cào"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-input
          v-model="superId"
          placeholder="Nhập super"
          clearable
        ></el-input>
      </el-col>
      <el-col :span="6">
        <div class="block">
          <el-date-picker
            v-model="dateFromTo"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="Tới"
            start-placeholder="Data Start"
            end-placeholder="Date End"
            :format="
              $root.formatDate.replace('YYYY', 'yyyy').replace('DD', 'dd')
            "
            :default-value="[
              $moment().subtract(1, 'months').format('YYYY/MM/DD'),
              $moment().format('YYYY/MM/DD'),
            ]"
            :picker-options="setPickerOptions()"
          >
          </el-date-picker>
        </div>
      </el-col>
      <el-col :span="6" :offset="0">
        <el-button
          type="success"
          @click="search()"
          :disabled="isDisabledBtnSearch()"
          >Tìm kiếm</el-button
        >
      </el-col>
    </el-row>
    <!-- Danh sách player -->
    <u-table
      ref="tableListPlayer"
      :data="dataListPlayer"
      :height="700"
      use-virtual
      style="width: 100%"
      header-row-class-name="header-row-class-name"
      border
      stripe
      highlight-current-row
      size="small"
      empty-text="Không có dữ liệu"
      @current-change="handleCurrentChange"
    >
      <u-table-column label="NO" width="50">
        <template slot-scope="scope">
          <span>{{ scope.$index + 1 }}</span>
        </template>
      </u-table-column>

      <u-table-column prop="statusPlay" label="Trạng Thái" sortable>
        <template slot-scope="scope">
          <span>{{
            scope.row.statusPlay === false ? "Không hoạt động" : "Có hoạt động"
          }}</span>
        </template>
      </u-table-column>

      <u-table-column prop="supplier" label="NCC" sortable>
        <template slot-scope="scope">
          <span>{{ scope.row.supplier }}</span>
        </template>
      </u-table-column>

      <u-table-column prop="typeDomainNm" label="Domain" sortable>
        <template slot-scope="scope">
          <span>{{ scope.row.typeDomainNm }}</span>
        </template>
      </u-table-column>

      <u-table-column prop="userName" label="TK Chính" sortable>
        <template slot-scope="scope">
          <span>{{ scope.row.userName }}</span>
        </template>
      </u-table-column>

      <u-table-column prop="loginId" label="Login ID" sortable>
        <template slot-scope="scope">
          <span>{{ scope.row.loginId }}</span>
        </template>
      </u-table-column>

      <u-table-column prop="passWord" label="Password" sortable>
        <template slot-scope="scope">
          <span>{{ scope.row.passWord }}</span>
        </template>
      </u-table-column>

      <u-table-column prop="passCode" label="PCode">
        <template slot-scope="scope">
          <span>{{ scope.row.passCode }}</span>
        </template>
      </u-table-column>

      <u-table-column prop="downLine" label="Down Line" sortable>
        <template slot-scope="scope">
          <span>{{ scope.row.downLine }}</span>
        </template>
      </u-table-column>
    </u-table>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Header from "../Common/Header.vue";
import ApiService from "../../service/api.service";

export default {
  name: "PlayerKhongChoi",
  components: {
    Header,
  },
  data() {
    return {
      title: "Thống kê super không còn chơi",
      urlMain: `${this.$root.url}/betscanv2`,
      domain: "00",
      domainOptions: [
        {
          codeKey: "00",
          codeName: "Tất cả",
        },
      ],
      dateFromTo: [
        this.$moment()
          .subtract(1, "weeks")
          .startOf("weeks")
          .format(this.$root.formatDate),
        this.$moment()
          .subtract(1, "weeks")
          .endOf("weeks")
          .format(this.$root.formatDate),
      ],
      superId: "",
      dataListPlayer: [],
    };
  },
  computed: {
    ...mapState("XemTheoWinLoss", ["domainName", "reportTable", "timeUpdate"]),
  },
  beforeCreate() {
    console.log("I have not been created yet!");
  },
  created() {
    console.log("I have just been created!");
  },
  beforeMount() {
    console.log("I am about to be mounted to the DOM!");
  },
  mounted() {
    console.log("I am mounted to the DOM!");
    this.getTimeUpdate();
    this.getDomainOptions();
  },
  beforeDestroy() {
    console.log("I am about to be removed from the DOM!");
  },
  destroyed() {
    console.log("I no longer exist...");
  },
  methods: {
    ...mapActions("XemTheoWinLoss", ["setDomainOptions", "setTimeUpdate"]),
    errorApi(error) {
      console.log(error);
      let message = "";
      let code = "";
      if (error.response) {
        message = error.response.data.message;
        code = error.response.data.code;
      } else {
        message = error.message;
      }
      this.$alert(message, `Lỗi ${code}`, {
        confirmButtonText: "OK",
        confirmButtonClass: "btn-success",
        type: "error",
      });
    },
    getTimeUpdate() {
      let url = `${this.$root.url}/betscan/getTimeUpdate`;
      let _this = this;
      let params = {};
      ApiService.get(url, params)
        .then((response) => {
          const timeUpdate = _this
            .$moment(response.data)
            .format(this.$root.formatDateTime);
          _this.setTimeUpdate(timeUpdate);
        })
        .catch((error) => {
          _this.errorApi(error);
        });
    },
    getDomainOptions() {
      let url = `${this.$root.url}/master/getListMasterCodeByClass`;
      let _this = this;
      ApiService.get(url, { codeClass: "00002" })
        .then((response) => {
          response.data.forEach((item) => {
            _this.domainOptions.push({
              codeKey: item.codeKey,
              codeName: item.codeName,
            });
          });
          _this.setDomainOptions(_this.domainOptions);
        })
        .catch((error) => {
          _this.errorApi(error);
        });
    },
    setPickerOptions() {
      let _this = this;
      let pickerOptions = {
        disabledDate(time) {
          let twoMonthsAgo = _this.$moment().subtract(2, "months");
          let currentMonth = _this.$moment();
          let timeFormat = _this.$moment(time);
          let returnValue = !(
            _this.$moment(timeFormat).isAfter(twoMonthsAgo) &&
            _this.$moment(timeFormat).isBefore(currentMonth)
          );
          return returnValue;
        },
        shortcuts: [
          {
            text: "Tuần trước",
            onClick(picker) {
              const end = _this
                .$moment()
                .subtract(1, "weeks")
                .endOf("weeks")
                .format(_this.$root.formatDate);
              const start = _this
                .$moment()
                .subtract(1, "weeks")
                .startOf("weeks")
                .format(_this.$root.formatDate);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "2 tuần trước",
            onClick(picker) {
              const end = _this
                .$moment()
                .subtract(1, "weeks")
                .endOf("weeks")
                .format(_this.$root.formatDate);
              const start = _this
                .$moment()
                .subtract(2, "weeks")
                .startOf("weeks")
                .format(_this.$root.formatDate);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "3 tuần trước",
            onClick(picker) {
              const end = _this
                .$moment()
                .subtract(1, "weeks")
                .endOf("weeks")
                .format(_this.$root.formatDate);
              const start = _this
                .$moment()
                .subtract(3, "weeks")
                .startOf("weeks")
                .format(_this.$root.formatDate);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Tháng này",
            onClick(picker) {
              const end = _this.$moment().format(_this.$root.formatDate);
              const start = _this
                .$moment()
                .startOf("months")
                .format(_this.$root.formatDate);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Tháng trước",
            onClick(picker) {
              const end = _this
                .$moment()
                .subtract(1, "months")
                .endOf("months")
                .format(_this.$root.formatDate);
              const start = _this
                .$moment()
                .subtract(1, "months")
                .startOf("months")
                .format(_this.$root.formatDate);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      };
      return pickerOptions;
    },
    search() {
      this.getDataListPlayer();
    },
    getDataListPlayer() {
      this.$root.fullScreenLoading = true;
      let url = `${this.$root.url}/crawldata/player/checkPlayerNotPlay`;
      let _this = this;
      const dateFrom = this.$moment(this.dateFromTo[0]).format(
        this.$root.formatDateAPI
      );
      const dateTo = this.$moment(this.dateFromTo[1]).format(
        this.$root.formatDateAPI
      );
      let params = {
        fromDate: dateFrom,
        toDate: dateTo,
        typeDomain: this.domain,
        userName: this.superId,
      };
      ApiService.get(url, params)
        .then((response) => {
          this.$root.fullScreenLoading = false;
          _this.dataListPlayer = response.data.map((item) => {
            item.crDateFrom = item.crDateFrom
              ? this.$moment(item.crDateFrom).format(this.$root.formatDate)
              : item.crDateFrom;
            item.crDateTo = item.crDateTo
              ? this.$moment(item.crDateTo).format(this.$root.formatDate)
              : item.crDateTo;
            return item;
          });
          setTimeout(() => {
            _this.$refs.tableListPlayer.doLayout();
          }, 100);
        })
        .catch((error) => {
          this.$root.fullScreenLoading = false;
          _this.errorApi(error);
        });
    },
    goBack() {
      this.$router.push("menu");
    },
    isDisabledBtnSearch() {
      if (!this.dateFromTo || this.dateFromTo.length === 0) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
.thong-ke-khong-choi {
  .title-select {
    margin-right: 1vw;
  }

  .conditions-search {
    margin-bottom: 1vh;

    &.el-row {
      display: flex;
    }

    .el-date-editor {
      width: 100%;
    }
  }

  .checkbox-report-choice {
    height: 100%;

    [class*="el-col-"] {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  .name-mdoe {
    height: 5.6vh;
    background-color: #0e9cc0;
    color: white;
    font-size: 18px;

    [class*="el-col-"] {
      display: flex;
      align-items: center;
      height: 100%;

      &:last-child {
        justify-content: flex-end;
      }
    }
  }

  .breadcrumb {
    height: 5.6vh;
    color: black;
    font-size: 18px;

    [class*="el-col-"] {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  .header-row-class-name {
    &:first-child {
      th {
        background-color: #d96767 !important;
        color: black !important;
        height: 3vh !important;
      }
    }

    th {
      background-color: #eac9b8 !important;
      color: black !important;
      height: 3vh !important;

      .no-sort {
        .el-icon-caret-top,
        .el-icon-caret-bottom {
          opacity: 0.2;
        }
      }

      .descending {
        .el-icon-caret-top {
          opacity: 0.2;
        }
      }

      .ascending {
        .el-icon-caret-bottom {
          opacity: 0.2;
        }
      }

      .header-sort {
        display: flex;
        justify-content: center;

        .icon-sort {
          padding-left: 3px;
          position: relative;

          .el-icon-caret-top {
            position: absolute;
            top: 0;
          }

          .el-icon-caret-bottom {
            position: absolute;
            bottom: 0;
          }
        }
      }
    }
  }

  .el-table {
    thead.is-group th {
      padding: 0px !important;
    }

    th .cell {
      //white-space: nowrap;
      white-space: pre-wrap;
      word-break: break-word;
    }

    td .cell {
      word-break: break-word;
    }
  }

  .el-table__body {
    tr {
      &.current-row {
        td {
          background-color: #96dcee !important;
        }
      }
      &:hover > td {
        background-color: #96dcee !important;
      }

      td {
        .cell-label-user-name {
          font-weight: bold;
          font-size: 14px;

          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }

        .cell-label-number-click {
          font-weight: bold;
          font-size: 14px;
          color: #0e9cc0;

          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }

  .pagination {
    text-align: right;
    margin-top: 1vh;

    .el-pagination button,
    .el-pagination span:not([class*="suffix"]) {
      font-size: 14px;
    }
  }
}

.btn-success {
  color: #fff !important;
  background-color: #0e9cc0 !important;
  border-color: #0e9cc0 !important;

  &:hover {
    color: #fff !important;
    background: #85ce61 !important;
    border-color: #85ce61 !important;
  }
}

.btn-success-plain {
  color: #0e9cc0 !important;
  background: #f0f9eb !important;
  border-color: #c2e7b0 !important;

  &:hover {
    color: #fff !important;
    background: #85ce61 !important;
    border-color: #85ce61 !important;
  }
}
.title-table {
  height: 40px;
  display: flex;
  align-items: center;
  margin-top: 10px;

  span {
    font-weight: bold;
  }
}
</style>
