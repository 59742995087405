import Vue from "vue";
import Vuex from "vuex";
import Player from "./Player/Player";
import HistoryBatchJob from "./BatchJob/HistoryBatchJob";
import XemTheoWinLoss from "./ThongKeGianLan/XemTheoWinLoss";
import ThongKeTheoMember from "./ThongKeGianLan/ThongKeTheoMember";
import Report from "./ThongKeGianLan/Report";
import TongDaiLy from "./User/TongDaiLy";
import ListUser from "./User/ListUser";
Vue.use(Vuex);

// ストアオブジェクトを定義
const store = new Vuex.Store({
  namespaced: true,
  modules: {
    Player,
    HistoryBatchJob,
    XemTheoWinLoss,
    ThongKeTheoMember,
    Report,
    TongDaiLy,
    ListUser,
  },
});
export default store;
