<template>
  <div id="manager-agent-player" class="manager-agent-player">
    <Header :title="title.toUpperCase()" :backTo="backTo" />

    <!-- Điều kiện tìm kiếm -->
    <el-row :gutter="10" class="conditions-search">
      <el-col :span="3" :offset="0" class="column-agent">
        <el-row>
          <el-button type="success" @click="clickAddAgent('addRoot')">
            <i class="el-icon-plus"></i>
            Thêm tổng đại lý
          </el-button>
        </el-row>
        <el-row>
          <el-button
            v-show="flgBtnAgentAddAccount"
            type="success"
            @click="themListPlayer('add')"
          >
            <i class="el-icon-plus"></i>
            Nhập hàng mới
          </el-button>
        </el-row>
        <el-row>
          <el-button
            v-show="flgBtnAgentDelete"
            type="success"
            @click="searchHangNhapVe()"
            title="Tìm hàng nhập"
          >
            EZ24(Tìm hàng nhập)
          </el-button>
        </el-row>
        <el-row>
          <el-input
            v-model="searchString"
            @keyup.enter.native="searchPopUp()"
            clearable
            placeholder="Tìm tài khoản"
            >Tìm kiếm</el-input
          >
        </el-row>
        <el-row>
          <el-input
            v-model="searchNodeTree"
            clearable
            placeholder="Tìm đại lý"
          />
          <vue-tree
            :treeData="treeData"
            :searchVal="searchNodeTree.toUpperCase()"
            @on-selected-change="clickNode"
            ref="treeData"
          />
        </el-row>
      </el-col>
      <el-col :span="2" :offset="0">
        <h2 style="color: #99b898" class="mb-3">Công ty</h2>
        <div>
          <div>
            <a class="list-group-item BoxHandle"><b>SPORT</b></a>
            <div style="">
              <a @click="onlickSearchDomain('02')" class="list-group-item Box">
                <b>VIVA88</b></a
              >
              <a @click="onlickSearchDomain('03')" class="list-group-item Box">
                <b>SBOBET</b></a
              >
              <a @click="onlickSearchDomain('04')" class="list-group-item Box">
                <b>SBOBET HK</b></a
              >
              <a @click="onlickSearchDomain('06')" class="list-group-item Box">
                <b>WBET</b>
              </a>
              <a @click="onlickSearchDomain('07')" class="list-group-item Box">
                <b>3IN1</b></a
              >
            </div>
          </div>
          <div>
            <a class="list-group-item active BoxHandle"><b>CASINO</b></a>
            <div style="">
              <a @click="onlickSearchDomain('17')" class="list-group-item Box">
                <b>LASVEGAS</b></a
              >
              <a @click="onlickSearchDomain('18')" class="list-group-item Box">
                <b>SGD777</b></a
              >
            </div>
          </div>
          <div>
            <a class="list-group-item active BoxHandle"><b>COOKFIGHTS</b></a>
            <div style="">
              <a @click="onlickSearchDomain('19')" class="list-group-item Box">
                <b>S128</b></a
              >
              <a @click="onlickSearchDomain('20')" class="list-group-item Box">
                <b>SV388</b></a
              >
            </div>
          </div>
          <div>
            <a class="list-group-item active BoxHandle"><b>LOTTO</b></a>
            <div style="">
              <a @click="onlickSearchDomain('21')" class="list-group-item Box">
                <b>OK368</b></a
              >
              <a @click="onlickSearchDomain('22')" class="list-group-item Box">
                <b>LD789</b></a
              >
              <a @click="onlickSearchDomain('23')" class="list-group-item Box">
                <b>HK1101</b></a
              >
            </div>
          </div>
        </div>
      </el-col>
      <h2 style="color: #99b898" class="mb-3" v-show="this.showInforAgent">
        Thông tin đại lý
      </h2>
      <el-col :span="19" :offset="0" class="main-table">
        <table style="width: 100%" v-show="this.showInforAgent">
          <tbody style="width: 100%">
            <tr>
              <td class="DetailTitle">Mã đại lý:</td>
              <td>{{ this.currentAgentData.code }}</td>
              <td class="TableButton">
                <el-button type="danger">Chụp nhanh</el-button>
              </td>
            </tr>
            <tr>
              <td class="DetailTitle">Cấp đại lý:</td>
              <td>{{ this.currentAgentData.nameDisplay }}</td>
              <td class="TableButton">
                <el-button
                  type="primary"
                  v-show="flgBtnAgentEdit && this.currentAgentData.code !== ''"
                  @click="clickEditAgent('edit')"
                >
                  Sửa thông tin
                </el-button>
              </td>
            </tr>
            <tr>
              <td class="DetailTitle">Quyền đại lý:</td>
              <td>{{ this.currentAgentData.agentRoleName }}</td>
              <td class="TableButton">
                <el-button
                  type="primary"
                  v-show="
                    flgBtnAgentAddDownline && this.currentAgentData.code !== ''
                  "
                  @click="clickAddAgent('add')"
                >
                  Thêm Downline
                </el-button>
              </td>
            </tr>
            <tr>
              <td class="DetailTitle">@User Telegram:</td>
              <td class="col-md-8">
                <div class="UserTelegram">
                  {{ this.currentAgentData.telegramName }}
                </div>
              </td>
              <td class="TableButton">
                <el-button
                  type="danger"
                  v-show="
                    flgBtnAgentDelete && this.currentAgentData.code !== ''
                  "
                  @click="callApiAgentDelete"
                >
                  Xóa Agent
                </el-button>
              </td>
            </tr>
            <tr>
              <td class="DetailTitle">Nhóm Telegram :</td>
              <td>
                <div class="UserTelegram">
                  {{ this.currentAgentData.telegramGroupName }}
                </div>
              </td>
              <td class="TableButton"></td>
            </tr>
            <tr>
              <td class="DetailTitle">Phone Telegram:</td>
              <td>{{ this.currentAgentData.telegramPhone }}</td>
              <td class="TableButton">
                <!-- <button>Lưu tác vụ</button> -->
              </td>
            </tr>
            <tr>
              <td class="DetailTitle">Ghi chú:</td>
              <td>
                <div
                  class="UserTelegram"
                  data-toggle="tooltip"
                  data-original-title=""
                  title=""
                >
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 1, maxRows: 4 }"
                    readonly
                    v-model="this.currentAgentData.note"
                  ></el-input>
                </div>
              </td>
              <td class="TableButton col-md-2"></td>
            </tr>
          </tbody>
        </table>
        <!-- <el-col :span="18" :offset="0"> -->
        <el-row>
          <div class="btn-group">
            <el-button
              v-show="flgBtnAgentDelete && !showInforAgent"
              @click="onlickBtnHangNhap()"
              :class="btnHangNhap ? 'btn-hang btn-select' : 'btn-hang'"
              >HÀNG NHẬP</el-button
            >
            <el-button
              v-show="flgBtnAgentDelete && !showInforAgent"
              @click="onlickBtnHangGiao()"
              :class="btnHangGiao ? 'btn-hang btn-select' : 'btn-hang'"
              >HÀNG ĐÃ GIAO</el-button
            >
            <el-button
              v-show="flgBtnHangNhapGiaoCC && this.showInforAgent"
              @click="onlickBtnHangNhapCC()"
              :class="btnHangNhapCC ? 'btn-hang btn-select' : 'btn-hang'"
              >WEBCC Thầu</el-button
            >
            <el-button
              v-show="flgBtnHangNhapGiaoCC && this.showInforAgent"
              @click="onlickBtnHangGiaoCC()"
              :class="btnHangGiaoCC ? 'btn-hang btn-select' : 'btn-hang'"
              >WEBCC Giao</el-button
            >
            <el-button
              @click="onlickBtnSubView()"
              :class="btnSubView ? 'btn-hang btn-select' : 'btn-hang'"
              >SUBVIEW SUPPORT</el-button
            >
            <el-button
              v-show="btnHangGiaoCC || btnHangNhapCC || btnSubView"
              type="success"
              icon="el-icon-circle-plus-outline"
              @click="addPlayer('add')"
              >Thêm Player</el-button
            >
          </div>
        </el-row>
        <!-- Condition search player:START  -->
        <!-- <el-row :gutter="0">
          <el-col :span="30" :offset="0">
            <label class="title-select">Loại tài khoản</label>
            <el-select v-model="typeSub" disabled value-key="codeKey">
              <el-option
                v-for="item in typeSubOptions"
                :key="item.codeKey"
                :label="item.codeName"
                :value="item.codeKey"
                disabled
              >
              </el-option>
            </el-select>
            <label class="title-select">Nhà Cung Cấp</label>
            <el-select
              v-model="nhaCungCap"
              filterable
              value-key="codeKey"
              placeholder="Chọn NCC"
            >
              <el-option
                v-for="item in nhaCungCapOptions"
                :key="item.codeKey"
                :label="item.codeName"
                :value="item.codeKey"
                no-data-text="Không có dữ liệu"
              >
              </el-option>
            </el-select>
            <label class="title-select">Domain</label>
            <el-select
              v-model="domain"
              value-key="codeKey"
              filterable
              placeholder="Chọn Domain"
              no-data-text="Không có dữ liệu"
            >
              <el-option
                v-for="item in domainOptions"
                :key="item.codeKey"
                :label="item.codeName"
                :value="item.codeKey"
              >
              </el-option>
            </el-select>
            <label class="title-select">Nhập tên</label>
            <el-input
              style="width: auto"
              v-model="searchString"
              clearable
              @keypress.enter="search()"
              placeholder="Tên super hoặc loginId"
            ></el-input>
          </el-col>
        </el-row>
        <el-row :gutter="0" style="margin-top: 20px">
          <el-col :span="6" :offset="10">
            <el-button type="success" @click="search()" :loading="isSearch"
              >Tìm kiếm</el-button
            >
            <el-button
              v-show="btnHangGiaoCC || btnHangNhapCC || btnSubView"
              type="success"
              icon="el-icon-circle-plus-outline"
              @click="addPlayer('add')"
              >Thêm Player</el-button
            >
          </el-col>
        </el-row> -->
        <!-- Condition search player:END  -->
        <!-- Table player:  -->
        <h3>{{ getTitleTale() }}</h3>
        <div v-for="data in dataListPlayer" :key="data.key">
          <div class="header-table">{{ data.name }}</div>
          <u-table
            ref="tableListPlayer"
            :data="data.data"
            style="width: 100%"
            :height="700"
            use-virtual
            border
            stripe
            highlight-current-row
            size="small"
            empty-text="Không có dữ liệu"
            @current-change="handleCurrentChange"
          >
            <u-table-column
              v-if="isShowColumnUpLine"
              prop="upLine"
              label="UpLine"
              sortable
            >
              <template slot-scope="scope">
                <span>{{ scope.row.upLine }}</span>
              </template>
            </u-table-column>

            <u-table-column
              v-if="isShowColumnDownLine"
              prop="downLine"
              label="DOWNLINE"
              sortable
            >
              <template slot-scope="scope">
                <span>{{ scope.row.downLine }}</span>
              </template>
            </u-table-column>

            <u-table-column
              prop="supplierName"
              sort-by="supplier"
              label="NCC"
              sortable
            >
              <template slot-scope="scope">
                <span>{{ scope.row.supplierName }}</span>
              </template>
            </u-table-column>

            <!-- <u-table-column
              prop="typeDomainName"
              sort-by="typeDomain"
              label="WEB"
              sortable
            >
              <template slot-scope="scope">
                <span>{{ scope.row.typeDomainName }}</span>
              </template>
            </u-table-column> -->

            <u-table-column prop="userName" label="TK Chính" sortable>
              <template slot-scope="scope">
                <span>{{ scope.row.userName }}</span>
              </template>
            </u-table-column>

            <u-table-column prop="levelAccount" label="LEVEL" sortable>
              <template slot-scope="scope">
                <span>{{ scope.row.levelAccountName }}</span>
              </template>
            </u-table-column>

            <u-table-column prop="status" order="status" label="Status">
              <template slot-scope="scope">
                <span>{{ scope.row.statusName }}</span>
              </template>
            </u-table-column>
            <u-table-column prop="loginId" label="Login ID" sortable>
              <template slot-scope="scope">
                <span @click="copyText(scope.row.loginId, $event)">{{
                  scope.row.loginId
                }}</span>
              </template>
            </u-table-column>
            <u-table-column prop="passWord" label="Password">
              <template slot-scope="scope">
                <span @click="copyText(scope.row.passWord, $event)">{{
                  scope.row.passWord
                }}</span>
              </template>
            </u-table-column>
            <u-table-column prop="passCode" label="PCode">
              <template slot-scope="scope">
                <span @click="copyText(scope.row.passCode, $event)">{{
                  scope.row.passCode
                }}</span>
              </template>
            </u-table-column>
            <u-table-column
              prop="distributorId"
              label="Đã giao"
              v-if="isShowColumnDaGiao"
            >
              <template slot-scope="scope">
                <div class="cell-btn-center">
                  <el-button
                    v-show="scope.row.receiverId == null"
                    title="Chưa giao"
                    type="danger"
                    icon="el-icon-close"
                    size="mini"
                    circle
                    disabled
                  >
                  </el-button>
                  <el-button
                    v-show="scope.row.receiverId != null"
                    title="Đã giao"
                    type="success"
                    icon="el-icon-check"
                    size="mini"
                    circle
                    disabled
                  ></el-button>
                </div>
              </template>
            </u-table-column>
            <u-table-column prop="typeManager" label="Quản lý">
              <template slot-scope="scope">
                <span>{{ scope.row.typeManagerName }}</span>
              </template>
            </u-table-column>
            <u-table-column prop="note" label="Ghi chú">
              <template slot-scope="scope">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  v-model="scope.row.note"
                  readonly
                ></el-input>
              </template>
            </u-table-column>

            <u-table-column v-if="isShowColumnAction" label="Action">
              <template slot-scope="scope">
                <div class="cell-btn">
                  <el-button
                    v-show="
                      scope.row.typeSub === 0 &&
                      flgSuperBtnReceiver &&
                      scope.row.receiverId === null
                    "
                    title="Giao thầu"
                    type="text"
                    size="mini"
                    @click="editCrrentPlayerRow('giaoThau', scope.row)"
                    >Giao thầu</el-button
                  >
                  <el-button
                    v-show="
                      flgSuperBtnDistributor &&
                      btnHangGiao &&
                      scope.row.receiverId !== null
                    "
                    title="Thu hồi"
                    type="text"
                    @click="thuHoiCrrentPlayerRow('thuHoi', scope.row)"
                  >
                    Thu hồi
                  </el-button>
                  <el-button
                    v-show="flgSuperBtnEdit"
                    title="Chỉnh sửa Player"
                    type="primary"
                    icon="el-icon-edit-outline"
                    size="mini"
                    circle
                    @click="editCrrentPlayerRow('edit', scope.row)"
                  ></el-button>
                  <el-button
                    v-show="flgSuperBtnDelete && !btnHangGiao"
                    title="Xóa Player"
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    circle
                    @click="deletePlayer(scope.row)"
                  ></el-button>
                </div>
              </template>
            </u-table-column>
          </u-table>
        </div>
      </el-col>
    </el-row>

    <!-- Agent: clickAddAgent -->
    <AddOrEditAgent
      :dialog-visible="visibleAddOrEditAgent"
      :data-row="dataRowAgent"
      :type="modeAddOrEditAgent"
      :title-agent="titleAgent"
      @closeDialog="colseAddOrEditAgent"
      @callApiAgentInset="callApiAgentInset"
      @callApiAgentUpdate="callApiAgentUpdate"
    />
    <!--ListPlayer: cl -->
    <AddOrEditListPlayer
      :dialog-visible="visibleAddOrEditListPlayer"
      :data-row="dataRowSuper"
      :type="modeAddOrEditListPlayer"
      :tree-data-options="treeData"
      :title-popup-list-player="titlePopupListPlayer"
      @closeDialog="closeAddOrEditListUser"
      @callApiInsertListPlayer="callApiInsertListPlayer"
      @callApiUpdateListPlayer="callApiUpdateListPlayer"
    />
    <AddOrEditPlayer
      :dialog-visible="visibleAddOrEditPlayer"
      :data-row="dataRowSuper"
      :current-agent-data="currentAgentData"
      :select-tree-agent="treeData"
      :mode-btn-active="modeBtnActive"
      :type="modeAddOrEditPlayer"
      :title-popup-player="titlePopupPlayer"
      @callApiInsertPlayer="callApiInsertPlayer"
      @callApiUpdatePlayer="callApiUpdatePlayer"
      @closeDialog="closeAddOrEditPlayer"
    />
    <SearchPlayer
      :dialog-visible="visibleSearchPlayer"
      :search-string="searchString"
      :mode-login="modeLogin"
      :current-agent-data="currentAgentData"
      :select-tree-agent="treeData"
      @closeDialog="closeSearchPlayer"
    />
    <go-top></go-top>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Header from "../Common/Header.vue";
import AddOrEditAgent from "./componentTongDaiLy/AddOrEditAgent.vue";
import AddOrEditListPlayer from "./componentTongDaiLy/AddOrEditListPlayer.vue";
import AddOrEditPlayer from "./componentTongDaiLy/AddOrEditPlayer.vue";
import ApiService from "../../service/api.service";
import SearchPlayer from "./componentTongDaiLy/SearchPlayer.vue";
import GoTop from "@inotom/vue-go-top";

export default {
  name: "TongDaiLy",
  components: {
    Header,
    AddOrEditAgent,
    AddOrEditListPlayer,
    AddOrEditPlayer,
    SearchPlayer,
    GoTop,
  },
  data() {
    return {
      //-----------Data setting init: start----------------------------
      title: "Quản lý đại lý và danh sách tài khoản",
      backTo: "menu",
      modeLogin: 3,
      typeSub: "0",
      typeSubOptions: [
        {
          codeKey: "0",
          codeName: "Super",
        },
        {
          codeKey: "1",
          codeName: "Subview",
        },
      ],
      nhaCungCap: "",
      nhaCungCapOptions: [
        {
          codeKey: "00",
          codeName: "Khác",
        },
      ],
      levelPlayer: "",
      levelPlayerOptions: [
        {
          codeKey: "",
          codeName: "",
        },
      ],
      domain: "",
      domainOptions: [
        {
          codeKey: "00",
          codeName: "Tất cả",
        },
      ],
      // btnAgent
      flgBtnAgentEdit: false,
      flgBtnAgentDelete: false,
      flgBtnAgentAddDownline: false,
      flgBtnAgentAddAccount: false,
      // btnAgent END
      titleTable: "",
      treeData: [
        // {
        //   id: 0,
        //   agentId: null,
        //   parentId: null,
        //   agentRoleId: null,
        //   agentRoleName: "",
        //   name: "Tất cả",
        //   nameDisplay: "Công ty",
        //   code: "",
        //   level: 0,
        //   telegramName: "",
        //   telegramGroupName: "",
        //   phoneArea: "",
        //   telegramPhone: "",
        //   note: "",
        //   parentCode: null,
        //   children: [],
        // },
      ],
      searchNodeTree: "",
      showInforAgent: false,
      // This infor detail of an agent list super.
      // setting super
      flgBtnHangNhapGiao: false,
      flgBtnHangNhapGiaoCC: false,
      flgSuperBtnEdit: false,
      flgSuperBtnDelete: false,
      flgSuperBtnDistributor: false,
      flgSuperBtnReceiver: false,
      //-----------Data setting init: start----------------------------
      // btnHangNhap vs btnHangGiao chỉ được xuất hiện khi chọn giao hàng mới nhập về
      btnHangNhap: false,
      btnHangGiao: false,
      btnHangNhapCC: false,
      btnHangGiaoCC: false,
      btnSubView: false,
      dataListPlayer: [],
      modeBtnSearch: 0,
      // column
      isShowColumnDownLine: false,
      isShowColumnAction: false,
      isShowColumnDaGiao: false,
      isShowColumnUpLine: false,
      // Setting popup of agent: for add edit agent
      currentAgentData: {},
      modeAddOrEditAgent: "",
      titleAgent: "",
      visibleAddOrEditAgent: false,
      dataRowAgent: "",

      // Setting popup of player: for add edit list player
      visibleAddOrEditListPlayer: false,
      modeAddOrEditListPlayer: "",
      currentRowPlayer: "",
      titlePopupListPlayer: "",

      // Setting popup of player: for add edit player
      modeAddOrEditPlayer: "",
      visibleAddOrEditPlayer: false,
      modeBtnActive: 0,
      dataRowSuper: "",
      titlePopupPlayer: "",

      // Setting popup of player: for add edit list player
      visibleSearchPlayer: false,

      agentId: 0,
      distributorId: 0,
      receiverId: 0,
      searchString: "",
    };
  },
  computed: {
    ...mapState("TongDaiLy", []),
  },
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    // this is function call init data when mounted
    this.initData();
    this.getNhaCungCapOptions(); // suplier
    this.getLevelPlayerOptions();
    this.getDomainOptions(); // các web chơi
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {},
  destroyed() {},
  methods: {
    ...mapActions("TongDaiLy", ["setNhaCungCapOptions", "setDomainOptions"]),
    errorApi(error) {
      let message = "";
      let code = "";
      if (error.response) {
        message = error.response.data.message;
        code = error.response.data.code;
      } else {
        message = error.message;
      }
      this.$alert(message, `Lỗi ${code}`, {
        confirmButtonText: "OK",
        confirmButtonClass: "btn-success",
        type: "error",
      });
    },
    getLevelPlayerOptions() {
      let url = `${this.$root.url}/master/getListMasterCodeByClass`;
      let _this = this;
      ApiService.get(url, { codeClass: "00008" })
        .then((response) => {
          response.data.forEach((item) => {
            _this.levelPlayerOptions = [];
            _this.levelPlayerOptions.push({
              codeKey: item.codeKey,
              codeName: item.codeName,
            });
          });
        })
        .catch((error) => {
          _this.errorApi(error);
        });
    },
    getNhaCungCapOptions() {
      let url = `${this.$root.url}/master/getListMasterCodeByClass`;
      let _this = this;
      ApiService.get(url, { codeClass: "00007" })
        .then((response) => {
          _this.nhaCungCapOptions = [];
          response.data.forEach((item) => {
            _this.nhaCungCapOptions.push({
              codeKey: item.codeKey,
              codeName: item.codeName,
            });
          });
          _this.setNhaCungCapOptions(_this.nhaCungCapOptions);
          _this.nhaCungCap = "00";
        })
        .catch((error) => {
          _this.errorApi(error);
        });
    },
    getDomainOptions() {
      let url = `${this.$root.url}/master/getListMasterCodeByClass`;
      let _this = this;
      ApiService.get(url, { codeClass: "00002" })
        .then((response) => {
          response.data.forEach((item) => {
            _this.domainOptions.push({
              codeKey: item.codeKey,
              codeName: item.codeName,
            });
          });
          _this.setDomainOptions(_this.domainOptions);
        })
        .catch((error) => {
          _this.errorApi(error);
        });
    },
    initData() {
      this.$root.fullScreenLoading = true;
      let url = `${this.$root.url}/account/agent/initScreen`;
      let _this = this;
      this.treeData = [];
      ApiService.get(url)
        .then((response) => {
          response.data.listTree.forEach((item) => {
            this.treeData.push(item);
          });
          this.flgBtnAgentEdit = response.data.flgBtnAgentEdit;
          this.flgBtnAgentDelete = response.data.flgBtnAgentDelete;
          this.flgBtnAgentAddDownline = response.data.flgBtnAgentAddDownline;
          this.flgBtnAgentAddAccount = response.data.flgBtnAgentAddAccount;
          this.modeLogin = response.data.mode;
          this.typeSub = "0";
          // Setting defaul button active
          if (this.modeLogin === 1) {
            // admin và manager
            this.btnHangNhap = true;
            this.isShowColumnAction = true;
          } else if (this.modeLogin === 2) {
            // support
            this.btnHangNhapCC = true;
            this.isShowColumnAction = true;
          } else {
            // khác mode support và admin
            this.typeSub = "1";
            this.btnSubView = true;
            this.isShowColumnAction = false;
          }
          // setting super button
          this.flgBtnHangNhapGiao = response.data.flgBtnHangNhapGiao;
          this.flgBtnHangNhapGiaoCC = response.data.flgBtnHangNhapGiaoCC;
          this.flgSuperBtnEdit = response.data.flgSuperBtnEdit;
          this.flgSuperBtnDelete = response.data.flgSuperBtnDelete;
          this.flgSuperBtnDistributor = response.data.flgSuperBtnDistributor;
          this.flgSuperBtnReceiver = response.data.flgSuperBtnReceiver;
          this.$root.fullScreenLoading = false;
        })
        .catch((error) => {
          this.$root.fullScreenLoading = false;
          _this.errorApi(error);
        });
    },
    getDataListPlayer() {
      this.$root.fullScreenLoading = true;
      let url = `${this.$root.url}/account/player/getListSuper`;
      let _this = this;
      let params = {
        typeSub: this.typeSub,
        typeDomain: this.domain,
        agentId: this.agentId,
        distributorId: this.distributorId,
        receiverId: this.receiverId,
        modeBtnSearch: this.modeBtnSearch,
      };
      this.dataListPlayer = [];
      ApiService.get(url, params)
        .then((response) => {
          this.dataListPlayer = response.data;
          // setTimeout(() => {
          //   _this.$refs.tableListPlayer.doLayout();
          // }, 50);
          this.$root.fullScreenLoading = false;
        })
        .catch((error) => {
          this.$root.fullScreenLoading = false;
          _this.errorApi(error);
        });
    },
    setPage(val) {
      this.page = val;
    },
    onlickSearchDomain(stringDomain) {
      this.domain = stringDomain;
      this.getDataListPlayer();
    },
    handleCurrentChange(currentRowPlayer) {
      this.currentRowPlayer = currentRowPlayer;
    },
    goBack() {
      this.$router.push("menu");
    },
    searchPopUp() {
      if (this.searchString !== "") {
        this.visibleSearchPlayer = true;
      }
    },
    getTitleTale() {
      if (this.btnHangNhap) {
        return "WEBCC THẦU OWNER (tất cả super)";
      }
      if (this.btnHangGiao) {
        return "WEBCC GIAO OWNER (tất cả super đã được giao)";
      }
      if (this.btnHangNhapCC) {
        return "WEBCC THẦU";
      }
      if (this.btnHangGiaoCC) {
        return "WEBCC GIAO";
      }
      if (this.btnSubView) {
        return "WEBCC SUB GIAO OWNER";
      }
      return "HÀNG MỚI NHẬP";
    },
    onlickBtnHangNhap() {
      // this search mode of Quản lý
      this.setStatusGroupBtnHang(1);
      this.distributorId = 0;
      this.getDataListPlayer();
    },
    onlickBtnHangGiao() {
      // this search mode of Quản lý
      this.setStatusGroupBtnHang(2);
      this.getDataListPlayer();
    },
    onlickBtnHangNhapCC() {
      this.setStatusGroupBtnHang(3);
      this.getDataListPlayer();
    },
    onlickBtnHangGiaoCC() {
      this.setStatusGroupBtnHang(4);
      this.getDataListPlayer();
    },
    onlickBtnSubView() {
      this.setStatusGroupBtnHang(5);
      this.getDataListPlayer();
    },
    setStatusGroupBtnHang(num) {
      // One Click button in group active
      this.btnHangNhap = false;
      this.btnHangGiao = false;
      this.btnHangNhapCC = false;
      this.btnHangGiaoCC = false;
      this.btnSubView = false;
      // One Click button in group active
      this.receiverId = 0;
      this.distributorId = 0;
      this.agentId = 0;
      // Setting param search to default
      this.domain = "00";
      this.nhaCungCap = "00";
      this.typeSub = "0";
      this.isShowColumnUpLine = false;
      this.isShowColumnDownLine = false;
      this.isShowColumnDaGiao = false;
      this.modeBtnSearch = num;
      switch (num) {
        case 1:
          this.btnHangNhap = true;
          break;
        case 2:
          this.btnHangGiao = true;
          break;
        case 3:
          this.btnHangNhapCC = true;
          this.distributorId = this.currentAgentData.parentId; // có thể null
          this.receiverId = this.currentAgentData.agentId || 0;
          break;
        case 4:
          this.btnHangGiaoCC = true;
          this.distributorId = this.currentAgentData.agentId || 0;
          this.receiverId = this.currentAgentData.agentId || 0;
          this.agentId = this.currentAgentData.agentId || 0;
          break;
        case 5:
          this.btnSubView = true;
          this.typeSub = "1";
          this.receiverId = this.currentAgentData.agentId;
          break;
        default:
          break;
      }
      // setting column by mode
      if (this.btnHangGiao || this.btnHangGiaoCC || this.btnSubView) {
        this.isShowColumnDownLine = true;
      }
      if (this.btnHangNhap) {
        this.isShowColumnDaGiao = true;
      }
      if (this.btnHangNhapCC) {
        this.isShowColumnUpLine = true;
      }
    },
    // This code control pop up agent: start
    clickAddAgent(mode) {
      this.visibleAddOrEditAgent = true;
      if (mode === "addRoot") {
        this.dataRowAgent = "";
        this.titleAgent = "Thêm tổng đại lý";
      } else {
        this.dataRowAgent = this.currentAgentData;
        this.titleAgent = "Thêm DOWNLINE";
      }
      this.modeAddOrEditAgent = mode;
    },
    clickEditAgent(mode) {
      this.visibleAddOrEditAgent = true;
      this.dataRowAgent = this.currentAgentData;
      this.modeAddOrEditAgent = mode;
      this.titleAgent = "Sửa đại lý";
    },
    colseAddOrEditAgent() {
      this.dataRowAgent = "";
      this.modeAddOrEditAgent = "";
      this.visibleAddOrEditAgent = false;
    },
    callApiAgentInset(val) {
      let param = val;
      let url = `${this.$root.url}/account/agent/insert`;
      let _this = this;
      ApiService.put(url, param)
        .then((response) => {
          console.log(response);
          _this.initData();
          _this.colseAddOrEditAgent();
        })
        .catch((error) => {
          _this.errorApi(error);
        });
    },
    callApiAgentUpdate(val) {
      let param = val;
      let url = `${this.$root.url}/account/agent/update`;
      let _this = this;
      ApiService.put(url, param)
        .then((response) => {
          console.log(response);
          _this.initData();
          _this.colseAddOrEditAgent();
        })
        .catch((error) => {
          _this.errorApi(error);
        });
    },
    callApiAgentDelete() {
      let param = this.currentAgentData;
      let url = `${this.$root.url}/account/agent/delete`;
      let _this = this;
      this.$confirm(
        `Bạn có chắc muốn xóa Agent:` + this.currentAgentData.code + `?`,
        `Xác nhận`,
        {
          confirmButtonText: "Đồng Ý",
          cancelButtonText: "Hủy",
          confirmButtonClass: "btn-success",
          cancelButtonClass: "btn-success-plain",
        }
      )
        .then(() => {
          ApiService.delete(url, param)
            .then((response) => {
              console.log(response);
              _this.initData();
            })
            .catch((error) => {
              _this.errorApi(error);
            });
        })
        .catch((response) => {
          console.log(response);
        });
    },
    // This code control pop up agent: end
    // This code control pop up of player: start
    thuHoiCrrentPlayerRow(val, currentRow) {
      this.titlePopupPlayer = "Thu hồi TKCC(xóa upline nhé)";
      this.dataRowSuper = JSON.parse(JSON.stringify(currentRow));
      this.dataRowSuper.distributorId = null;
      this.modeAddOrEditPlayer = val;
      this.visibleAddOrEditPlayer = true;
    },
    addPlayer(val) {
      let pefixTitle = "Thêm ";
      if (this.btnHangNhapCC) {
        pefixTitle = pefixTitle + "TKCC THẦU";
        this.modeBtnActive = 1;
      }
      if (this.btnHangGiaoCC) {
        pefixTitle = pefixTitle + "TKCC GIAO";
        this.modeBtnActive = 2;
      }
      if (this.btnSubView) {
        pefixTitle = pefixTitle + "TKCC Subview";
        this.modeBtnActive = 3;
      }
      this.titlePopupPlayer = pefixTitle;
      this.dataRowSuper = {};
      this.modeAddOrEditPlayer = val;
      this.visibleAddOrEditPlayer = true;
    },
    editCrrentPlayerRow(val, currentRow) {
      let pefixTitle = "Chỉnh sửa ";
      if (this.btnHangNhapCC) {
        pefixTitle = pefixTitle + "TKCC THẦU";
        val = "editThau";
        this.modeBtnActive = 1;
      }
      if (this.btnHangGiaoCC) {
        pefixTitle = pefixTitle + "TKCC GIAO";
        val = "editGiao";
        this.modeBtnActive = 2;
      }
      if (this.btnSubView) {
        pefixTitle = pefixTitle + "SubView";
        val = "edit";
        this.modeBtnActive = 3;
      }
      this.titlePopupPlayer = pefixTitle;
      this.dataRowSuper = currentRow;
      this.modeAddOrEditPlayer = val;
      this.visibleAddOrEditPlayer = true;
    },
    callApiInsertPlayer(val) {
      let param = val;
      let url = `${this.$root.url}/account/player/insert`;
      let _this = this;
      ApiService.put(url, param)
        .then((response) => {
          console.log(response);
          _this.closeAddOrEditPlayer();
          _this.getDataListPlayer();
        })
        .catch((error) => {
          _this.errorApi(error);
        });
    },
    callApiUpdatePlayer(val) {
      let param = val;
      let url = `${this.$root.url}/account/player/update`;
      let _this = this;
      ApiService.put(url, param)
        .then((response) => {
          console.log(response);
          _this.closeAddOrEditPlayer();
          _this.getDataListPlayer();
        })
        .catch((error) => {
          _this.errorApi(error);
        });
    },
    closeAddOrEditPlayer() {
      this.visibleAddOrEditPlayer = false;
      this.dataRowSuper = "";
      this.modeAddOrEditPlayer = "";
    },
    closeSearchPlayer() {
      this.visibleSearchPlayer = false;
      this.searchString = "";
    },
    // Thêm 1 list super ( gồm super và các tài khoản sub view)
    themListPlayer(mode) {
      this.visibleAddOrEditListPlayer = true;
      this.modeAddOrEditListPlayer = mode;
      if (mode === "add") {
        this.titlePopupListPlayer =
          "TẠO MỚI TÀI KHOẢN CUNG CẤP THẦU (Giao diện cho nhà quản lý)";
      } else {
        this.titlePopupListPlayer =
          "CẬP NHẬT TÀI KHOẢN CUNG CẤP THẦU (Giao diện cho nhà quản lý)";
      }
    },
    closeAddOrEditListUser() {
      this.visibleAddOrEditListPlayer = false;
      this.dataRowSuper = "";
      this.modeAddOrEditListPlayer = "";
    },
    callApiInsertListPlayer(val) {
      let param = val;
      let url = `${this.$root.url}/account/player/insertSuperAndSubView`;
      let _this = this;
      ApiService.put(url, param)
        .then((response) => {
          console.log(response);
          //_this.getDataListPlayer();
          _this.closeAddOrEditListUser();
        })
        .catch((error) => {
          _this.errorApi(error);
        });
    },
    callApiUpdateListPlayer(val) {
      let param = val;
      let url = `${this.$root.url}/account/player/updateSuperAndSubView`;
      let _this = this;
      ApiService.put(url, param)
        .then((response) => {
          console.log(response);
          //_this.getDataListPlayer();
          _this.closeAddOrEditListUser();
        })
        .catch((error) => {
          _this.errorApi(error);
        });
    },
    deletePlayer(currentRowPlayer) {
      this.$confirm(`Bạn có chắc muốn xóa Player?`, `Xác nhận`, {
        confirmButtonText: "Đồng Ý",
        cancelButtonText: "Hủy",
        confirmButtonClass: "btn-success",
        cancelButtonClass: "btn-success-plain",
      })
        .then(() => {
          let param = this.setData(currentRowPlayer);
          let url = `${this.$root.url}/account/player/delete`;
          let _this = this;
          ApiService.delete(url, param)
            .then((response) => {
              console.log(response);
              _this.getDataListPlayer();
            })
            .catch((error) => {
              _this.errorApi(error);
            });
        })
        .catch((response) => {
          console.log(response);
        });
    },
    setData(val) {
      let row = {};
      row.playerId = val.playerId;
      row.typeSub = val.typeSub;
      row.typeDomain = val.typeDomain;
      row.supplier = val.supplier;
      row.typeManager = val.typeManager;
      row.levelAccount = val.levelAccount;
      row.originId = val.originId;
      row.parentId = val.parentId;
      row.distributorId = val.distributorId;
      row.receiverId = val.receiverId;
      row.status = val.status;
      row.userName = val.userName;
      row.loginId = val.loginId;
      row.passWord = val.passWord;
      row.passCode = val.passCode;
      row.note = val.note;
      row.mode = val.mode;
      return row;
    },
    // This code control pop up of player: end
    searchHangNhapVe() {
      this.currentAgentData = {
        agentId: 0,
      };
      this.setStatusGroupBtnHang(1);
      // set tất cả
      this.btnHangNhap = true;
      this.showInforAgent = false;
      this.getDataListPlayer();
    },
    clickNode(node) {
      this.showInforAgent = true;
      this.domain = "00"; // search tất cả domain của agent đó
      this.currentAgentData = node;
      if (this.modeLogin == 3) {
        this.onlickBtnSubView();
      } else {
        this.onlickBtnHangNhapCC();
      }
    },
    async copyText(mytext, event) {
      try {
        // navigator.clipboard.writeText only run with http @@@@
        if (!navigator.clipboard) {
          this.fallbackCopyTextToClipboard(mytext, event);
          return;
        }
        await navigator.clipboard.writeText(mytext);
        this.$toast.success(`Copy: "${mytext}"`);
      } catch ($e) {
        console.log($e);
        //this.$toast.error("Cannot copy");
      }
    },
    fallbackCopyTextToClipboard(text, event) {
      var textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = event.offsetX;
      textArea.style.left = "50vw";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        var successful = document.execCommand("copy");
        let msg = successful ? "successful" : "unsuccessful";
        this.$toast.success(`Copy: "${text}" ${msg}`);
      } catch (err) {
        console.log(err);
        //console.error("Fallback: Oops, unable to copy", err);
      }
      document.body.removeChild(textArea);
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/css/User/TongDaiLy.scss";
@import "../../assets/css/Vuetree.scss";
</style>
