const player = {
  namespaced: true,
  state: {
    domainOptions: [
      {
        codeKey: "00",
        codeName: "Tất cả",
      },
    ],
  },
  mutations: {
    setDomainOptions(state, value) {
      state.domainOptions = value;
    },
  },
  actions: {
    setDomainOptions(context, value) {
      context.commit("setDomainOptions", value);
    },
  },
  modules: {},
};

export default player;
