<template>
  <div
    id="list-player"
    class="list-player"
    v-on:keyup.enter="getDataListPlayer()"
  >
    <Header :title="title" :backTo="backTo" />

    <!-- Điều kiện tìm kiếm -->
    <el-row :gutter="20" class="conditions-search">
      <el-col :span="17" :offset="1">
        <label class="title-select">Mode</label>
        <el-select v-model="mode" value-key="codeKey" placeholder="Chọn mode">
          <el-option
            v-for="item in modeOptions"
            :key="item.codeKey"
            :label="item.codeName"
            :value="item.codeKey"
            no-data-text="Không có dữ liệu"
          >
          </el-option>
        </el-select>

        <label class="title-select">Domain</label>
        <el-select
          v-model="domain"
          value-key="codeKey"
          placeholder="Chọn Domain"
          no-data-text="Không có dữ liệu"
        >
          <el-option
            v-for="item in domainOptions"
            :key="item.codeKey"
            :label="item.codeName"
            :value="item.codeKey"
          >
          </el-option>
        </el-select>

        <label class="title-select">User Name</label>
        <el-input v-model="userName" clearable></el-input>
      </el-col>
      <el-col :span="6" :offset="0">
        <el-button type="success" @click="search()">Tìm kiếm</el-button>
        <el-button
          type="success"
          icon="el-icon-circle-plus-outline"
          @click="AddOrEditPlayer('add')"
          >Thêm Player</el-button
        >
      </el-col>
    </el-row>

    <!-- Tên mode -->
    <el-row :gutter="20" class="name-mdoe">
      <el-col :span="18" :offset="0">
        <label>{{ modeNm }}</label>
      </el-col>
    </el-row>

    <!-- Danh sách player -->
    <u-table
      ref="tableListPlayer"
      :data="dataListPlayer"
      :height="700"
      use-virtual
      style="width: 100%"
      header-row-class-name="header-row-class-name"
      border
      stripe
      highlight-current-row
      size="small"
      empty-text="Không có dữ liệu"
      @current-change="handleCurrentChange"
    >
      <u-table-column label="NO" width="50">
        <template slot-scope="scope">
          <span>{{ scope.$index + 1 }}</span>
        </template>
      </u-table-column>

      <u-table-column prop="downLine" label="Down Line" sortable>
        <template slot-scope="scope">
          <span>{{ scope.row.downLine }}</span>
        </template>
      </u-table-column>

      <u-table-column prop="supplier" label="NCC" sortable>
        <template slot-scope="scope">
          <span>{{ scope.row.supplier }}</span>
        </template>
      </u-table-column>

      <u-table-column prop="typeDomainNm" label="Domain" sortable>
        <template slot-scope="scope">
          <span>{{ scope.row.typeDomainNm }}</span>
        </template>
      </u-table-column>

      <u-table-column prop="userName" label="TK Chính" sortable>
        <template slot-scope="scope">
          <span>{{ scope.row.userName }}</span>
        </template>
      </u-table-column>

      <u-table-column prop="loginId" label="Login ID" sortable>
        <template slot-scope="scope">
          <span>{{ scope.row.loginId }}</span>
        </template>
      </u-table-column>

      <u-table-column prop="passWord" label="Password" sortable>
        <template slot-scope="scope">
          <span style="hidden">{{ scope.row.passWord }}</span>
        </template>
      </u-table-column>

      <u-table-column prop="passCode" label="PCode">
        <template slot-scope="scope">
          <span>{{ scope.row.passCode }}</span>
        </template>
      </u-table-column>

      <u-table-column prop="status" label="Trạng Thái" sortable>
        <template slot-scope="scope">
          <span>{{ scope.row.status === 1 ? "Close" : "Open" }}</span>
        </template>
      </u-table-column>
      <u-table-column prop="note" label="Ghi chú" sortable>
        <template slot-scope="scope">
          <span>{{ scope.row.note }}</span>
        </template>
      </u-table-column>

      <u-table-column
        prop="crDateFrom"
        label="Date Start"
        v-if="isShowDate"
        sortable
      >
        <template slot-scope="scope">
          <span>{{ scope.row.crDateFrom }}</span>
        </template>
      </u-table-column>

      <u-table-column
        prop="crDateTo"
        label="Date End"
        v-if="isShowDate"
        sortable
      >
        <template slot-scope="scope">
          <span>{{ scope.row.crDateTo }}</span>
        </template>
      </u-table-column>

      <u-table-column
        prop="crDatetimeRun"
        label="Date Run"
        v-if="isShowDate"
        sortable
      >
        <template slot-scope="scope">
          <span>{{ scope.row.crDatetimeRun }}</span>
        </template>
      </u-table-column>

      <u-table-column>
        <!-- <template slot="header">
          <el-button
            title="Thêm Player"
            type="success"
            icon="el-icon-circle-plus-outline"
            class="add-player-btn"
            @click="AddOrEditPlayer('add')"
          ></el-button>
        </template> -->
        <template slot-scope="scope">
          <div class="cell-btn">
            <el-button
              title="Chỉnh sửa Player"
              type="success"
              icon="el-icon-edit-outline"
              circle
              :disabled="
                !(
                  currentRow &&
                  currentRow.loginId === scope.row.loginId &&
                  currentRow.typeDomain === scope.row.typeDomain
                )
              "
              @click="AddOrEditPlayer('edit')"
            ></el-button>
            <el-button
              title="Xóa Player"
              type="danger"
              icon="el-icon-delete"
              circle
              :disabled="
                !(
                  currentRow &&
                  currentRow.loginId === scope.row.loginId &&
                  currentRow.typeDomain === scope.row.typeDomain
                )
              "
              @click="deletePlayer()"
            ></el-button>
          </div>
        </template>
      </u-table-column>
    </u-table>
    <AddOrEditPlayer
      :dialog-visible="visibleAddOrEditPlayer"
      :data-row="dataRow"
      :mode="{ mode: mode, modeNm: modeNm }"
      :type="modeAddOrEditPlayer"
      @closeDialog="closeAddOrEditPlayer"
      @addPlayer="addPlayer"
      @editPlayer="editPlayer"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AddOrEditPlayer from "./AddOrEditPlayer.vue";
import Header from "../Common/Header.vue";
import ApiService from "../../service/api.service";

export default {
  name: "ListPlayer",
  components: {
    AddOrEditPlayer,
    Header,
  },
  data() {
    return {
      title: "Manager account crawl data",
      backTo: "menu",
      mode: "",
      modeNm: "",
      modeOptions: [
        {
          codeKey: "",
          codeName: "",
        },
      ],
      domain: "",
      domainOptions: [
        {
          codeKey: "00",
          codeName: "Tất cả",
        },
      ],
      userName: "",
      dataListPlayer: [],
      currentRow: "",
      visibleAddOrEditPlayer: false,
      dataRow: "",
      modeAddOrEditPlayer: "",
      isShowDate: true,
    };
  },
  computed: {
    ...mapState("Player", []),
  },
  beforeCreate() {
    console.log("I have not been created yet!");
  },
  created() {
    console.log("I have just been created!");
  },
  beforeMount() {
    console.log("I am about to be mounted to the DOM!");
  },
  mounted() {
    console.log("I am mounted to the DOM!");
    this.mode = this.mode ? this.mode : "4";
    this.getModeOptions();
    this.getDomainOptions();
    this.getDataListPlayer();
  },
  beforeDestroy() {
    console.log("I am about to be removed from the DOM!");
  },
  destroyed() {
    console.log("I no longer exist...");
  },
  methods: {
    ...mapActions("Player", ["setModeOptions", "setDomainOptions"]),
    errorApi(error) {
      console.log(error);
      let message = "";
      let code = "";
      if (error.response) {
        message = error.response.data.message;
        code = error.response.data.code;
      } else {
        message = error.message;
      }
      this.$alert(message, `Lỗi ${code}`, {
        confirmButtonText: "OK",
        confirmButtonClass: "btn-success",
        type: "error",
      });
    },
    getModeOptions() {
      let url = `${this.$root.url}/master/getListMasterCodeByClass`;
      let _this = this;
      ApiService.get(url, { codeClass: "00003" })
        .then((response) => {
          _this.modeOptions = [];
          response.data.forEach((item) => {
            _this.modeOptions.push({
              codeKey: item.codeKey,
              codeName: item.codeKey === "0" ? " " : item.codeName,
            });
          });
          _this.modeNm = _this.modeOptions.find(
            (item) => item.codeKey === this.mode
          ).codeName;
          _this.setModeOptions(_this.modeOptions);
        })
        .catch((error) => {
          _this.errorApi(error);
        });
    },
    getDomainOptions() {
      let url = `${this.$root.url}/master/getListMasterCodeByClass`;
      let _this = this;
      ApiService.get(url, { codeClass: "00002" })
        .then((response) => {
          response.data.forEach((item) => {
            _this.domainOptions.push({
              codeKey: item.codeKey,
              codeName: item.codeName,
            });
          });
          _this.setDomainOptions(_this.domainOptions);
        })
        .catch((error) => {
          _this.errorApi(error);
        });
    },
    getDataListPlayer() {
      this.$root.fullScreenLoading = true;
      let url = `${this.$root.url}/crawldata/player/getPlayers`;
      let _this = this;
      let params = {
        mode: this.mode,
        typeDomain: this.domain,
        userName: this.userName,
      };
      ApiService.get(url, params)
        .then((response) => {
          this.$root.fullScreenLoading = false;
          _this.dataListPlayer = response.data.map((item) => {
            item.crDateFrom = item.crDateFrom
              ? this.$moment(item.crDateFrom).format(this.$root.formatDate)
              : item.crDateFrom;
            item.crDateTo = item.crDateTo
              ? this.$moment(item.crDateTo).format(this.$root.formatDate)
              : item.crDateTo;
            item.crDatetimeRun = item.crDatetimeRun
              ? this.$moment(item.crDatetimeRun).format(
                  this.$root.formatDateTimeNoSecond
                )
              : item.crDatetimeRun;
            return item;
          });
          _this.modeNm = _this.modeOptions.find(
            (item) => item.codeKey === this.mode
          ).codeName;
          _this.isShowDate = _this.mode === "4" || _this.mode === "5";
          setTimeout(() => {
            _this.$refs.tableListPlayer.doLayout();
          }, 100);
        })
        .catch((error) => {
          this.$root.fullScreenLoading = false;
          _this.errorApi(error);
        });
    },
    addPlayer(val) {
      let param = this.setData(val);
      let url = `${this.$root.url}/crawldata/player/insert`;
      let _this = this;
      ApiService.put(url, param)
        .then((response) => {
          console.log(response);
          _this.getDataListPlayer();
        })
        .catch((error) => {
          _this.errorApi(error);
        });
    },
    editPlayer(val) {
      let param = this.setData(val);
      let url = `${this.$root.url}/crawldata/player/update`;
      let _this = this;
      ApiService.put(url, param)
        .then((response) => {
          console.log(response);
          _this.getDataListPlayer();
        })
        .catch((error) => {
          _this.errorApi(error);
        });
    },
    deletePlayer() {
      this.$confirm(`Bạn có chắc muốn xóa Player?`, `Xác nhận`, {
        confirmButtonText: "Đồng Ý",
        cancelButtonText: "Hủy",
        confirmButtonClass: "btn-success",
        cancelButtonClass: "btn-success-plain",
      })
        .then(() => {
          let param = this.setData(this.currentRow);
          let url = `${this.$root.url}/crawldata/player/delete`;
          let _this = this;
          ApiService.delete(url, param)
            .then((response) => {
              console.log(response);
              _this.getDataListPlayer();
            })
            .catch((error) => {
              _this.errorApi(error);
            });
        })
        .catch((response) => {
          console.log(response);
        });
    },
    setData(val) {
      let row = {};
      row.crDateFrom = val.crDateFrom
        ? this.$moment(val.crDateFrom).format(this.$root.formatDateAPI)
        : val.crDateFrom;
      row.crDateTo = val.crDateTo
        ? this.$moment(val.crDateTo).format(this.$root.formatDateAPI)
        : val.crDateTo;
      row.crDatetimeRun = val.crDatetimeRun
        ? this.$moment(val.crDatetimeRun).format(
            this.$root.formatDateTimeNoSecondAPI
          )
        : val.crDatetimeRun;
      row.delivered = val.delivered;
      row.downLine = val.downLine;
      row.loginId = val.loginId;
      row.mode = val.mode ? val.mode : this.mode;
      row.modeNm = val.modeNm ? val.modeNm : this.modeNm;
      row.note = val.note;
      row.scan = val.scan;
      row.passCode = val.passCode;
      row.passWord = val.passWord;
      row.status = val.status;
      row.supplier = val.supplier;
      row.typeDomain = val.typeDomain;
      row.typeDomainNm = val.typeDomainNm;
      row.typeManager = val.typeManager;
      row.typeManagerNm = val.typeManagerNm;
      row.updDate = val.updDate;
      row.userName = val.userName;

      return row;
    },
    goBack() {
      this.$router.push("menu");
    },
    search() {
      this.getDataListPlayer();
    },
    handleCurrentChange(currentRow) {
      this.currentRow = currentRow;
    },
    AddOrEditPlayer(val) {
      this.visibleAddOrEditPlayer = true;
      this.dataRow =
        val === "add" ? "" : JSON.parse(JSON.stringify(this.currentRow));
      this.modeAddOrEditPlayer = val;
    },
    closeAddOrEditPlayer() {
      this.visibleAddOrEditPlayer = false;
      this.dataRow = "";
      this.modeAddOrEditPlayer = "";
    },
  },
};
</script>

<style lang="scss">
.list-player {
  .title-select {
    margin-right: 1vw;
    white-space: nowrap;
  }

  .conditions-search {
    margin-bottom: 1vh;

    .el-col {
      display: flex;
      align-items: center;

      .el-select {
        width: 100%;
      }

      .el-input {
        width: 90%;
      }
    }
  }

  .name-mdoe {
    height: 5.6vh;
    background-color: #0e9cc0;
    color: white;
    font-size: 18px;

    [class*="el-col-"] {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  .header-row-class-name {
    th {
      background-color: #f56c6c !important;
      color: black;
    }
  }

  .el-table {
    .cell {
      word-break: break-word;
    }
  }

  .el-table__body {
    tr {
      &.current-row {
        td {
          background-color: #96dcee !important;
        }
      }
      &:hover > td {
        background-color: #96dcee !important;
      }
    }
  }

  .cell-btn {
    display: flex;
  }

  .add-player-btn {
    width: 100% !important;
    padding: 12px 5px !important;
  }
}

.btn-success {
  color: #fff !important;
  background-color: #0e9cc0 !important;
  border-color: #0e9cc0 !important;

  &:hover {
    color: #fff !important;
    background: #85ce61 !important;
    border-color: #85ce61 !important;
  }
}

.btn-success-plain {
  color: #0e9cc0 !important;
  background: #f0f9eb !important;
  border-color: #c2e7b0 !important;

  &:hover {
    color: #fff !important;
    background: #85ce61 !important;
    border-color: #85ce61 !important;
  }
}
</style>
