<template>
  <div class="sap-gia">
    <el-table
      :ref="'tableSapGia'"
      :data="dataTable"
      style="width: 100%"
      header-row-class-name="header-row-class-name"
      border
      size="small"
      default-expand-all
      highlight-current-row
      row-key="index"
      empty-text="Không có dữ liệu"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      :row-class-name="rowClassName"
      @current-change="currentChange"
    >
      <el-table-column
        prop="rowNo"
        label="STT"
        header-align="center"
        align="center"
        width="40"
      >
        <template slot-scope="scope">
          <span>
            {{ scope.row.children ? scope.row.rowNo : "" }}
          </span>
        </template>
      </el-table-column>

      <el-table-column label="" header-align="center" align="center" width="40">
        <template slot-scope="scope">
          <span @click="removeRow(scope.row)">
            <i class="el-icon-circle-close"></i>
          </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="refNo"
        label="Mã Cược"
        header-align="center"
        align="left"
        min-width="110"
      >
        <template slot-scope="scope">
          <span class="text-bold">{{ scope.row.refNo }}</span>
          <span
            v-show="scope.row.children && scope.row.children.length > 0"
            class="text-bold el-table__expand-icon"
            :class="
              scope.row.expansion ? 'el-table__expand-icon--expanded' : ''
            "
            @click="expendRow(scope.row, `tableSapGia`)"
          >
            <i class="el-icon-arrow-right"></i>
          </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="domainName"
        label="Hệ Thống"
        header-align="center"
        align="left"
        min-width="70"
      >
        <template slot-scope="scope">
          <span>
            {{ scope.row.domainName }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="ip"
        label="IP"
        header-align="center"
        align="center"
        min-width="100"
      >
        <template slot-scope="scope">
          <span>
            {{ getIp(scope.row) }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="memberId"
        label="Tài Khoản"
        header-align="center"
        align="center"
        min-width="100"
      >
        <template slot-scope="scope">
          <span class="text-bold"> {{ getUsername(scope.row) }} </span>
          <br />
          <span class="text-bold text-red" v-show="!anSoDem">
            ({{ scope.row.betCount }})
          </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="match"
        label="Trận Đấu"
        header-align="center"
        align="center"
        min-width="180"
      >
        <template slot-scope="scope">
          <span> {{ scope.row.matchLabel }} {{ scope.row.match }} </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="league"
        label="Giải Đấu"
        header-align="center"
        align="center"
        min-width="150"
      >
        <template slot-scope="scope">
          <span>
            {{ scope.row.league }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="choiceDisplay"
        label="Cược/live"
        header-align="center"
        align="center"
        min-width="120"
      >
        <template slot-scope="scope">
          <span
            :class="getClassChoiceDisplay(scope.row)"
            v-html="getChoiceDisplay(scope.row.choiceDisplay)"
          >
          </span>
        </template>
      </el-table-column>

      <!-- <el-table-column
        prop="live"
        label="Chưa đá/Đang đá"
        header-align="center"
        align="center"
        min-width="130"
      >
        <template slot-scope="scope">
          <span>
            {{ scope.row.live ? "Đang đá" : "Chưa đá" }}
          </span>
        </template>
      </el-table-column> -->

      <el-table-column
        prop="odds"
        label="Giá"
        header-align="center"
        align="center"
        min-width="75"
      >
        <template slot-scope="scope">
          <span>
            {{ scope.row.odds }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="stake"
        label="Điểm"
        header-align="center"
        align="center"
        min-width="75"
      >
        <template slot-scope="scope">
          <span>
            {{ getStake(scope.row) }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="winLoss"
        label="Kết quả"
        header-align="center"
        align="center"
        min-width="75"
      >
        <template slot-scope="scope">
          <span>
            {{ scope.row.status }}
            <br />
            {{ scope.row.winLoss }}
            <!-- <br />
            {{ scope.row.com }} -->
          </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="thoiGianCuoc"
        label="Thời Gian Cược"
        header-align="center"
        align="center"
        min-width="130"
      >
        <template slot-scope="scope">
          <span
            v-show="!thayDoiGio"
            v-html="getTimeVN(scope.row.timeChoiceVst)"
          >
          </span>
          <span
            v-show="thayDoiGio"
            v-html="getTimeDomain(scope.row.domainName, scope.row.timeChoice)"
          >
          </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="timeDiff"
        label="Lệch giây"
        header-align="center"
        align="center"
        min-width="70"
      >
        <template slot-scope="scope">
          <span class="text-bold">
            {{ scope.row.timeDiff }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="ratioDiff"
        label="Lệch kèo"
        header-align="center"
        align="center"
        min-width="70"
      >
        <template slot-scope="scope">
          <span class="text-bold">
            {{ scope.row.ratioDiff }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="priceDiff"
        label="Lệch giá"
        header-align="center"
        align="center"
        min-width="70"
      >
        <template slot-scope="scope">
          <span class="text-bold">
            {{ scope.row.priceDiff }}
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "SapGia",
  props: {
    dataSapGia: {
      default: [],
    },
    anDong: {
      default: false,
    },
    anIp: {
      default: false,
    },
    anUsername: {
      default: false,
    },
    anStake: {
      default: true,
    },
    anDoiKeo: {
      default: false,
    },
    demTheoUsername: {
      default: false,
    },
    thayDoiGio: {
      default: false,
    },
    anSoDem: {
      default: false,
    },
    count: {
      default: 0,
    },
  },
  data() {
    return {
      dataTable: [],
      dataCopy: [],
      isInit: true,
    };
  },
  computed: {
    ...mapState("Report", [""]),
  },
  watch: {
    dataSapGia: {
      handler(val) {
        let index = 1;
        let parentIndex = 1;
        this.dataTable = [];
        val.forEach((el, id) => {
          let parent = el.dataRoot;
          parent.children = [];
          parent.index = index;
          parent.id = id;
          parent.rowNo = parentIndex++;
          parent.expansion = true;
          index++;
          el.listDataSameRoot.forEach((el1) => {
            el1.index = index;
            el1.parentId = parent.id;
            index++;
            parent.children.push(el1);
          });
          this.dataTable.push(parent);
        });
        this.dataCopy = this.dataTable;
      },
      deep: true,
    },
    anDong() {
      this.setAnDongData();
    },
    demTheoUsername() {
      this.setDemTheoUsernameData();
    },
    count() {
      this.removeBelowCount();
    },
  },
  beforeCreate() {
    console.log("I have not been created yet!");
  },
  created() {
    console.log("I have just been created!");
  },
  beforeMount() {
    console.log("I am about to be mounted to the DOM!");
  },
  mounted() {
    console.log("I am mounted to the DOM!");
  },
  beforeDestroy() {
    console.log("I am about to be removed from the DOM!");
  },
  destroyed() {
    console.log("I no longer exist...");
  },
  methods: {
    ...mapActions("Report", ["setShowCuocTheo"]),
    currentChange(currentRow) {
      currentRow.selectedRow = !currentRow.selectedRow;
      //this.$refs.tableSapGia.setCurrentRow({});
    },
    rowClassName({ row }) {
      if (row.selectedRow) {
        return "selected-row";
      } else if (row.children) {
        return "row-cuoc-goc";
      } else {
        return "";
      }
    },
    expendRow(row, refTable) {
      let $table = this.$refs[refTable];
      row.expansion = !row.expansion;
      $table.toggleRowExpansion(row, row.expansion);
    },
    setAnDongData() {
      let $table = this.$refs["tableSapGia"];
      for (const item of this.dataTable) {
        //this.expendRow(item, "tableSapGia");
        item.expansion = !this.anDong;
        $table.toggleRowExpansion(item, item.expansion);
      }
    },
    setDemTheoUsernameData() {},
    getIp(row) {
      if (this.anIp && !row.children) {
        let str = row.ip.split(".").pop();
        return `*********${str}`;
      } else {
        return row.ip;
      }
    },
    getUsername(row) {
      if (this.anUsername && !row.children) {
        let str = row.memberId.substr(row.memberId.length - 3);
        return `*********${str}`;
      } else {
        return row.memberId;
      }
    },
    getStake(row) {
      if (this.anStake && !row.children) {
        return `*******`;
      } else {
        return row.stake;
      }
    },
    getChoiceDisplay(choiceDisplay) {
      if (choiceDisplay) {
        return choiceDisplay.split("||").join("<br />");
      } else {
        return "";
      }
    },
    getClassChoiceDisplay(row) {
      let classStr = "";
      if (row.children) {
        classStr += "cuoc-goc ";
      }
      if (row.choice === 1 || row.choice === 3) {
        classStr += "tai-tren";
      } else {
        classStr += "xiu-duoi";
      }
      return classStr;
    },
    getTimeVN(timeVn) {
      if (timeVn) {
        let arrayStr = timeVn.split(" ");
        let str = "Giờ VN:";
        str += "<br />";
        str += arrayStr[0] + " ";
        str +=
          //"<font class='text-bold'>" +
          arrayStr[1] + " " + arrayStr[2];
        //+ "</font>";
        return str;
      } else {
        return "";
      }
    },
    getTimeDomain(domain, time) {
      if (time) {
        let arrayStr = time.split(" ");
        let str = "Giờ theo: " + domain;
        str += "<br />";
        str += arrayStr[0] + " ";
        str +=
          //"<font class='text-bold'>" +
          arrayStr[1] + " " + arrayStr[2];
        //+ "</font>";
        return str;
      } else {
        return "";
      }
    },
    removeRow(row) {
      let index = -1;
      let parent = {};
      if (row.children) {
        index = this.dataTable.findIndex((el) => el.id === row.id);
        this.dataTable.splice(index, 1);
        let parentIndex = 1;
        const listParent = [];
        for (const parent of this.dataTable) {
          let copyOb = { ...parent, rowNo: parentIndex };
          parentIndex++;
          listParent.push(copyOb);
        }
        this.dataTable = listParent;
      } else {
        parent = this.dataTable.filter((el) => el.id === row.parentId)[0];
        index = parent.children.findIndex((el) => el.index === row.index);
        parent.children.splice(index, 1);
      }
      //this.$refs.tableBuonCom.setCurrentRow({});
    },
    removeBelowCount() {
      const listParent = [];
      // console.log(this.dataCopy);
      let parentIndex = 1;
      for (const parent of this.dataCopy) {
        if (parent.children && parent.children.length > 0) {
          const listChild = [];
          for (const child of parent.children) {
            if (child.betCount >= this.count) {
              listChild.push(child);
            }
          }
          if (listChild.length > 0) {
            parent.children = [...listChild];
            let copyOb = { ...parent, rowNo: parentIndex };
            parentIndex++;
            listParent.push(copyOb);
          }
        }
      }
      this.dataTable = listParent;
    },
  },
};
</script>
<style lang="scss">
.sap-gia {
  .el-table {
    color: black; //hnh
    .selected-row {
      td {
        background-color: #96dcee !important;
      }
    }

    .row-cuoc-goc {
      td {
        background-color: #e2eff1 !important;
      }
    }

    .cell {
      line-height: unset; //hnh
      word-break: break-word;

      .el-icon-circle-close {
        color: red;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
          opacity: 0.8;
          font-size: 18px;
        }
      }

      .text-bold {
        font-weight: bold;

        [class*=" el-icon-"],
        [class^="el-icon-"] {
          font-weight: bold;
        }
      }

      .text-red {
        color: red;
      }

      .tai-tren {
        color: mediumslateblue;
        font-weight: bold;
      }

      .xiu-duoi {
        color: mediumseagreen;
        font-weight: bold;
      }

      .cuoc-goc {
        &.tai-tren {
          color: blue;
          font-weight: bold;
        }

        &.xiu-duoi {
          color: green;
          font-weight: bold;
        }
      }
    }

    tr {
      td {
        &:first-child {
          .cell {
            .el-table__expand-icon {
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>
